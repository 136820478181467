import { Injectable } from '@angular/core';
import { CONSTANT } from '../constants/constant-data';
import { ISnackBarData } from '../model/scan/scan';
import { SnackbarService } from './snackbar.service';
import { MessageServiceService } from './message-service.service';
import { IToastrOptions } from '../model/IMessage.model';
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  fileExtentions = ['.txt', '.xml', 'text/plain', 'text/xml'];
  maxSize = 5120; //5 mb in kb
  constructor(private snackbarService: SnackbarService,
    private messageService: MessageServiceService ) { }

  public validateFilesExtenstions(files: Array<File>, fileExtentions:Array<string> = []): Boolean {
    let isValidate = true;
    for (const item of files) {
      if (fileExtentions.indexOf(item.type) === -1) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }
  public checkFileSize(files: Array<File>, previousSize?: number): { isValid: boolean, totalSize: number } {
    let isValid = false;
    let totalSize = 0;
    for (const item of files) {
      totalSize += item.size;
    }
    totalSize = totalSize / 1024; 
    if (previousSize !== undefined) {
      totalSize += previousSize;
    } else {
      previousSize = 0; // Default to zero if not provided
    }
     //in KB
    if (totalSize < this.maxSize) {
      isValid = true;
    }
    return { isValid, totalSize };
  }
  public deleteFile(files: Array<File>, index: number): Array<File> {
    files.splice(index, 1);
    return files;
  }
  public removeDuplicateFiles(existingFiles: Array<any>, uploadedFiles: Array<File>, callback?: any) {
    let duplicateElemt: any = [];
    let filteredUploadedFiles: Array<File> = [];
    if (existingFiles.length) {
      for (const exist of existingFiles) {
        for (const upload of uploadedFiles) {
          if (exist.name === upload.name) {
            duplicateElemt.push(upload);
          }
        }
      }
    }
    if (duplicateElemt.length) {
      uploadedFiles = [...uploadedFiles].filter(function (item) {
        for (let i = 0, len = duplicateElemt.length; i < len; i++) {
          if (duplicateElemt[i].name == item.name) {
            return false;
          }
        }
        return true;
      });
    }
    callback(duplicateElemt, uploadedFiles);
  }
  public validateFileUpload(files: Array<any>, uploadedFiles: File[], isSsh = true, previousSize?: number): { isValid: boolean, totalSize: number } {
    const snakMsg: IToastrOptions = {}; // Assuming IToastrOptions is defined somewhere
    const fileFormat = isSsh ? CONSTANT.SSH_UPLOAD_EXE : CONSTANT.UPLOAD_EXE;
    const fileSizeResult = this.checkFileSize(uploadedFiles, previousSize);
    const isValidFiles = isSsh ? this.validateFilesExtenstions(files,fileFormat):this.validateFilesExtenstions(uploadedFiles, fileFormat);
    const checkMaxfiles = files.length + uploadedFiles.length;
    let isValid: boolean = false;
    
    if (fileSizeResult.isValid) {
        if (isValidFiles) {
            if (checkMaxfiles < 10001) {
                isValid = true;
            } else {
                snakMsg.message = CONSTANT.UPLOAD_FILE_MAX_FILE;
                this.messageService.showError(snakMsg);
            }
        } else {
            snakMsg.message = isSsh ? CONSTANT.SSH_FILE_FORMAT_MSG : CONSTANT.UPLOAD_FILE_FORMAT_MSG;
            this.messageService.showError(snakMsg);
        }
    } else {
        snakMsg.message = CONSTANT.FILE_SIZE_MSG;
        this.messageService.showError(snakMsg);
    }
    return { isValid, totalSize: fileSizeResult.totalSize };
}

}
