import { EventEmitter, Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable, catchError, throwError } from 'rxjs';
import { END_POINTS } from '../constants/endpoints';
import { environment } from "src/environments/environment";
import { IRequestObject } from '../search-bar/search.model';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  public tableData: any = [];
  public searchResult: any = {};
  public searchTerm: string = '';
  public headerText: string = '';
  public searchText: string = '';
  public isRedirected: boolean = false;
  public dataType: string = '';
  public isViewClicked: boolean = false;
  public searchResponse: any;
  public mainSearchData: any;
  public pageNum: any;
  public searchCleared: EventEmitter<void> = new EventEmitter<void>();
  public type:any = 0;
  public redirectiontype:string=''
  public keyMapping: any = {
    'Benchmarks' : environment.searchIndexes.benchmark,
    'Assets': environment.searchIndexes.device,
    'Parameters': environment.searchIndexes.param,
    'Roles': environment.searchIndexes.roles,
    'Scans': environment.searchIndexes.scan_summary,
    'Users': environment.searchIndexes.users
  }
  public defaultSearchDetails = [
    {
      value: 0,
      key: 'Assets',
      highlight: false,
      response: [],
      isApiCall: false,
      data: new MatTableDataSource<any>([])
    },
    {
      value: 0,
      key: 'Parameters',
      highlight: false,
      response: [],
      isApiCall: false,
      data: new MatTableDataSource<any>([])
    },
    {
      value: 0,
      key: 'Benchmarks',
      highlight: false,
      response: [],
      isApiCall: false,
      data: new MatTableDataSource<any>([])
    },
    {
      value: 0,
      key: 'Roles',
      highlight: false,
      response: [],
      isApiCall: false,
      data: new MatTableDataSource<any>([])
    },
    {
      value: 0,
      key: 'Scans',
      highlight: false,
      response: [],
      isApiCall: false,
      data: new MatTableDataSource<any>([])
    },
    {
      value: 0,
      key: 'Users',
      highlight: false,
      response: [],
      isApiCall: false,
      data: new MatTableDataSource<any>([])
    }
  ]
  constructor(private restService: RestService) { }

  public search(term: string | any, category?: string, search_type?: any, page_no =  1,content_no = 5){
    let req:IRequestObject = {
      search : term,
      category: category ? category : "",
      type: search_type ? search_type : "",
    }
    if (page_no && content_no) {
      req.page_no = page_no;
      req.content_no = content_no;
    }
    return this.restService.postApi(END_POINTS.SEARCH, req);
  }
  public setSearchTerm(term: string | any) {
    this.searchTerm = term ? term : '';
  }
  public setSearchResult(data: any) {
    this.searchResult = data;
  }

  public setSearchResponse(data: any) {
    this.searchResponse = data;
  }

  public setSearchTableData(data: any) {
    this.tableData = data && data.length ? data: [];
  }

  public setSearchText(value: string){
    this.searchText = value;
  }

  public setMainSearchData(value: any){
    this.mainSearchData = value;
  }

  public setPageNum(value: any){
    this.pageNum = value;
  }

  public setSearchDataType(value: string){
    this.dataType = value;
  }

 public setIsViewClicked(value: boolean){
   this.isViewClicked = value;
 }
  public clearSearch(){
    this.isRedirected = false;
  }

  public clearAll(){
    this.searchText = '';
    this.isRedirected = false;
    this.searchTerm = '';
    this.tableData = [];
    this.searchResult = {};
  }
}
