import { Component, Inject } from '@angular/core';
import { CustomDailog } from '../../model/Dialog.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-request',
  templateUrl: './subscription-request.component.html',
  styleUrls: ['./subscription-request.component.scss']
})
export class SubscriptionRequestComponent {
  userDetails :any
  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog) {
    this.userDetails = data.data;
  }
  onClose(callBack: Function | undefined) {
    if (callBack) {
        callBack();
    }
  }

}
