import { User } from "../../admin/components/users/model/user.model"

export const CONSTANT = {
  AUTHENTICATION: {
    USER_NAME_REQUIRED: 'User name is required',
    USER_NAME_EMAIL: 'User name should be a email',
    USER_NAME_MAX_LENGTH: 'User name cannot be more than 64 characters',
    LOG_IN_FAIL_MSG: 'Failed to logged in.',
    LOG_IN_SUCCESS_MSG: 'You have successfully logged in.',
    LOG_OUT_SUCCESS_MSG: 'You have successfully logout .',
    INVALID_TOKEN_MSG: 'Verification token is invalid',
    LOG_IN_PASSWORD_REQUIRED: 'Password is required',
    SIGN_IN_PASSWORD_REQUIRED: 'Enter Password',
    AUTH_PASSWORD_MAX_LENGTH: 'Password cannot be more than 20 characters',
    AUTH_PASSWORD_MIN_LENGTH: 'Password should be atleast 12 characters',
    AUTH_PASSWORD_FORMAT: 'Please enter proper password format',
    AUTH_PASSWORD_NO_SPACE: 'Password should not contain spaces',
    AUTH_OLD_PASSWORD_REQUIRED: 'Old password is required',
    AUTH_NEW_PASSWORD_REQUIRED: 'New password is required',
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required',
    CONFIRM_PASSWORD_MUST_MATCH: 'Password mismatched',
    AUTH_FIRST_NAME_MSG: 'First name is required',
    AUTH_FIRST_NAME_MIN_LENGTH: 'First name must be minimum 1 character',
    AUTH_FIRST_NAME_MAX_LENGTH: 'First name contain maximum 32 characters',
    AUTH_FIRST_NAME_PATTERN: 'First name must contain only alphabets (spaces are allowed between the words)',
    AUTH_LAST_NAME_MSG: 'Last name is required',
    AUTH_LAST_NAME_MIN_LENGTH: 'Last name must be minimum 1 character',
    AUTH_LAST_NAME_MAX_LENGTH: 'Last name contain maximum 32 characters',
    AUTH_LAST_NAME_PATTERN: 'Last name must contain only alphabets (spaces are allowed between the words)',
    AUTH_COUNTRY_CODE_MSG: 'Country code is required',
    AUTH_COUNTRY_CODE_MAX_LENGTH: 'Country code contain maximum 3 digits',
    AUTH_COUNTRY_CODE_PATTERN: 'Enter proper country code',
    AUTH_PHONE_NUM_MSG: 'Phone number is required',
    AUTH_PHONE_NUM_INVALID: 'Phone number is invalid',
    AUTH_PHONE_NUM_MIN_LENGTH: 'Phone number contain minmum 7 digits',
    AUTH_PHONE_NUM_MAX_LENGTH: 'Phone number contain maximum 15 digits',
    AUTH_ORG_EMAIL_REQUIRED: 'Email id is required',
    AUTH_ORG_EMAIL_MAX_LENGTH: 'Email id contain maximum 64 characters',
    AUTH_ORG_EMAIL_VAILD: 'Please enter valid email',
    AUTH_EMAIL_REQUIRED: 'Enter Organization Email',
    AUTH_EMAIL_FORMAT: 'Email should be in Email format',
    AUTH_EMAIL_NOT_ALLOWED: 'Please use business email',
    AUTH_ORG_NAME_REQUIRED: 'Organization name is required',
    AUTH_ORG_NAME_MIN_LENGTH: 'Organization name must contain minimum 6 characters',
    AUTH_ORG_NAME_MAX_LENGTH: 'Organization name contian maximum 64 characters',
    AUTH_ORG_ADDRESS_MAX_LENGTH:'Organization address Must contain Maximum 128 characters',
    AUTH_ORG_NAME_PATTERN: 'Organization name will allow space in between characters!',
    AUTH_EMAIL_PATTERN: /^[a-zA-Z0-9][a-zA-Z0-9._%+-]{0,63}@[a-zA-Z0-9.-]{1,}\.[a-zA-Z]{2,}$/
  }, 
  CREDENTIAL:{
    CRED_USER_NAME_MSG: 'User name is required',
    CRED_USER_NAME_MIN_LENGTH: 'User name must be minimum 1 character',
    CRED_USER_NAME_MAX_LENGTH: 'User name contain maximum 32 characters',
    CRED_USER_NAME_PATTERN: 'User name must contain only alphabets (spaces are allowed between the words)',
    CRED_CREDENTIAL_NAME_MSG: 'Credential name is required',
    CRED_CREDENTIAL_NAME_MIN_LENGTH: 'Credential name must be minimum 1 character',
    CRED_CREDENTIAL_NAME_MAX_LENGTH: 'Credential name contain maximum 32 characters',
    CRED_CREDENTIAL_NAME_PATTERN: 'Credential name must contain only alphabets (spaces are allowed between the words)',
    CRED_DESCRIPTION_NAME_MSG: 'Description name is required',
    CRED_DESCRIPTION_NAME_MIN_LENGTH: 'Description name must be minimum 1 character',
    CRED_DESCRIPTION_NAME_MAX_LENGTH: 'Description name contain maximum 32 characters',
    CRED_DESCRIPTION_NAME_PATTERN: 'Description name must contain only alphabets (spaces are allowed between the words)',
    CRED_API_MSG: 'API token  is required',
  },
  ORGANIZATION: {
    ORG_ADDRESS_REQUIRED: 'Organization address is required',
    ORG_ADDRESS_MIN_LEN: 'Organization address must contain minimum 4 characters',
    ORG_ADDRESS_MAX_LEN: 'Organization address contain maximum 128 characters',
    ORG_PROP_ADDRESS: 'Please enter proper address',
    ORG_LOCATION_REQUIRED: 'Please select the location',
    ORG_LOCATION_SELECT: 'Please select any of the above',
    NO_IMAGE: 'No image to save'
  },
  CREATE_ROLE: {
    ROLE_NAME_REQUIRED: 'Role name is required',
    ROLE_NAME_MIN_LENGTH: 'Role name must be minimum 3 characters',
    ROLE_NAME_MAX_LENGTH: 'Role name contain maximum 64 characters',
    ROLE_NAME_PATTERN: 'Role name must contain Alphabets & Numbers',
    ROLE_ALEARDY_EXIST:'The role is already exists! Please have a different role name',

  },
  WHITELIST_REASON:{
    WHITELIST_REASON_REQUIRED: 'This field is required',
    WHITELIST_REASON_MIN_LENGTH: 'Minimum length is 8 characters',
    WHITELIST_REASON_MAX_LENGTH: 'Maximum length is 120 characters',
    WHITELIST_PATTERN:'Please enter proper message'
  },
  NEWSCAN:{
  TITLE: "Do you want to save us the API Details?",
  CONTENT: "It will help us to reuse the information to Re-scan",
  LABEL: "Save & Connect",
  LABEL_1: "Connect"
  },
  SSH_NEWSCAN:{
  TITLE: "Do you want to save us the SSH Details?",
  CONTENT: "It will help us to reuse the information to Re-scan",
  LABEL: "Save & Connect",
  LABEL_1: "Connect"
  },
  UPLOAD_EXE: ['.txt', '.xml', 'text/plain', 'text/xml'],
  SSH_UPLOAD_EXE: ['text/csv', 'csv'],
  UNSAVED_FILE_CHANGES:'Selection disabled. You have unsaved form changes please remove them or try submititng the form',
  INVALID_RESET_TOKEN_MSG: 'The reset token is invalid',
  FILE_SIZE_MSG: 'You have uploaded more than 5MB file size.',
  UPLOAD_FILE_FORMAT_MSG: 'You can upload only Xml/Text file types.',
  UPLOAD_FILE_MAX_FILE: 'You can upload maximum 10000 file(s).',
  UPLOAD_INVALID_FILES: 'You have uploaded invalid files, please remove them and try submitting',
  INVALID_VENDOR_FILES: 'You have uploaded wrong vendor(s) files, please remove them and try submitting',
  SSH_FILE_FORMAT_MSG: ' You can Upload only CSV/XSL Document.',
  SERVICE_FAILED_MSG: 'API has Failed. Please try again.',
  UNAUTH_ACCESS_MSG: 'Unauthorized user access/Session expired. Please contact with administrator if this issue persists again.',
  USER_PERMISSION: 'You do not have permission to access this module in application.',
  EDIT_PERMISSION: 'You do not have permission to modify this screen.',
  SUCCESS_CHANGE_PASS_MSG: 'Password has changed successfully.',
  SUCCESS_CHANGE_FAIL_MSG: 'Failed to change the password. Please try again.',
  SUCCESS_CHANGE_SUCCEES_MSG: 'Congratulations! Your password has been updated successfully. Please login again!',
  PASSWORD_RESET_MESG: 'Password reset has been successfully completed.',
  ROLE_API_FAILED_MSG: 'Role API has failed. Please login again',
  ROLE_SUCCESS_MSG: 'Role has been created successfully!',
  ROLE_MODIFIED_MSG: 'Permissions have been saved successfully!',
  VERIFICATION_EMAIL: 'Verification email has been sent to the registered email',
  CATEGORY_WISE_LABELS: ['Control Plane', 'Management Plane', 'Data Plane'],
  CHANGE_PASSWORD_LABEL: 'Change Password',
  CREATE_PASSWORD_LABEL: 'Create Password',
  RESED_PASSWORD_LINK_MSG: 'Password Link has been send in your E-mail!',
  NO_REGION_LIST_MSG: 'No region data available!',
  NO_MAPPED_REGION_LIST_MSG:'No Regions has been mapped to this role',
  NO_DEVICE_LIST_MSG: 'No asset information available!',
  NO_DEVICE_DATA: "You don't have any data for this asset!",
  LOGOUT_DEVICE: "You have been logged out.",
  SUCCESS_REGION_DEVICE: 'The Regions and Assets were saved successfully.',
  LOADING: 'Loading...',
  PASSWORD_ALERT: 'Enter your password to commission the asset(s)',
  PASSWORD_ALERT_DECOMMISSION: 'Please enter your password to decommission the asset(s)',
  DECOMM_TITLE: 'Are you sure want to decommission?',
  RECOMM_TITLE: 'Are you sure want to commission the assets(s)?',
  DELETE_DATA_TITLE: 'Deleting data permanently!',
  DELETE_DATA_SUB_TITLE: 'Enter the password to perform this action.',
  RECOMM_BUTTON: 'Commission',
  DELETE_BUTTON: 'Confirm Delete',
  DECOMM_BUTTON: 'Decommission',
  DLETE_DEVICE_TITLE: 'Are you sure want to delete asset(s)?',
  PASSWORD: 'password',
  CONGRATULATIONS: "Congratulations!",
  DUPLICATE: "Duplicate data",
  DATA_EXISTS: "Data already exists",
  TOO_MANY_WRONG_ATTEMPTS: 'Too many wrong attempts!!!',
  INVALID_LENGTH: "Invalid Value Length",
  SUCCESS_MESSAGE: 'Successfully deleted',
  VALUE_LENGTH_ERROR_MSG: "The region name must be in between 3 and 64 characters",
  LABEL_LENGTH_ERROR_MSG: "The label length must be between 3 and 64 characters.",
 
  ENTER_DATA: "Please Enter Data",
  NO_DATA_MSG: 'No data available',
  NO_ACTIVE_DEVICE_MSG: 'No active assets are available.',
  NO_DECOMMISSION_DEVICE_MSG: 'No decommission assets are available.',
 
  NO_SCHEDULE_LIST: 'No Data Available',
  DEFAULT_ROLE_REMOVE: 'Default Role Cannot be removed',
  NO_PERMISSION: 'You do not have permission to edit the credential',
  ADMIN_ROLE: 'Admin',
  EDIT:'edit',
  DELETE: 'delete',
  DEACTIVATE: 'deactivate',
  REACTIVATE: 'reactivate',
  RESEND_LINK:'resend password link',
  INVITE: 'Invited',
  INVALID_INPUT: "Invalid Data!",
  INVALID_INPUT_MSG: "Only combination of Alphabets, Numbers and Special Characters[ - , _ , . , comma, () ] are allowed",
  SSO_REGISTRATION: 'Congratulations! You have registered successfully.',
  UNREAD_NOTIFICATION: "You don't have any unread notifications",
  NOTIFICATION_EMPTY: "You don’t have notifications so far!",
  ALERTS_EMPTY: "Your Alert box is empty!",
  UNREAD_ALERTS: "You don't have any unread alerts",
  REQUST_FAILURE: 'Request has been failed. Please contact support!',
  DELETE_USER: 'The User has been deleted successfully',
  ASSEST_SUCCESS: 'Asset summary report downloaded successfully',
  GLOBAL_FILTER_BTN_MSG: 'Select a asset to apply the filters',
  VENDOR_LIST: [
    { key: 'cisco', value: 'Cisco' },
    { key: 'arista', value: 'Arista' },
    { key: 'palo-alto', value: 'Palo Alto' }
  ],
  VENDOR_LIST_1: [
    // { key: 'cisco', value: 'Cisco' },
    // { key: 'arista', value: 'Arista' },
    { key: 'paloalto', value: 'paloalto' }
  ],
  CUSTOM_PORT: [
    { key: 'true', value: 'true' },
    { key: 'false', value: 'false' }
  ],
  DEVICE_LIST: [
    { key: 'router', value: 'Router' },
    { key: 'switch', value: 'Switch' },
    { key: 'Firewall', value: 'Firewall' }
  ],
  Global_Values: [
    { key: 'global', value: 'Global Credential' },
    { key: 'local', value: 'Local Credential' },
  ],
  PASSWORD_TYPE: [
    { key: 'password', value: 'Password' },
    { key: 'privatekey', value: 'Private Key' },
    { key: 'none', value: 'None' }
  ],
  CONNECTION_TYPE: [
    { key: 'ssh', value: 'SSH' },
    { key: 'api', value: 'API' }
  ],
  REGION_LIST: [
    {
      name: "Region-1",
      children: [
        {
          name: "Country-1",
          children: [
            { name: "State-1", id: 1 },
            { name: "State-2", id: 2 }
          ]
        },
        {
          name: "Country-2",
          children: [
            { name: "State-3", id: 3 },
            { name: "State-4", id: 4 }
          ]
        }
      ]
    },
    {
      name: "Region-2",
      children: [
        {
          name: "Country-3",
          children: [
            { name: "State-5", id: 5 },
            { name: "State-6", id: 6 }
          ]
        },
        {
          name: "Country-4",
          children: [
            { name: "State-7", id: 7 },
            { name: "State-8", id: 8 }
          ]
        }
      ]
    }
  ],
  DEVICE_LIST_DETAILS: [
    {
      label: "Device-1",
      id: 11,
      data: [
        {
          label: "d-1",
          id: 12,
          data: [
            { label: "d11", id: 1 },
            { label: "d12", id: 2 }
          ]
        },
        {
          label: "d-2",
          id: 13,
          data: [
            { label: "d21", id: 3 },
            { label: "d22", id: 4 }
          ]
        }
      ]
    },
    {
      label: "Device-2",
      id: 21,
      data: [
        {
          label: "d-3",
          id: 23,
          data: [
            { label: "d31", id: 5 },
            { label: "d32", id: 6 }
          ]
        },
        {
          label: "d-4",
          id: 24,
          data: [
            { label: "d33", id: 7 },
            { label: "d34", id: 8 }
          ]
        }
      ]
    }
  ],
  DEVICE_PARAMETER: [
    {
      "is_passed_1": true,
      "is_passed_2": false,
      "parameter": "Set 'ip authentication mode eigrp' ",
      "whiteList": {
        "value": true,
        "date": "29 oct"
      }
    },
    {
      "is_passed_1": false,
      "is_passed_2": true,
      "parameter": "Set 'ip authentication mode eigrp sdsadasd' ",
      "whiteList": {
        "value": false,
        "date": ""
      }
    }
  ],
  SCAN_TYPE_ARRAY:{
    FILE_UPLOAD: 'Upload your configuration files to generate reports ',
    SSH_MSG: 'Fetch configuration from asset to scan',
    API_MSG: 'Define your API details and start your scan'
  },
  CYBER_RES: {
    "status": 200,
    "message": "The widget data has been retrieved successfully",
    "data": [
        {
            "date_time": "2023-05-22 15:12:53.372974",
            "security_rating": 6,
            "new_event": true
        },
        {
          "date_time": "2023-05-23 15:12:53.372974",
          "security_rating": 6,
          "new_event": true
        },
        {
            "date_time": "2023-06-17 22:04:19.087269857",
            "security_rating": 6,
            "new_event": false
        },
        {
          "date_time": "2023-06-18 22:04:19.087269857",
          "security_rating": 8,
          "new_event": false
        },
        {
            "date_time": "2023-07-14 04:55:44.801565714",
            "security_rating": 41,
            "new_event": true
        },
        {
            "date_time": "2023-08-09 11:47:10.515861571",
            "security_rating": 92,
            "new_event": true
        },
        {
          "date_time": "2023-08-09 11:47:10.515861571",
          "security_rating": 92,
          "new_event": true
      },
      {
        "date_time": "2023-08-09 11:47:10.515861571",
        "security_rating": 92,
        "new_event": true
    },
      {
        "date_time": "2023-08-10 11:47:10.515861571",
        "security_rating": 92,
        "new_event": true
    },
    {
      "date_time": "2023-08-11 11:47:10.515861571",
      "security_rating": 92,
      "new_event": true
  },
  {
    "date_time": "2023-08-12 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-13 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-14 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-15 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-16 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-17 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-18 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
  {
    "date_time": "2023-08-19 11:47:10.515861571",
    "security_rating": 92,
    "new_event": true
  },
        {
            "date_time": "2023-09-04 18:38:36.230157428",
            "security_rating": 0,
            "new_event": true
        },
        {
            "date_time": "2023-10-01 01:30:01.944453284",
            "security_rating": 41,
            "new_event": true
        },
        {
            "date_time": "2023-10-27 08:21:27.658749142",
            "security_rating": 0,
            "new_event": true
        },
        {
            "date_time": "2023-11-22 15:12:53.373045",
            "security_rating": 41.29,
            "new_event": true
        }
    ]}
  }

export const duplicateFileMsg = (fileNames: Array<string>) => {
  return `You have uploaded duplicate ${fileNames.toString()} file(s).`
}
export const getRoleSuccessMsg=(roleName:string)=>  `Role ${roleName} created successfully!`
export const IDENTIFIED_UNIDENTIFIED = {
  FILE_UPLOAD_HEAD: 'Files uploaded',
  TAB_LABEL: {
    IDENTIFIED: 'Identified',
    UNIDENTIFIED: 'Unidetified'
  },
  IDENTIFIED_UNIDENTIFIED_TABLE_HEADER: {
    FILENAME: 'File Name',
    VENDOR: 'Vendor',
    DEVICE: 'Device',
    REGION: 'Region'
  },
  UPLOAD_IN_PROGRESS_MSG: 'Uploading files in progress..',
  SUBMIT_LABEL: 'Submit',
  SAVE_AND_LEAVE_LABEL: 'Save & Leave',
  APPLY_LABEL: 'Apply',
  VENDER_LABEL: 'Vendor',
  DEVICE_LABEL: 'Device',
  REGION_LABEL: 'Region',
  NO_UNDENTIFIED_DATA_MSG: 'No unidentified files are available.',
  NO_UNDENTIFIED_DATA_MSG_1: 'Seems you have identified all the files with Vendor & Assets.',
  TABLE_COLUMN_UN: ['fileName', 'vendor', 'device', 'region'],
  TABLE_COLUMN_ID: ['fileName', 'vendor', 'device', 'region'],
  TABLE_COLUMN_LABEL_UN: ['File Name', 'Vendor', 'Device', 'Region'],
  TABLE_COLUMN_LABEL_ID: ['File Name', 'Vendor', 'Device', 'Region'],
  SELECTED_LIST_DETAILS: {
    name: 'DISABLE TELNET',
    valueInfo: '2000/5000',
    details: [
      {
        isWhileList: false,
        description: '07SW5476E (CISCO-Switch)'
      },
      {
        isWhileList: false,
        description: '07SW5476E (CISCO-Switch)'
      },
      {
        isWhileList: false,
        description: '07SW5476E (CISCO-Switch)'
      },
      {
        isWhileList: true,
        description: '07SW5476E (CISCO-Switch)'
      },
      {
        isWhileList: false,
        description: '07SW5476E (CISCO-Switch)'
      },
      {
        isWhileList: true,
        description: '07SW5476E (CISCO-Switch)'
      },
      {
        isWhileList: false,
        description: '07SW5476E (CISCO-Switch)'
      }
    ]
  },
  IDENTIFIED_UNIDENTIFIED_RES: {
    UNIDENTIFIED_DATA: [
      {
        fileName: 'Ankur.txt',
        vendor: 'cisco',
        device: 'micro',
        region: 'agra'
      }
    ],
    IDENTIFIED_DATA: [
      {
        fileName: 'Ankur.txt',
        vendor: 'cisco',
        device: 'micro',
        region: 'agra'
      },
      {
        fileName: 'Ankur1.txt',
        vendor: 'cisco1',
        device: 'micro1',
        region: 'agra1'
      },
      {
        fileName: 'Ankur2.txt',
        vendor: 'cisco2',
        device: 'micro2',
        region: 'agra2'
      },
      {
        fileName: 'Ankur3.txt',
        vendor: 'cisco3',
        device: 'micro3',
        region: 'agra3'
      },
      {
        fileName: 'Ankur4.txt',
        vendor: 'cisco4',
        device: 'micro4',
        region: 'agra4'
      },
      {
        fileName: 'Ankur5.txt',
        vendor: 'cisco5',
        device: 'micro5',
        region: 'agra5'
      },
      {
        fileName: 'Ankur6.txt',
        vendor: 'cisco6',
        device: 'micro6',
        region: 'agra6'
      },
      {
        fileName: 'Ankur7.txt',
        vendor: 'cisco7',
        device: 'micro7',
        region: 'agra7'
      }
    ]
  },
  NO_DATA_FOR_UN_MSG: {
    MSG_LINE_1: 'No unidentified files are available.',
    MSG_LINE_2: 'Seems you have identified all the files with Vendor & Dssets.'
  },
  NO_DATA_FOR_ID_MSG: {
    MSG_LINE_1: 'No identified files are available.',
    MSG_LINE_2: 'Seems you have to upload the file(s) .'
  },
  DELETE_POP_INFO: {
    TITLE: 'Are you sure want to delete this file?',
    MSG: 'If you delete, you will not be able to find in details',
    CANCEL_LABEL: 'Cancel',
    DELETE_LABEL: 'Delete!'
  }
}
export const ROLES_ROUTE = {
  DASHBOARD: 'dashboard',
  MYSCANS: 'my_scans',
  USER_MGNT: 'user_management',
  DEVICE_MGNT: 'asset_management',
  REGION_MGNT: 'region_management',
  ROLE_MGNT: 'role_management',
  SECURITY_INFO: 'security_info',
  NEW_SCAN: 'new_scan',
  ACCEPTED_RISK: 'accepted_risk',
  CVE_DASHBOARD: 'cve-dashboard',
  CREDENTIAL_MANAGER: 'credential_manager',
  SCHEDULES: 'schedules'
}
export const REGION_ROUTE={
  MODIFY_REGION_DATA:'modify_region_data',
  MODIFY_REGION_LEVEL:'modify_region_level'
}
export const ROLES_CONTENT_PROPS = {
  NEW_SCAN: 'new_scan',
  UPLOAD_FILE: 'upload_file_new_asset',
  SSH_CONNECTION: 'ssh_connection_new_asset',
  API_CONNECTION: 'api_connection_new_asset',
  SCAN_DETAILS: 'scan_details',

  ADD_USER: 'add_user',
  DELETE_USER: 'delete_user',
  ACTIVATE_DEACTIVATE: 'activate_deactivate_user',
  RESET_PASS_LINK: 'reset_pass_link',

  ADD_DEVICE: 'add_asset',
  DELETE_DEVICE: 'delete_asset',
  RESCAN_DEVICE: 'rescan_asset',
  DECOMMISSION_DEVICE: 'decommission_asset',
  MODIFIY_DEVICE_LOCATION: 'modify_asset_location',

  MODIFY_ROLE_PERMISSION: 'modify_role_permissions',
  MODIFY_ROLE_REGION_DEVICE: 'modify_role_region_asset',
  MODIFY_REGION_LEVEL: 'modify_region_level',
  MODIFY_RISK_PARAMETER: 'modify_accepted_risk',
  SCHEDULES: 'schedules',
  CREDENTIAL_MANAGER: 'credential_manager',
  GLOBAL_CREDENTIAL: 'global_credential',
  LOCAL_CREDENTIAL: 'local_credential'
}


export const COMPOENT_NAME = {
  UPLOAD_FILE: 'Upload File',
  SSH_CONNECTION: 'SSH connection',
  API_CONNECTION: 'API connection',
  ADD_USER: 'Add User',
  DELETE_USER: 'Delete User',
  ACTIVATE_DEACTIVATE: 'Activate Deactivate User',
  RESET_PASS_LINK: 'Reset Pass Link'
}

export const EMAIL_ALREADY_REGISTERED = (emails: Array<string>): string => {
  let re = /\,/gi;
  return `(${emails.toString().replace(re, ', ')}) Email(s) has already registered.`;
}
export const EMAIL_SUCCESS_REGISTERED = (emails: Array<string>): string => {
  let re = /\,/gi;
  return `(${emails.toString().replace(re, ', ')}) Email(s) are registered.`;
}
export const CONTENT_MESSAGE = (option: any, currentUser: User): string => {
  if (option?.toLocaleLowerCase() === 'resend password link') {
    return `<p>Are you sure want to resend the password link to</p>\n<p class="text_ellipsis" title ="${currentUser.organization_email}?">${currentUser.organization_email}?</p>`;
  } else if (option?.toLocaleLowerCase() != 'resend password link') {
    return `<p>Are you sure want to ${option?.toLocaleLowerCase()} user</p>\n<p class="text_ellipsis" title ="${currentUser.organization_email}?">${currentUser.organization_email}?</p>`;
  }
  else {
    return `Are you sure want to ${option.toLocaleLowerCase()} user ${currentUser.organization_email}?`
  }
  return ''
}

export const GET_MENU_ITEM_DATA = (option: any, currentUser: User): any => {
  switch (option.toLowerCase()) {
    case 'delete':
      return {
        noBtnName: 'Cancel',
        yesBtnName: option,
        className: "btn-tartorange",
        subContent: `If you delete, you will not be able to find the user again`
      }
    case 'deactivate':
      return {
        noBtnName: 'Cancel',
        yesBtnName: option,
        className: "btn-tartorange",
        subContent:""
      }
    case 'reactivate':
      return {
        noBtnName: 'Cancel',
        yesBtnName: option,
        className: "btn-primary",
        subContent: `${currentUser.firstname} ${currentUser.lastname} (${currentUser.organization_email})`
      }
    case 'resend password link':
      return {
        noBtnName: 'Cancel',
        yesBtnName: 'Resend',
        className: "btn-primary",
        subContent:""
      }
    default:
      return {
        noBtnName: 'Cancel',
        yesBtnName: option,
        className: "filled-btn-1",
        subContent:""
      }
  }

}

export const USER_PERMISSION = (title: string | undefined | null | any): string => {
  let msg = 'You do not have permission to access this module . Please connect with administrator.'
  if (title) {
    msg = `You do not have permission to access "${title}". Please connect with administrator.`;
  }
  return msg;
}

export const ToolTipConfig = {
  showDelay: 0,
  hideDelay: 0,
  placement: 'bottom',
  offset: 0,
  display: true
}
export const CVE_PAGE = {
  cveSearchPlaceHolder: 'Search CVE',
  assetsSearchPlaceHolder: 'Search Asset',
  exploit: 'Exploited',
  nonExploit: 'Not Exploited',
  cisaKevHeaderTooltip: 'CISA’s Known Exploited Vulnerabilities',
  epssHeaderTooltip: {
    title: 'Exploit prediction scoring system',
    desc: 'Probability of exploitation activity next 30 days'
  },
  tab: {
    cve: 'CVE',
    assets: 'Assets'
  }
}