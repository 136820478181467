import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomDailog, IDialog } from '../model/Dialog.model';
import { Output, EventEmitter } from '@angular/core';
import { CustomDialog } from '../components/custom-dialog/custom-dialog.component';
import { TrailMessageComponent } from '../components/trail-message/trail-message.component';
import { AllPlansComponent } from '../components/all-plans/all-plans.component';
import { SubscriptionRequestComponent } from '../components/subscription-request/subscription-request.component';
import { DeleteDataComponent } from '../components/delete-data/delete-data.component';
import { Router } from '@angular/router';
import { PasswordConfirmationPopupComponent } from '../components/password-confirmation-popup/password-confirmation-popup.component';
import { CONSTANT } from '../constants/constant-data';


/*
  Here you go with the same method call for the service. 
  The service can be used in two ways.
    1. You can use the existing dialog 
    2. You can define your own component

  Below are the sample requests. 

    1. this.dialogService.openDialog(
        {title: 'Welcome', content: 'Welcome user', buttons:[{label: 'Ok', callBack: this.test}]});
    2. this.dialogService.openDialog({component: TestDialog})
*/

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  @Output() onClose = new EventEmitter<string>();
  public isNewUserData:any; 
  trailDialogRef!: MatDialogRef<TrailMessageComponent>;

  constructor(private dialog: MatDialog,
    private router: Router
  ) { }

  closeDialog = () => {
      this.dialog.closeAll();
  }

  openDialog = (payload: IDialog = {}) => {

    const data: CustomDailog = {
      title: payload.title || '',
      content: payload.content || '',
      buttons: payload.buttons || [{ label: 'Ok', class:'filled-btn-1' }],
      data: payload.data || {},
      apiStatus: payload.apiStatus,
      callBack: payload.callBack || null
    }

    const comp = payload.component || CustomDialog;
    let defaultClass = 'custom-dialog';
    if(payload.component){
      defaultClass = '';
    }
    const dialogRef = this.dialog.open(comp,
      {
        panelClass: payload.class || defaultClass,
        width: payload.width || '360px',
        height: payload.height,
        disableClose: payload.disableClose || true, 
        data: data,
        position:payload.position,
        exitAnimationDuration:payload.exitAnimationDuration,
        enterAnimationDuration:payload.enterAnimationDuration
      });

    dialogRef.afterClosed().subscribe(result => {
      this.onClose.emit(result);
    });
  }

  deleteUserData() {
    if (this.trailDialogRef) {
      this.trailDialogRef.close();
    }
    let passwordData = {
      title: CONSTANT.DELETE_DATA_TITLE,
      text:  CONSTANT.DELETE_DATA_SUB_TITLE,
      buttonLabel: CONSTANT.DELETE_BUTTON,
      password: CONSTANT.PASSWORD,
    }
    const payload: IDialog = {
      component:PasswordConfirmationPopupComponent,
      width: '350px',
      data: {
        userData: passwordData, 
        setCallbackFunction: (isDeleteData?:any,userData?:any) => {
          if(isDeleteData){
            // this.authService.deleteProgress();
            this.router.navigate(["login/delete"]);
            this.confirmDelete(userData)
          } 
          else {
            const currentDate = new Date();
              const expiryDate = new Date(userData.expiry_date);
              const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
              const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24));
              if (expiresInDays <= 0) {
                this.trailMessage(userData,this.isNewUserData);
              }

          }   
        }
      },
    }
   this.openDialog(payload);
  }
  confirmDelete(profileData:any) {
    
    this.deleteData(profileData);
  }

  trailMessage(userDetails:any,isNewUser?: any){
    if(isNewUser){
      this.isNewUserData = isNewUser;
    }
    this.trailDialogRef = this.dialog.open(TrailMessageComponent, {
      width: '380px',
      panelClass: 'trail-box',
      disableClose: true,
      data: {
        title: 'Cancel Upload',
        content: `This action will cancel the upload  selected files`,
        displayFlag: isNewUser,
        buttons: [{
          label: 'Upgrade',
          class: 'btn btn-primary',
          callBack: () => {
            this.plans(userDetails);
            //this.isSaveChanges = true;
            //this.spinnerService.show();
            //this.toolManagementService.createUpdateRole(currentRole, this.createUpdateRolecallback, false);
          }
        }, {
          label: 'Sign Out',
          class:'btn btn-outline red-outline',
          callBack: () => {
           
            
          }
        },
        {
          label: 'delete',
          class:'btn btn-outline red-outline',
          callBack: () => {
           this.deleteUserData()
          }
        }
      ],
        disableClose: true
      },
    });
    return this.trailDialogRef.afterClosed(); 
   }

   plans(userDetails:any,isNewScanDetails?:any){
    if (this.trailDialogRef) {
      this.trailDialogRef.close();
    }
    if(isNewScanDetails){
      this.isNewUserData = isNewScanDetails;
    }
    const dialogRef = this.dialog.open(AllPlansComponent, {
      width: '360px',
      panelClass: 'plans-box',
      disableClose: true,
      data: {
        title: 'Cancel Upload',
        content: `This action will cancel the upload selected files`,
        data:userDetails,
        buttons: [
          {
            label: 'close',
            callBack: () => {
              
              const currentDate = new Date();
              const expiryDate = new Date(userDetails.expiry_date);
              const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
              const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24));
              if (expiresInDays <= 0) {
                this.trailMessage(userDetails,this.isNewUserData);
              }
              
            }
          },
          {
          label: 'free',
          callBack: () => {
            //console.log("Free")
            this.subscriptionRequest(userDetails);
          }
        }, {
          label: 'gold',
          callBack: () => {
            //console.log("Gold")
            this.subscriptionRequest(userDetails);
          },
          
        },
        {
          label: 'diamond',
          callBack: () => {
           // console.log("Diamond")
            this.subscriptionRequest(userDetails);
          }
        }, {
          label: 'platinum',
          callBack: () => {
            this.subscriptionRequest(userDetails);
           // console.log("Platinum")
          }
      }],
        disableClose: true
      },
    });
    return dialogRef.afterClosed().subscribe(result => {

       //this.subscriptionRequest();
    }); 
   }

   subscriptionRequest(userDetails?:any){
    const dialogRef = this.dialog.open(SubscriptionRequestComponent, {
      width: '480px',
      panelClass: 'trail-box',
      disableClose: true,
      
      data: {
        title: 'Cancel Upload',
        content: `This action will cancel the upload  selected files`,
        data:userDetails,
        buttons: [{
          label: 'Yes',
          callBack: () => {
            //this.plans();
            //this.isSaveChanges = true;
            //this.spinnerService.show();
            //this.toolManagementService.createUpdateRole(currentRole, this.createUpdateRolecallback, false);
          }
        }, {
          label: 'No',
          callBack: () => {
            //this.isSaveChanges = false;
          
          }
        },
        {
          label: 'close',
          callBack: () => {
            
            const currentDate = new Date();
            const expiryDate = new Date(userDetails.expiry_date);
            const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
            const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24));
            if (expiresInDays <= 0) {
              this.trailMessage(userDetails,this.isNewUserData);
            }
            
          }
        }],
        disableClose: true
      },
    });
    return dialogRef.afterClosed().subscribe(result => {
      
    }); 
   }

   deleteData(userDetails:any){
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '380px',
      panelClass: 'trail-box',
      disableClose: true,
      data: {
        title: 'Cancel upload',
        content: `This action will cancel the upload  selected files`,
        userDetails:userDetails,
        buttons: [{
          label: 'Upgrade',
          class: 'btn btn-primary',
          callBack: () => {
            this.plans({});
            //this.isSaveChanges = true;
            //this.spinnerService.show();
            //this.toolManagementService.createUpdateRole(currentRole, this.createUpdateRolecallback, false);
          }
        }, {
          label: 'Sign Out',
          class:'btn btn-outline red-outline',
          callBack: () => {
            //this.isSaveChanges = false;
            
          }
        }],
        disableClose: true
      },
    });
    return dialogRef.afterClosed(); 
   }
}
