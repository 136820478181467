import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
const { detect } = require('detect-browser');

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private renderer!: Renderer2;
  
  constructor( private rendererFactory: RendererFactory2) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  detectBrowser(): string {
    const browserInfo = detect();
    return browserInfo ? browserInfo.name : 'unknown';
  }

  getBrowserClass() {
    const browser = this.detectBrowser();
    const baseBrowserStyles = browser ? `${browser.toLowerCase()}-styles` : 'unknown-browser-styles';
    this.renderer.addClass(document.body, baseBrowserStyles);
  }

  getBrowserSelectorClass():string {
    const browser = this.detectBrowser();
    const browserStyles = browser ? `${browser.toLowerCase()}-styles-unique` : 'unknown-browser-styles-unique';
    return browserStyles;
  }

}
