<div class="myaccount-prifiletab">
    <div class="prifiletab-header">
       
        <div class="personal_details">
            
        <div class="profile_photo" *ngIf="imageSrc?.length===0">
            <mat-icon aria-hidden="false" aria-label="Example person icon" 
                fontIcon="person"></mat-icon>
            <span class="edit-pan-icon" (click)="openFilePicker()"></span> 
            <input type="file" style="display: none;" #fileInput  (change)="handleImageInput(fileInput?.files)" accept=".jpg,.jpeg,.png" >  
        </div>
        <div class="profile_photo" *ngIf="imageSrc?.length">
            <mat-icon [style.background-image]="'url(' + imageSrc + ')'" aria-hidden="false"></mat-icon>
            <span class="edit-pan-icon" (click)="openFilePicker()"></span>
            <input type="file" style="display: none;" #fileInput  (change)="handleImageInput(fileInput?.files)" accept=".jpg,.jpeg,.png">
        </div>
     </div>
        <div *ngIf="hideUserdetail" class="Profile-name">
            <p>{{userDetails.firstname}}
                {{ userDetails.lastname}}</p>
        </div>
        <button class="btn edit-button" *ngIf="hideUserdetail && !isSsouser" (click)="showHideDetails()">Edit</button>
    </div>
    <div  class="profiletab-info">
        <div *ngIf="hideUserdetail" class="profiletab-box">
            <div class="profiletab-item">
                <div class="profiletab-itemlable">Email</div>
                <div class="profiletab-itemvalue">{{userDetails.organization_email}}</div>
            </div>
            <div class="profiletab-item">
                <div class="profiletab-itemlable">Role</div>
                <div class="profiletab-itemvalue">{{userDetails.role_name}}</div>
            </div>
            <!-- <div class="profiletab-item">
                <div class="profiletab-itemlable">Mobile</div>
                <div class="profiletab-itemvalue">{{userDetails.country_code}}
                    {{userDetails.phone_number}}</div>
            </div> -->
            <div class="profiletab-item">
                <div class="profiletab-itemlable">Organization</div>
                <div class="profiletab-itemvalue">{{userDetails.organization_name}}</div>
            </div>
        </div>
        <div *ngIf="!hideUserdetail" class="profiletab-edit">
            <form [formGroup]="userForm" [ngClass]="{'disabled': isSsouser}">
            <div>
                <mat-form-field class="example-full-width border-field no-bg" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="first_name" >
                    <mat-error *ngFor="let validation of validation_messages.first_name">
                        <mat-error class="error-message"
                            *ngIf="userForm.get('first_name')?.hasError(validation.type) && (userForm.get('first_name')?.dirty || userForm.get('first_name')?.touched)">
                            {{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width no-bg border-field" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" formControlName="last_name" >
                    <mat-error *ngFor="let validation of validation_messages.last_name">
                        <mat-error class="error-message"
                            *ngIf="userForm.get('last_name')?.hasError(validation.type) && (userForm.get('last_name')?.dirty || userForm.get('last_name')?.touched)">
                            {{validation.message}}</mat-error>
                    </mat-error>
            </mat-form-field>
            </div>
            <!-- <div>
                <mat-form-field class="example-full-width no-bg border-field" appearance="outline">
                    <mat-label>Country Code</mat-label>
                    <input matInput placeholder="Country Code"
                    [(ngModel)]="userDetails.country_code"
                    formControlName="country_code">
                <mat-error *ngFor="let validation of validation_messages.country_code">
                    <mat-error class="error-message"
                        *ngIf="userForm.get('country_code')?.hasError(validation.type) && (userForm.get('country_code')?.dirty || userForm.get('country_code')?.touched)">
                        {{validation.message}}</mat-error>
                </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width no-bg border-field" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="Phone Number"
                    [(ngModel)]="userDetails.phone_number"
                    formControlName="phone_number">
                <mat-error *ngFor="let validation of validation_messages.phone_number">
                    <mat-error class="error-message"
                        *ngIf="userForm.get('phone_number')?.hasError(validation.type) && (userForm.get('phone_number')?.dirty || userForm.get('phone_number')?.touched)">
                        {{validation.message}}</mat-error>
                </mat-error>
            </mat-form-field>
            </div> -->
        </form>
        </div>
        <div *ngIf="hideUserdetail">
        <div *ngIf="!userProfile.sso_enabled" class="profiletab-box">
            <div class="profiletab-item" (click)="onChangePassWord()">
                <div class="profiletab-itemlable">Change Password</div>
                <div class="profiletab-itemvalue"><mat-icon>keyboard_arrow_right</mat-icon></div>
            </div>
        </div>
        <div>
            <button class="btn btn-tartorange-outline" (click)="logout()">Sign Out</button>
        </div>
    </div>
        <div *ngIf="!hideUserdetail">
            <div class="profile_buttons">
                <button *ngIf="!hideUserdetail"  class="btn btn-blue"
                (click)="uploadProfileConnect(userForm)">Save</button>
            <button *ngIf="!hideUserdetail" class="btn btn-white"
                (click)="hideDetails()">Cancel</button>
            </div>
        </div>
    </div>
</div>