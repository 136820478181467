import { SelectionModel } from '@angular/cdk/collections';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { IDENTIFIED_UNIDENTIFIED, CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { CustomDailog, DialogButton, IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { IdentifiedUnidentified, IdentifiedUnidentifiedResponse, IidentifiedUnidentifiedHeaderDropdown, IRegion, IScanDropdown } from 'src/app/modules/shared/model/scan/scan';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { ScanService } from 'src/app/modules/shared/services/scan.service';
import { UploadService } from 'src/app/modules/shared/services/upload.service';
@Component({
  selector: 'app-identified-unidentified',
  templateUrl: './identified-unidentified.component.html',
  styleUrls: ['./identified-unidentified.component.scss']
})

export class IdentifiedUnidentifiedComponent implements OnInit {

  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('rMenu') rMenu!: ElementRef;
  currentSelectedTab: number = 0;
  selection = new SelectionModel<IdentifiedUnidentified>(true, []);
  public IdentifiedColumns: string[] = [];
  public UnidentifiedColumns: string[] = [];
  public unidentifiedData = new MatTableDataSource<IdentifiedUnidentified>([]);
  public identifiedData = new MatTableDataSource<IdentifiedUnidentified>([]);
  public vendorList: IScanDropdown[] = CONSTANT.VENDOR_LIST;
  public deviceList: IScanDropdown[] = CONSTANT.DEVICE_LIST;
  public isRegionShow: boolean = false;
  public treeControl =
    new NestedTreeControl<IRegion>(node => node.children);
  public regionDataSource: MatTreeNestedDataSource<IRegion>;
  public hasChild = (_: number, node: IRegion) =>
    !!node.children && node.children.length > 0;
  public selectedDropdpwn: IidentifiedUnidentifiedHeaderDropdown | any = {};
  public selectedIdAndUnidentifiedRow: IdentifiedUnidentified[] = [];
  public enableSubmitBtn: boolean = true;
  public get labelDetails() {
    return IDENTIFIED_UNIDENTIFIED;
  }
  // Return selected region with '/' as string
  public get selectedRegion(): string {
    let regionValue = '';
    this.selectedDropdpwn?.region?.forEach((obj: IRegion) => {
      if (obj.selected || obj.indeterminate) {
        regionValue += obj.name;
        obj.children?.forEach((childOne) => {
          if (childOne.selected || childOne.indeterminate) {
            regionValue += ' / ' + childOne.name;
            childOne.children?.forEach((childTwo) => {
              if (childTwo.selected || childTwo.indeterminate) {
                regionValue += ' / ' + childTwo.name;
              }
            })
          }
        })
      }
    });
    regionValue = regionValue || 'Select';
    return regionValue;
  }
  public get totalAndUploadedCount () {
    return {
      inProgressCount: this.scanService.fileUploadCount,
      totalCount: this.scanService.totalFileUpload,
    }
  }
  constructor(
    private overlayContainer: OverlayContainer,
    private renderer: Renderer2,
    private dialogService: DialogService,
    private scanService: ScanService,
    @Inject(MAT_DIALOG_DATA) public dialogData: CustomDailog,
    private uploadService: UploadService) {
    this.regionDataSource = new MatTreeNestedDataSource<IRegion>();
    this.clearDropdownValue();
    // event when user click out side in region dropdown
    this.renderer.listen('window', 'click', (e: Event | any) => {
      if (e?.target !== this.toggleButton?.nativeElement &&
        e?.target !== this.rMenu?.nativeElement &&
        e.target?.classList?.value !== 'mat-checkbox-inner-container' &&
        e.target?.classList?.value !== 'mat-checkbox-label') {
        this.isRegionShow = false;
      }
    });
    this.regionTreeDataUpdate();
    this.scanService.shareFilesAfterUpload.subscribe({
      next:(files)=> {
        if(files?.length){
          this.scanService.totalFileUpload = files.length;
          this.scanService.fileUploadCount = 0;
          this.enableSubmitBtn = false;
          this.scanService.loadUnidentifiedIdentified(files, this.successIdUnCallBack, this.failedIdUnCallBack)
        }
      }
    });
  }

  ngOnInit(): void {
    this.IdentifiedColumns = [...IDENTIFIED_UNIDENTIFIED.TABLE_COLUMN_ID];
    this.UnidentifiedColumns = [...IDENTIFIED_UNIDENTIFIED.TABLE_COLUMN_UN];
  }
  public successIdUnCallBack = (response: HttpResponse<IdentifiedUnidentifiedResponse>, currentReq: FormData) => {
    this.unidentifiedData.data = 
    this.unidentifiedData.data.concat(IDENTIFIED_UNIDENTIFIED.IDENTIFIED_UNIDENTIFIED_RES.UNIDENTIFIED_DATA);
    this.identifiedData.data =  
    this.identifiedData.data.concat(IDENTIFIED_UNIDENTIFIED.IDENTIFIED_UNIDENTIFIED_RES.IDENTIFIED_DATA);
    // this.scanService.fileUploadCount +=  
    // this.unidentifiedData.data.length + this.identifiedData.data.length;
    this.scanService.fileUploadCount += currentReq?.getAll('resumes')?.length;
  }
  public failedIdUnCallBack = (response:  HttpErrorResponse, currentReq: FormData) => {
   const currentUpladedFiles = currentReq.get('resumes');
   this.unidentifiedData.data = this.unidentifiedData.data.concat(IDENTIFIED_UNIDENTIFIED.IDENTIFIED_UNIDENTIFIED_RES.UNIDENTIFIED_DATA);
   this.identifiedData.data =  this.identifiedData.data.concat(IDENTIFIED_UNIDENTIFIED.IDENTIFIED_UNIDENTIFIED_RES.IDENTIFIED_DATA);
   // this.scanService.fileUploadCount +=  this.unidentifiedData.data.length + this.identifiedData.data.length;
   this.scanService.fileUploadCount += currentReq?.getAll('resumes')?.length;
   if(this.scanService.fileUploadCount === this.scanService.totalFileUpload){
    this.enableSubmitBtn = false;
   }
  }
  public closeDialog(): void {
    this.dialogService.closeDialog();
  }
  // Submit after for 
  public submit(btn: DialogButton): void {
    if(btn?.callBack){
      btn?.callBack('test');
    }
  }
  public uploadFiles(event: any): void {
    if (event.target?.files.length > 0 && this.uploadService.validateFileUpload(event.target?.files, [])) {
      this.scanService.shareFilesAfterUpload.next(event.target?.files);
    }
  }
  
  public selectTab(event: MatTabChangeEvent): void {
    this.currentSelectedTab = event.index;
    this.clearDropdownValue();
    this.clearTableData();
  }
  // select device / vendor dropdown value
  public selectedValue(value: string, type: string): void {
    if (value && type) {
      if (type === 'device') {
        this.selectedDropdpwn.device = value;
      } else if (type === 'vendor') {
        this.selectedDropdpwn.vendor = value;
      }
    }
  }

  // show hide region dropdown
  public toggleRegionDropDown(): void {
    this.isRegionShow = !this.isRegionShow;
  }
  // in table when user select checkbox
  public selectedUnidentifiedTableData(selection: SelectionModel<IdentifiedUnidentified>): void {
    this.selection = selection;
    this.selectedIdAndUnidentifiedRow = this.selection.selected;
  }
  public regionTreeDataUpdate(): void {
    this.regionDataSource.data = CONSTANT.REGION_LIST;
    Object.keys(this.regionDataSource?.data).forEach((key: string | any) => {
      this.setParent(this.regionDataSource?.data[key], null, 0);
    });
  }
  // select / unselect region dropdown value
  public itemToggle(checked: boolean, node: IRegion): void {
    node.selected = checked;
    if (node.children) {
      node.children.forEach(child => {
        this.itemToggle(checked, child);
      });
    }
    this.checkAllParents(node);
  }

  // update region dropdown value in saperate array
  public updateRegionSelection(node: IRegion | any): void {
    if (!node.parent) {
      if (this.selectedDropdpwn?.region?.length) {
        const findIndex =
          this.selectedDropdpwn?.region.findIndex((obj: IRegion) => obj.name === node.name);
        if (findIndex > -1) {
          this.selectedDropdpwn.region[findIndex] = node;
        } else {
          this.selectedDropdpwn?.region.push(node);
        }
      } else {
        this.selectedDropdpwn?.region?.push(node);
      }
    } else {
      if (!node?.parent?.parent && node.lavel === 1) { // second lavel
        if (this.selectedDropdpwn?.region?.length) {
          const findIndex =
            this.selectedDropdpwn?.region.findIndex((obj: IRegion) => obj.name === node?.parent?.name);
          if (findIndex > -1) {
            this.selectedDropdpwn.region[findIndex] = node.parent;
          } else {
            this.selectedDropdpwn?.region.push(node.parent);
          }
        } else {
          this.selectedDropdpwn?.region?.push(node?.parent);
        }
      }
      if (!node?.parent?.parent?.parent && node.lavel === 2) { // third lavel
        if (this.selectedDropdpwn?.region?.length) {
          let findIndex: number =
            this.selectedDropdpwn?.region.findIndex((obj: IRegion) => obj.name === node?.parent?.parent?.name);
          if (findIndex > -1) {
            this.selectedDropdpwn?.region ? [findIndex] = node?.parent?.parent : {};
          } else {
            this.selectedDropdpwn?.region?.push(node?.parent?.parent);
          }
        } else {
          this.selectedDropdpwn?.region?.push(node?.parent?.parent);
        }
      }
    }
  }

  // enable disable apply button
  public isApplyEnable(): boolean {
    return (!this.selectedDropdpwn?.vendor?.length ||
      !this.selectedDropdpwn?.device?.length ||
      (!this.selectedDropdpwn?.region?.length && this.selectedRegion === 'Select'));
  }

  // Apply selected value from dropdown to table data
  public applyDropdownSelection() {
    if (!this.isApplyEnable() && this.selectedIdAndUnidentifiedRow.length) {
      if (this.currentSelectedTab === 0) {
        this.scanService.applySelectedValueFromDropdown(this.unidentifiedData.data,
          this.selectedIdAndUnidentifiedRow, this.selectedDropdpwn, this.selectedRegion);
      } else if (this.currentSelectedTab === 1) {
        this.scanService.applySelectedValueFromDropdown(this.identifiedData.data,
          this.selectedIdAndUnidentifiedRow, this.selectedDropdpwn, this.selectedRegion);
      }
      this.clearDropdownValue();
      this.clearTableData();
    }
  }
  public deletePopup(): void {
    const payload: IDialog = {};
    payload.title = this.labelDetails.DELETE_POP_INFO.TITLE;
    payload.content = this.labelDetails.DELETE_POP_INFO.MSG;
    payload.class = 'delete-un-id-popup';
    payload.width = '340px';
    payload.buttons = [
      {
        label: this.labelDetails.DELETE_POP_INFO.CANCEL_LABEL,
        callBack: () => {
          this.dialogService.closeDialog();
        }
      },
      {
        label: this.labelDetails.DELETE_POP_INFO.DELETE_LABEL,
        callBack: () => {
          this.deleteRows();
        }
      }
    ];
    this.dialogService.openDialog(payload);
  }
  // delete current row
  public deleteRow(currentRow: IdentifiedUnidentified): void {
    this.selectedIdAndUnidentifiedRow.push(currentRow);
    this.deletePopup();
  }
  // Delete row when user select multiple row.
  public deleteRows(): void {
    if (this.selectedIdAndUnidentifiedRow.length) {
      if (this.currentSelectedTab === 0) {
        this.unidentifiedData.data = this.scanService.deleteSelectedRow(this.unidentifiedData.data,
          this.selectedIdAndUnidentifiedRow);
      } else if (this.currentSelectedTab === 1) {
        this.identifiedData.data = this.scanService.deleteSelectedRow(this.identifiedData.data,
          this.selectedIdAndUnidentifiedRow);
      }
      this.clearTableData();
      this.clearDropdownValue();
    }
  }

  // update parent detail in current obj
  private setParent(node: IRegion, parent: IRegion | null, level: number): void {
    node.parent = parent;
    node.lavel = level;
    if (node.children) {
      node.children.forEach(childNode => {
        this.setParent(childNode, node, level + 1);
      });
    }
  }
  // check parent level
  private checkAllParents(node: IRegion): void {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected =
        descendants.every(child => child.selected);
      node.parent.indeterminate =
        descendants.some(child => child.selected);
      this.checkAllParents(node.parent);
    }
  }
  // clear dropdown
  private clearDropdownValue(): void {
    this.selectedDropdpwn.vendor = '';
    this.selectedDropdpwn.device = '';
    this.selectedDropdpwn.region = [];
    this.regionTreeDataUpdate();
    this.selectedIdAndUnidentifiedRow = [];
  }
  // clear table data
  private clearTableData(): void {
    this.selection?.clear();
  }
}
