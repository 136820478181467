import { Injectable } from '@angular/core';
import { ResponseService } from './response.service';
import { RestService } from './rest.service';
import { END_POINTS } from '../constants/endpoints';
import { MessageServiceService } from './message-service.service';
import { AuthService } from './auth.service';
import { SpinnerService } from './spinner.service';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { CONSTANT } from '../constants/constant-data';
import { IToastrOptions } from '../model/IMessage.model';
import { mergeMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private restService: RestService,
    private responseService: ResponseService,
    private messageService: MessageServiceService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getRolesNAuthApi();
  }

  public authenticateSingleSignIn(callback: Function){
    const payload: any = {
      "sso_code": "microsoft_sso"
  }
  this.restService.postApi(END_POINTS.AUTHENTICATE_SINGLE_SIGNIN, payload).subscribe({
    next: (response) => {
      callback(this.responseService.successResponse(response));
    },
    error: (err) => {
      callback(this.responseService.errorResponse(err));
    },
  })
  
  }

  public getRolesNAuthApi(): Observable<any> | any {
    this.spinnerService.show('', true);
    return this.authService.isLoggedIn() && (!Object.keys(this.authService.getRoles()).length || !this.authService.refreshTokenAvailable) ?
      this.authService.refreshToken().pipe(
        mergeMap(result => {
          if (result.status === 200) {
            this.authService.setAccessTokens(result.data);
            this.notificationService.bellRefreshSubject.next()
            return this.fetchRoles()
          } else {
            this.authService.logOut();
            return false;
          }
        }),
        take(1)
      )
      : Promise.resolve().then(() => {
        this.spinnerService.hide();
        return true
      }).catch(() => {
        this.spinnerService.hide();
        return false;
      })
  }

  public fetchRoles(callback?: Function): any {
    return this.restService.getApi(END_POINTS.USER_PERMISSION).toPromise().then((data) => {
      const userPermission: any = this.responseService.successResponse(data);
      this.spinnerService.hide();
      if (userPermission.status === 200) {
        this.authService.setRole(userPermission.data.permissions);
        if (userPermission.data.permissions && userPermission.data.permissions.length === 0) {
          const snakMsg: IToastrOptions = {
            message: CONSTANT.USER_PERMISSION
          }
          this.messageService.showError(snakMsg);
          return false;
        }
      }
      if (callback) {
        callback();
      }
      return true;
    }).catch((err) => {
      this.spinnerService.hide();
      if (callback) {
        callback();
      }
      this.authService.logOut();
      return false;
    })
  }
}
