import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, catchError, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPasswordChage, IPermission } from '../model/login/login';
import { END_POINTS } from '../constants/endpoints';
import { CookieService } from 'ngx-cookie-service';
import { MessageServiceService } from './message-service.service';
import { FilterService } from './filter.service';
import { IToastrOptions } from '../model/IMessage.model';
import { CONSTANT, ROLES_CONTENT_PROPS, USER_PERMISSION } from '../constants/constant-data';
import { EncryptService } from 'src/app/modules/shared/services/encrypt.service';
import { SharedService } from './shared.service';
import { SpinnerService } from './spinner.service';
import { DialogService } from './dialog.service';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { GlobalSearchService } from './global-search.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public roleDetails: IPermission | any = {};
  public userLoggedInSubject = new Subject<void>();
  public userLoggedOutSubject = new Subject<void>();
  private tokenTimeoutSubject = new Subject<void>();
  userLoggedIn = this.userLoggedInSubject.asObservable();
  userLoggedOut = this.userLoggedOutSubject.asObservable();
  shouldStartTimer = this.tokenTimeoutSubject.asObservable();
  public scanDetailsSubject = new Subject<any>();
  scanDetails = this.scanDetailsSubject.asObservable();
  public myScansSubject = new Subject<any>();
  myScansEvent = this.myScansSubject.asObservable();
  public socket: Socket | null = null;

  encrtptClientId: string;
  encrtptClientSecret: string;
  tokenValidTime: number = 0;
  refreshTokenAvailable: boolean = false;
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  constructor(
    private router: Router, 
    private http: HttpClient, 
    private cookie:CookieService,
    private messageService: MessageServiceService, 
    private filterService: FilterService,
    private encryptService: EncryptService,
    private sharedService: SharedService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private globalSearchService: GlobalSearchService
    ) {
      this.encrtptClientId = this.encryptService.doEncryptLogin(this.encryptService.CLIENT_ID);
      this.encrtptClientSecret = this.encryptService.doEncryptLogin(this.encryptService.CLIENT_SECRET);
      //this.socketConnection();
    }

  authenticateUser(user: any, newSession?: boolean): Observable<any> {
    // get Encrypted cilent id and secret and encrypt to btoa format
    const btoaEncryptIDAndSecret = window.btoa(`${this.encrtptClientId}:${this.encrtptClientSecret}`);
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Basic ${btoaEncryptIDAndSecret}` })
    }
    let requestPayload;
    if (newSession) {
      requestPayload = {
        username: user.userName.toLowerCase(),
        password: this.encryptService.doEncryptLogin(user.password),
        new_session: true
      }
    } else {
      requestPayload = {
        username: user.userName.toLowerCase(),
        password: this.encryptService.doEncryptLogin(user.password)
      }
    }
    return this.http.post(END_POINTS.LOGIN, requestPayload, httpOptions);
  }
 
  /**
   * logoutUser will logout the user from the active session
   * @param token 
   * @returns Observable
   */
  logoutUser(): Observable<any> {
    const token = this.getToken()
    const btoaEncryptIDAndSecret = window.btoa(`${this.encrtptClientId}:${this.encrtptClientSecret}`);
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': `Basic ${btoaEncryptIDAndSecret}`})
    }
    return this.http.get(`${END_POINTS.LOGOUT}?token=${token}`, httpOptions);
  }

  /**
   * refreshToken would call the refresh token api when expires_in is reaches
   * @param token 
   * @returns 
   */
  refreshToken(refreshtoken:any=null): Observable<any> {
    let refreshToken: any = this.getRefreshToken();
    if(refreshtoken!=null) {
      refreshToken = refreshtoken;
    }
    
    const btoaEncryptIDAndSecret = window.btoa(`${this.encrtptClientId}:${this.encrtptClientSecret}`);
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': `Basic ${btoaEncryptIDAndSecret}`})
    }
    return this.http.post(END_POINTS.REFRESH_TOKEN, {
      "grant_type": "refresh_token",
      "refresh_token": refreshToken
    }, httpOptions).pipe(
      catchError((error) => {
        this.spinnerService.hide();
        this.logOut();
        return of(false);
      })
    );
   }

  registerUser(register: any): Observable<any> {
    return this.http.post(END_POINTS.REGISTER, {
      "firstname": register.userName,
      "lastname": register.lastName,
      "organization_email": register.email.toLowerCase(),
      "organization_name": register.orgName.trim(),
      "password": this.encryptService.doEncryptLogin(register.password)
    });
  }

  resendEmail(email:string): Observable<any> {
    return this.http.post(END_POINTS.RESEND_EMAIL,{
      email : email.toLowerCase()
    });
  }

  isLoggedIn() {
    const token = this.cookie.get('token');
    return token !=null &&  token !='';
  }

  getToken() {
    if(this.getCookie('token')) {
       return  this.getCookie('token')
    }
    
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token')
    }
    return  '';
  }

  getRefreshToken() {
    if(this.getCookie('refresh_token')) {
      return  this.getCookie('refresh_token')
   }
   
   if (localStorage.getItem('refresh_token')) {
     return localStorage.getItem('refresh_token')
   }
   return  '';
  }

  setAccessTokens(data: any):void{
    const timer: any = data.expires_in * 1000 - (data.expires_in * 1000 * 0.05);
    this.setCookie('refresh_token', data.refresh_token);
    localStorage.setItem('token', data.access_token);
    
    this.setCookie('token', data.access_token);
    localStorage.setItem('refresh_token', data.access_token);
    
    this.tokenValidTime = timer;
    this.refreshTokenAvailable = true;
    this.tokenTimeoutSubject.next();
    this.messageService.toggleErrorMesg(true);
  }

  setCookie(key: string, value:string):void{
    this.deleteCookie(key);
    this.cookie.set(key, value, undefined, "/", "", true);
  }

  checkCookie(key: string): boolean{
    return this.cookie.check(key);
  }

  getCookie(key: string): string{
    return this.cookie.get(key);
  }

  deleteCookie(key: string): void{
    this.cookie.delete(key, '/');
  }


  isMac():boolean {
    return window.navigator.platform.indexOf("Mac") !== -1;
  }

  logOut(isRediction: boolean = true, shouldCloseDialog: boolean = true,reload:boolean=false,showLogoutMsg: boolean = false) {
    if (localStorage.getItem('isReloading')) {
      console.log('Page is reloading, exiting logOut');
      localStorage.removeItem('isReloading');
      this.router.navigate(["login"]);
      return;
    }
    if(shouldCloseDialog){
    this.dialogService.closeDialog()
    }
    this.removeToken();
    sessionStorage.clear();
    localStorage.clear()
    this.userLoggedOutSubject.next()
    if (isRediction) {
      this.router.navigate(["login"]);
    }
    this.setRole([]);
    this.closeSocketConnection()
    this.filterService.closeSidenav();
    this.sharedService.toggleBodyClass(false);
    this.messageService.toggleErrorMesg(false);
    this.globalSearchService.mainSearchData = null;
    this.globalSearchService.type = 0;
    if(reload) {
      localStorage.setItem('isReloading', 'true');
      window.location.reload();
    }
    if(showLogoutMsg){
      const snakMsg: IToastrOptions = {
        message: CONSTANT.LOGOUT_DEVICE
      }
      this.messageService.toggleErrorMesg(true);
      this.messageService.showError(snakMsg)
    }
  }

  deleteProgress(isRediction: boolean = true, shouldCloseDialog: boolean = true) {
    if(shouldCloseDialog){
    this.dialogService.closeDialog()
    }
    this.router.navigate(["login/delete"]);
  }

  idleTimeOut(){
    this.logOut(false);
  }

  toggleUserInfo(makeDefault: boolean = true) {
    const isNewUserDetails = JSON.parse(this.getCookie('isNewUserDetails') || '{}');
    if(makeDefault){
      if (isNewUserDetails?.device_count > 0) {
        this.setCookie('isNewUserDetails', JSON.stringify(
          { 
            new_user: true, 
            device_count: 0, 
            isAddOrDeleteAction: false } || {}
          ));
          this.setCookie('affectedNoData', JSON.stringify({
            total: 0,
            affected: 0
          }));
      }
    }else{
      if (isNewUserDetails?.new_user) {
        this.setCookie('isNewUserDetails', JSON.stringify(
          { 
            new_user: false, 
            device_count: 1, 
            isAddOrDeleteAction: false } || {}
          ));
          this.setCookie('affectedNoData', JSON.stringify({
            total: 1,
            affected: 2
          }));
      }
    }
    
  }

  removeToken() {
    console.trace();
    this.tokenValidTime = 0;
    this.refreshTokenAvailable = false;
    const userDetail = this.cookie.get('userDetail');
    this.deleteCookie('roles');
    this.deleteCookie('token');
    this.deleteCookie('refresh_token');
    this.cookie.deleteAll();
    sessionStorage.clear();
    localStorage.clear()
    if(userDetail) {
      this.cookie.set('userDetail', userDetail);
    }
    this.removeFilters();
  }
  passwordReset(password: any): Observable<any> {
    return this.http.post(END_POINTS.RESET, {
      email: password.email.toLowerCase()
    });
  }
  changePassword(user: IPasswordChage, token: string, isLoginWindow: boolean, isNewUser: boolean): Observable<any> {
    const req: IPasswordChage = {};
    let endPoint = '';
    if(isLoginWindow){
      if(isNewUser){
        req.firstname = user.firstname;
        req.lastname =  user.lastname;
        req.password = this.encryptService.doEncryptLogin(user.password);
        req.password1 = this.encryptService.doEncryptLogin(user.password);
        req.flag = isNewUser;
      }
      else {
        req.password = this.encryptService.doEncryptLogin(user.password);
        req.password1 = this.encryptService.doEncryptLogin(user.password);
        req.flag = isNewUser;
      }
      endPoint = END_POINTS.CHANGE_PASSWORD + '/' + token;
    } else {
      req.new_password = this.encryptService.doEncryptLogin(user.password);
      req.new_password1 = this.encryptService.doEncryptLogin(user.password);
      req.old_password = this.encryptService.doEncryptLogin(user.oldPassword);
      endPoint = END_POINTS.UPDATED_PASSWORD;
    }
    return this.http.post<any>(endPoint, req);
  }
  public setRole(roles: IPermission[]): void {
   const permission: IPermission | any = {};
   roles?.forEach((role: IPermission)=>{
    if(role.value === 'true') {
      role.value = true;
    } else if(role.value === 'false') {
      role.value = false;
    } else if(role.value && !isNaN(role?.value)){
      role.value = Number(role.value)
    }
    permission[role.code] = role.value;
   })
  // making new_scan props forcefully true/false based on sub component props
    if(Object.keys(permission).length) {
      permission[this.permissionProps.NEW_SCAN] = permission[this.permissionProps.UPLOAD_FILE] || 
      permission[this.permissionProps.SSH_CONNECTION] ||
      permission[this.permissionProps.API_CONNECTION];
    }
   this.roleDetails = permission;
  }
  public getRoles(): IPermission {
    return this.roleDetails;
  }
  public removeRole(): void {
    this.cookie.delete('roles');
  }
  public removeFilters() {
    const allCookies = this.cookie.getAll();
    if(allCookies && Object.keys(allCookies).length) {
      for(const key in allCookies) {
        if(key!=='userDetail') {
          this.deleteCookie(key);
        }
      }
    }
  }
  public isRoleAccess(role: string): boolean | string | number {
    let canAccess: boolean | number | string = false;
    let roles: IPermission | any = this.getRoles();
    if (roles && Object.keys(roles).length) {
      canAccess = roles[role];      
      return canAccess;
    }
    return true;
  }
  public permissionMsg(title: string, props: string): boolean {
    if(!this.isRoleAccess(props)) {
      const msg: IToastrOptions = {};
      msg.message = USER_PERMISSION(title);
      this.messageService.showError(msg);
      return false;
    }
    return true;
  }

  passwordValidation(password: string){
    const password_encrypt = this.encryptService.doEncryptLogin(password);
    return this.http.post<any>(END_POINTS.PASSWORD_VALIDATION, {password: password_encrypt});
  }

  public socketConnection(){
    console.log("ffff")
    if(this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log('Socket disconnected');

    }
    if(this.isLoggedIn()){
      const token = this.getToken();
      if(token){
         this.socket = io(environment.WebSocket + `?token=${token}&device_code=d48iif4qdPzyoS8yZ0DqPQmBNIjD2Cc3wXWSaqxO`)
        this.socket.on('connect', () => {
        });
        
        
      }
    }
  }

  public closeSocketConnection() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log('Socket disconnected');
    }
  }

}  
