import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { IOrgDetail, IOrgDetailResponse, IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { ProfileService } from 'src/app/modules/shared/services/profile.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { SSOService } from 'src/app/modules/shared/services/sso-service';
import { Utils } from 'src/app/modules/shared/utils';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';


@Component({
  selector: 'app-organisation-profile',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit {
  hideUserdetail: boolean = true;
  orgForm: FormGroup
  orgDetails: IOrgDetail = {}
  ssoList: any[] = [];
  imageSrc: string | undefined;
  ssoData = {sso: false};
  defaultSso = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  validation_messages = {
    organization_address: [{ type: 'required', message: CONSTANT.ORGANIZATION.ORG_ADDRESS_REQUIRED },
    { type: 'minlength', message: CONSTANT.ORGANIZATION.ORG_ADDRESS_MIN_LEN },
    { type: 'maxlength', max: 128, message: CONSTANT.ORGANIZATION.ORG_ADDRESS_MAX_LEN },
    { type: 'pattern', pattern: /^[^\s][\s\S]*[^\s]$/, message: CONSTANT.ORGANIZATION.ORG_PROP_ADDRESS }
    ],
    organization_location: [
      { type: 'required', message: CONSTANT.ORGANIZATION.ORG_LOCATION_REQUIRED },
      { type: 'patern', message: CONSTANT.ORGANIZATION.ORG_LOCATION_SELECT }
    ]
  };

  constructor(private fb: FormBuilder,
    public profileService: ProfileService,
    public ssoService: SSOService,
    private spinnerService: SpinnerService,
    private headerService: HeaderService,
    public dialogService: DialogService,
    public messageService: MessageServiceService) {
    this.loadOrganisation();
    this.headerService.setHeading("My Organization");
    this.orgForm = this.fb.group({
      "organization_address": new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(128),
        Validators.pattern(/^[^\s][\s\S]*[^\s]$/)
      ])),
      "organization_location": new FormControl("", Validators.compose([
        Validators.required
      ])),
      sso_status: new FormControl(null),
      sso_id: new FormControl(null),
    })
  }

  ngOnInit(): void {
    this.headerService.setHeading('My Organization');
    this.loadOrgImage();
  }

  public showHideDetails() {
    this.hideUserdetail = false;
  }

  public hideDetails() {
    this.hideUserdetail = true;
  }

  public loadOrganisation(): void {
    this.profileService.getOrgDetails(this.organisationCallback);
  }
  public organisationCallback = (results: IOrgDetailResponse) => {
    if (results.status === 200 && results.data) {
      this.orgDetails = results.data;
      this.profileService.fetchUserDetails(this.userProfileCallback);
    }
  }

  public userProfileCallback = (results: IUserDetail) => {
      if(results?.is_admin){
        this.ssoService.getListOfSSO(this.onSSOListCallback);
        this.ssoService.getSSODetails(this.getSSODetailsCallback);
      }
  }

  uploadOrganisationConnect(orgForm: FormGroup) {
    if (this.orgForm.valid) {
      const { organization_address, organization_location } = orgForm.value
      const data = { organization_address, organization_location }
      this.profileService.postOrgDetails(data, this.uploadOrganisationResponse);
    }
  }

  public getSSODetailsCallback = (response: any) => {
    if(response.status === 200){
      this.ssoData = response.data;
      this.defaultSso = this.ssoData.sso;
    }
  }

  public onSSOListCallback = (response: any) => {
    if(response.status === 200){
      this.ssoList = response.data.sso_list;
      if(this.ssoList.length > 0){
        this.orgForm.controls['sso_id'].setValue(this.ssoList[0].id);
      }
    }
  }

  openFilePicker() {
    this.fileInput.nativeElement.click();
  }

  handleImageInput(files: FileList | null | undefined) {
    if (files && files.length > 0) {
      const selectedFile: File = files[0];
      const formData = new FormData();
      formData.append('picture', selectedFile, selectedFile.name);
      this.profileService.saveOrgImage(formData, this.saveOrgImageCallback);
    }
  }

  public saveOrgImageCallback = (response: any) => {
    this.spinnerService.hide();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.profileService.setUserImage('', '');
      this.loadOrgImage();

    }
  }

  public uploadOrganisationResponse = (response: any) => {
    this.spinnerService.hide();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.hideUserdetail = true;
      this.loadOrganisation();
    }
  }
  public loadOrgImage() {
    this.profileService.fetchOrgImage(this.fetchOrgImageCallback, 100, 100);
  }

  public fetchOrgImageCallback = (response: any) => {
    this.imageSrc = response;
  }
  makeFirstLetterCapital(str: string | undefined) {
    return Utils.makeFirstLetterCapital(str)
  }

  ssoToggle(event: MatSlideToggleChange): void {
    this.toggleValue(this.ssoData.sso);
    const windowInfo = {
      'true': {
        title: 'Are you sure want to turn on the SSO?',
        content: 'If you turn on the SSO, all existing users will now have to login only through SSO and password based login is not allowed.',
        class: 'btn-primary',
        label: 'Turn On'
      },
      'false': {
        title: 'Are you sure want to turn off the SSO?',
        content: 'If you turn off the SSO, all active SPARK users of your application can use Password Forgot/Reset option to login to the application. Please ensure only required users are active before disabling the SSO for security reasons.',
        class: 'btn-red',
        label: 'Turn Off'
      }
    }

    const info = this.ssoData.sso ? windowInfo.false : windowInfo.true;

    this.dialogService.openDialog(
      {
        title: info.title,
        content: info.content,
        class: 'sso-popup',
        buttons: [
        {
          label: 'Cancel',
          class: 'btn-grayborder',
          callBack: () => {
            this.toggleValue(this.ssoData.sso);
          }
        },
        {
          label: info.label,
          class: info.class,
          callBack: () => {
            this.toggleValue(!this.ssoData.sso);
          }
        }
        ],
        disableClose: true
      }
    );
  }

  toggleValue(flag:boolean){
    this.ssoData.sso = flag;
    this.orgForm.controls['sso_status'].setValue(flag);
  }

  onCancelSSO(){
    this.toggleValue(this.defaultSso);
  }

  onSaveSSO(){
    const obj = this.orgForm.value;
    const payload = {
      'sso_id':  obj['sso_status'] === true ? obj['sso_id']: null,
      'sso_status': obj['sso_status']
    }
    this.ssoService.saveSSODetails(payload, this.saveSSODetailsCallback);
  }

  public saveSSODetailsCallback = (response: any) => {
   if(response.status === 200){
    const snakMsg: IToastrOptions = {
      message: response.message
    }
    this.messageService.showSuccess(snakMsg);
    this.defaultSso = this.ssoData.sso;
   }else{
    const snakMsg: IToastrOptions = {
      message: CONSTANT.REQUST_FAILURE
    }
    this.messageService.showError(snakMsg);
    this.toggleValue(this.defaultSso);
   }
  }
}
