import { Component } from '@angular/core';
import { IApply, ITreeView } from '../filter/filter.model';
import * as _ from 'lodash';
import { GlobalFilterService } from '../../services/global-filter.service';
import { SpinnerService } from '../../services/spinner.service';
import { FilterService } from '../../services/filter.service';
import { Subscription } from 'rxjs';
import { IToastrOptions } from '../../model/IMessage.model';
import { MessageServiceService } from '../../services/message-service.service';
import { CONSTANT } from '../../constants/constant-data';
import { ProfileService } from '../../services/profile.service';
import { Utils } from '../../utils';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.scss']
})
export class GlobalFilterComponent {
  regionGlobalFilterData: Map<String, ITreeView[]> = new Map<String, ITreeView[]>();
  deviceGlobalFilterData: ITreeView[] | undefined = [];
  isFirstLevelApiCalled: boolean = false;
  isSecondLevelApiCalled: boolean = false;
  public firstLevelDataBackUp: any = null;
  public filterSubscription!: Subscription;
  constructor(
    private globalFilterService: GlobalFilterService,
    private spinnerService: SpinnerService,
    private filterService: FilterService,
    private messageService: MessageServiceService,
    private profileService: ProfileService,
    private sharedService:SharedService
  ) { }

  ngOnInit() {
    this.filterSubscription = this.globalFilterService.toggleFilterState.subscribe(
      (flag: boolean) => {
        this.openGlobalFilter(flag);
      }
    )
  }
  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }
  public openGlobalFilter(flag: boolean) {
    this.isFirstLevelApiCalled = false;
    this.isSecondLevelApiCalled = false;
    this.regionGlobalFilterData.clear();
    this.filterService.isFilterTouch = false;
    this.filterService.toggleSidenav('globalfilter');
    if (!this.regionGlobalFilterData.get('Region')?.length) {
      this.firstLevelDataBackUp = null;
      // this.spinnerService.show();
      this.globalFilterService.fetchFilterRegion(this.fetchFilterRegionCallback)
    }
  }

  public fetchFilterRegionCallback = (response: any) => {
    this.spinnerService.hide();
    this.isFirstLevelApiCalled = true;
    if (response.status === 200) {
      this.firstLevelDataBackUp = _.cloneDeep(response.data);
      const filterData = response.data?.vendor_list.concat(response.data?.category_list).concat(response.data?.regions_list);
      this.regionGlobalFilterData.set('Region', filterData);
      if (!response.data?.regions_list?.length && !response.data?.category_list?.length && !response.data?.vendor_list?.length) {
        const snakMsg: IToastrOptions = {
          message: CONSTANT.NO_REGION_LIST_MSG
        }
        // this.messageService.showSuccess(snakMsg);
        this.isSecondLevelApiCalled = true;
      }
    }
  }

  public fetchRegions(values: ITreeView[]) {
    this.deviceGlobalFilterData = [];
    this.isSecondLevelApiCalled = false;
    values = _.cloneDeep(values);
    if (this.globalFilterService.deviceSubs) {
      this.globalFilterService.deviceSubs.unsubscribe();
    }
    if (values.length) {
      this.isSecondLevelApiCalled = false;
      // this.spinnerService.show();
    }
    
    const filterRegionData: ITreeView | undefined = values.find((obj: ITreeView) => {
      return obj.type === 'Region';
    })
    const filterVendorData: ITreeView | undefined = values.find((obj: ITreeView) => {
      return obj.type === 'Vendors';
    })
    const filterCatData: ITreeView | undefined = values.find((obj: ITreeView) => {
      return obj.type === 'Asset Type';
    })
    const regionFilter = filterRegionData ? this.createRegionFilterData(filterRegionData.data) : [];
    const req = {
      region: regionFilter,
      vendor: _.map(filterVendorData?.data, 'id'),
      cat: _.map(filterCatData?.data, 'id')
    }
    this.globalFilterService.fetchFilterDevices(req, this.deviceDetailsCallback);

  }

  public deviceDetailsCallback = (response: any) => {
    this.spinnerService.hide();
    this.isSecondLevelApiCalled = true;
    if (response.status === 200 && response.data) {
      if (response.data.devices_list?.length) {
        this.removeIndeterMinateProps(response.data.devices_list);
      }
      this.deviceGlobalFilterData = response.data.devices_list;
      if (!response.data.devices_list.length) {
        const snakMsg: IToastrOptions = {
          message: CONSTANT.NO_DEVICE_LIST_MSG
        }
        // this.messageService.showSuccess(snakMsg);
      }
    }
  }
  public checkUncheckRegion(values: ITreeView[]) {
    this.deviceGlobalFilterData = [];
  }
  public apply($event: IApply) {
    this.spinnerService.show();
    this.sharedService.setAPIEmptyData();
    const request: any = {};

    const selectedRegion = $event?.firstLevel?.find((obj: ITreeView) => {
      return obj.type === "Region";
    });
    const selectedVendors = $event?.firstLevel?.find((obj: ITreeView) => {
      return obj.type === "Vendors";
    });
    const selectedAssets = $event?.firstLevel?.find((obj: ITreeView) => {
      return obj.type === "Asset Type";
    });
    if (!$event.isFirstLevelAllSelected) {
      if (selectedRegion?.selected && selectedRegion?.indeterminate) {
        request.all_regions = true;
        request.regions_list = [];
      } else {
        request.all_regions = false;
        request.regions_list = selectedRegion?.data || [];
      }
      if (selectedVendors?.selected && selectedVendors?.indeterminate) {
        request.all_vendors = true;
        request.vendor_list = [];
      } else {
        request.all_vendors = false;
        request.vendor_list = selectedVendors?.data || [];
      }
      if (selectedAssets?.selected && selectedAssets?.indeterminate) {
        request.all_categories = true;
        request.category_list = [];
      } else {
        request.all_categories = false;
        request.category_list = selectedAssets?.data || [];
      }
    } else {
      request.all_regions = true;
      request.all_vendors = true;
      request.all_categories = true;
      request.regions_list = [];
      request.vendor_list = [];
      request.category_list = [];
    }
    if (!$event.isSecondLevelAllSelected) {
      request.all_devices = false;
      request.devices_list = $event?.secondLevel
    } else {
      request.all_devices = true;
      request.devices_list = [];
    }
    if (!$event.isFirstLevelAllSelected || !$event.isSecondLevelAllSelected) {
      let userDetail = this.profileService.getUserDetails();
      //userDetail.global_filter = true;
      this.profileService.setUserDetails(userDetail);
    }
    this.globalFilterService.saveFilter(request, this.saveFilterCallback);
  }
  public saveFilterCallback = (response: any) => {
    this.spinnerService.hide();
    this.filterService.closeSidenav();
    this.regionGlobalFilterData.clear();
    const snakMsg: IToastrOptions = {
      message: response?.message
    }
    if (response?.message) {
      // this.messageService.showSuccess(snakMsg);
    }
    this.globalFilterService.updateAfterApply.next(true);
  }
  public createRegionFilterData(regionFilter: ITreeView[] | any) {
    if (!Array.isArray(regionFilter)) {
      return regionFilter
    }
    return regionFilter.filter(item => {
      delete item.lavel;
      delete item.parent;
      if ('data' in item) {
        delete item.lavel;
        delete item.parent;
        item.data = this.createRegionFilterData(item.data || []);
      }
      return (item.selected || item.indeterminate)
    })
  }
  public removeIndeterMinateProps(filter: ITreeView[] | any[]) {
    filter.map(item => {
      if (('data' in item)) {
        this.removeIndeterMinateProps(item.data || []);
      } else {
        item.vendorName = this.makeFirstLetterCapital(item.vendorName)
        delete item.indeterminate;
      }
    })
  }
  makeFirstLetterCapital(str: string | undefined) {
    return Utils.makeFirstLetterCapital(str)
  }
}
