<div class="modal-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="56" viewBox="0 0 44 56" fill="none">
        <path d="M11.2101 50.5224H32.7898V42.1994C32.7898 39.12 31.7511 36.474 29.6736 34.2615C27.5962 32.0489 25.0392 30.9426 22.0026 30.9426C18.966 30.9426 16.4081 32.049 14.3289 34.2616C12.2497 36.4743 11.2101 39.1202 11.2101 42.1996V50.5224ZM2.53907 55.0644C1.898 55.0644 1.35922 54.845 0.922733 54.4064C0.486288 53.9676 0.268066 53.4261 0.268066 52.7817C0.268066 52.1373 0.486288 51.5996 0.922733 51.1687C1.35922 50.7378 1.898 50.5224 2.53907 50.5224H6.66807V42.2047C6.66807 39.1218 7.45525 36.283 9.0296 33.6883C10.604 31.0936 12.8038 29.1973 15.6289 27.9992C12.8038 26.7905 10.604 24.8803 9.0296 22.2687C7.45525 19.6571 6.66807 16.8099 6.66807 13.727V5.47597H2.53907C1.898 5.47597 1.35922 5.25661 0.922733 4.8179C0.486288 4.37919 0.268066 3.8381 0.268066 3.19464C0.268066 2.55121 0.486288 2.01093 0.922733 1.57377C1.35922 1.13657 1.898 0.917969 2.53907 0.917969H41.4608C42.1005 0.917969 42.6416 1.14046 43.0841 1.58544C43.5266 2.03041 43.7478 2.57462 43.7478 3.21804C43.7478 3.8615 43.5266 4.3987 43.0841 4.82963C42.6416 5.26052 42.1005 5.47597 41.4608 5.47597H37.3478V13.727C37.3478 16.8099 36.5579 19.6571 34.9782 22.2687C33.3985 24.8803 31.2014 26.7905 28.3869 27.9992C31.2014 29.1973 33.3985 31.0944 34.9782 33.6905C36.5579 36.2866 37.3478 39.1229 37.3478 42.1992V50.5224H41.4608C42.1005 50.5224 42.6416 50.7417 43.0841 51.1804C43.5266 51.6191 43.7478 52.1607 43.7478 52.8051C43.7478 53.4495 43.5266 53.9871 43.0841 54.418C42.6416 54.8489 42.1005 55.0644 41.4608 55.0644H2.53907Z" fill="#583FED"/>
        </svg>
    <h1 class="header">Already have active Session!</h1>
    <p class="content">You already have an active session running in the browser. Would you like to SignIn again or continue with the existing session?</p>
    <div class="button-direction">
        <button mat-raised-button color="primary" class="btn btn-primary" (click)="signInAgain()">Signout, Signin here</button>
        <button mat-raised-button (click)="continueWithExisting()" class="btn btn-white">Continue with Existing</button>
    </div>
</div>
