<!-- <div class="accepted-risk-container">
  <span class="badge">{{ params.value }}</span>
  <span class="device-label">Devices</span>
</div> -->
<div class="ag-center" *ngIf="params.value > 0">
  <p class="whitelisted-label bg-tertiary">
    <span class="whitelisted-value text-tertiary"
    [ngClass]="addBadgeClass('whitelist')">
    {{ params.value }}</span>
  <span class="whitelisted-text">Accepted</span>
  </p>

</div>
