<div class="netsec-modelbox text-center">
    <h2 mat-dialog-title class="title">{{data.title}}</h2>
    <div class="modelbox-body">
        <p>{{data.content}}</p>
        <div *ngIf="data.data && data.data.radio" >
            <div>
                <mat-radio-group  class="radio-btn-group" [(ngModel)]="selectedValue" (change)="onSelectionChange($event)">
                  <mat-radio-button *ngFor="let option of data.data.radio" [value]="option.value">
                    {{ option.label }}
                  </mat-radio-button>
                </mat-radio-group>
                
              </div>
              
        </div>
    </div> 
    <div class="backend-error" *ngIf="data.apiStatus && data.apiStatus!==200">
        <mat-icon>warning</mat-icon> Error message from backend
    </div>
    <div class="modelbox-footer">
        <button mat-button *ngFor="let btn of data.buttons" [ngClass]="btn?.class" class="mat-stroked-button"
            (click)="onClose(btn?.callBack)" mat-dialog-close>{{btn.label}}</button>
    </div>
</div>