import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IToastrOptions } from '../model/IMessage.model';

@Injectable({
  providedIn: 'root'
})
export class MessageServiceService {

  private _showErrorMsg: boolean = true;

  constructor(private toastr: ToastrService) { }

  _getMesgObject = (response: IToastrOptions) => {
    response.timeOut = response.timeOut || 5000;
    response.positionClass = response.positionClass || 'toast-top-right';
    response.preventDuplicates = true;
    response.progressBar = true
    return response;
  }

  showSuccess = (resp: IToastrOptions) => {
    const response = this._getMesgObject(resp);
    this.toastr.success(response.message, response.title,{timeOut: response.timeOut, progressBar: response.progressBar,positionClass: response.positionClass});
  }

  toggleErrorMesg(flag: boolean){
    this._showErrorMsg = flag;
  }

  showError = (resp: IToastrOptions) => {
    if(!this._showErrorMsg){
      return true;
    }
    const response = this._getMesgObject(resp);
    this.toastr.error(response.message, response.title,{timeOut: response.timeOut, progressBar: response.progressBar,positionClass: response.positionClass});
  }

  showWarning = (resp: IToastrOptions) => {
    const response = this._getMesgObject(resp);
    this.toastr.warning(response.message, response.title,{timeOut: response.timeOut, progressBar: response.progressBar,positionClass: response.positionClass});
  }

  showInfo = (resp: IToastrOptions) => {
    const response = this._getMesgObject(resp);
    this.toastr.info(response.message, response?.title,{timeOut: response?.timeOut, progressBar: response?.progressBar,positionClass: response?.positionClass});
  }

  showCustomToast = (resp: IToastrOptions, imageUrl: string) => {
    //this.clearAllToasts()
    setTimeout(() => {
      const response = this._getMesgObject(resp);
      const toastrOptions = {
        enableHtml: true,
        timeOut: 0,
        disableTimeOut : true,
        closeButton:true,
        progressBar: response.progressBar,
        positionClass: response.positionClass,
        toastClass: 'toast-custom'
      };
      const customToastHTML = `
    <div class="custom-toast">

    <img src="${imageUrl}" style="width: 50px; height: 50px; margin-right: 10px;" />
    <p class="notification-msg text_ellipsis">${resp.message}</p>
 
</div>
    `;
      const imageTag = `<img src="${imageUrl}" style="width: 50px; height: 50px; margin-right: 10px;" />`;
      this.toastr.success(customToastHTML, '', toastrOptions);
    }, 5300);
  }
  

  
  

}
