export const environment = {
  production: false,
  offlineCaptcha: false,
  recaptcha: {
    siteKey: '6LeGY3QjAAAAAJgQa18CRytaAv7KatcMwG_kwCI_',
  },
  baseURL: 'https://c7le22zxwj.execute-api.us-east-1.amazonaws.com/api/',
  searchIndexes: {
    'device': 'scandev_table_dev',
    'scan_summary': 'scansum_table_dev',
    'param' : 'param_table_dev',
    'roles' : 'roles_table_dev',
    'benchmark' : 'benchmarks_table_dev',
    'users': 'users_table_dev'
  },
  WebSocket:'https://websocket-dev.veritasweaver.io'
};