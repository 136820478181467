import { Component, Inject } from '@angular/core';
import { CustomDailog } from '../../model/Dialog.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { IUserDetail } from '../../model/profile/profile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  userData: any;
  public profileData: any = {};
  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog,
  private profileService: ProfileService,
  public authService:AuthService,
  public router: Router) {
    this.loadProfile();

  }
  private loadProfile(): void {
    this.profileService.fetchUserDetails(this.userProfileCallback);
  }
  public userProfileCallback = (results: IUserDetail) => {
    this.userData = results;
    this.profileData['firstname'] = this.userData.firstname;
    this.profileData['lastname'] = this.userData.lastname;
    const currentDate = new Date();
    const expiryDate = new Date(this.userData.expiry_date);
    const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
    const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    this.profileData['expiresIn'] = expiresInDays;
  }

  onClose(callBack: Function | undefined,label?: string | undefined) {
    if (callBack) {
        if(label == 'Delete Permanently') {
          this.authService.deleteProgress();
        }
        if(label=='close' || label== 'Cancel') {
           callBack(this.userData)
        }
    }
  }
  logout() {
    this.profileService.reset();
    this.authService.logoutUser().subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.authService.logOut();
          this.authService.userLoggedOutSubject.next();
        }
      },
      error: (error) => {
        console.error('Error during logout:', error);
        this.authService.logOut();
        this.authService.userLoggedOutSubject.next();
        // Handle the error as needed, such as displaying a message to the user
      }
    });
  }
}
