import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent {
  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;
  transform: ImageTransform = {};
  constructor(
    public dialogRef: MatDialogRef<ProfileImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }
  onClose() {
    this.dialogRef.close();
  }

  croppedImage() {
    const event = this.imageCropper.crop('base64');
    this.dialogRef.close(event);
  }
}
