import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { END_POINTS } from '../constants/endpoints';
import { EncryptService } from './encrypt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SSOService {

    encrtptClientId: string;
    encrtptClientSecret: string;

    constructor(
        private restService: RestService,
        private encryptService: EncryptService,
        private http: HttpClient,
        private responseService: ResponseService) {
        this.encrtptClientId = this.encryptService.doEncryptLogin(this.encryptService.CLIENT_ID);
        this.encrtptClientSecret = this.encryptService.doEncryptLogin(this.encryptService.CLIENT_SECRET);
    }

    public ssoLogin(state: string, isNewSession: boolean = false) {
        const req: any = {
            state: state
        }
        if (isNewSession) {
            req.new_session = isNewSession;
        }
        const btoaEncryptIDAndSecret = window.btoa(`${this.encrtptClientId}:${this.encrtptClientSecret}`);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': `Basic ${btoaEncryptIDAndSecret}` })
        }
        return this.http.post(END_POINTS.SSO_LOGIN, req, httpOptions);
    }

    public getListOfSSO(callback: Function) {
        this.restService.getApi(END_POINTS.SSO_MASTER).subscribe({
            next: (response) => {
                callback(this.responseService.successResponse(response));
            },
            error: (err) => {
                callback(this.responseService.errorResponse(err));
            },
        })
    }

    public getSSODetails(callBack: Function): void {
        this.restService.getApi(END_POINTS.SSO_ORG).subscribe({
            next: (response) => {
                callBack(this.responseService.successResponse(response));
            },
            error: (err) => {
                callBack(this.responseService.errorResponse(err));
            },
        });
    }

    public saveSSODetails(payload: any, callBack: Function): void {
        this.restService.postApi(END_POINTS.SSO_ORG, payload).subscribe({
            next: (response) => {
                callBack(this.responseService.successResponse(response));
            },
            error: (err) => {
                callBack(this.responseService.errorResponse(err));
            },
        })
    }
}