<div class="rescan-modalbox">
    <div *ngFor="let btn of data.data.buttons">
        <span class="popup-close-btn" *ngIf="btn.label=='close'">
            <button mat-button (click)="onClose(btn?.callBack,btn.label)" class="icon-btn" [mat-dialog-close]="true">
            <mat-icon>cancel</mat-icon></button>
        </span>
    </div>
    
    <h3>Are you sure you want to delete your data permanently?</h3>
    <p class="sub-text">You cannot undo this action. We suggest you to contact us before deleting it.</p>
    <div class="rescan-btn">
        <div  *ngFor="let btn of data.data.buttons">
            <div *ngIf="btn.label!='close'">
                <button   [ngClass]="btn?.class" 
                (click)="onClose(btn?.callBack,btn.label)" mat-dialog-close>{{btn.label}}</button>  
            </div>
        </div>
    </div>
</div>