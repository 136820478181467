<div class="custom-snackbar-container">
  <span>
    <em class="material-icons">{{icons}}</em>
  </span>
  <span class="snack-bar-data">
    {{data.message}}
  </span>

  <span class="closebutton" *ngIf="data.type=='failure'">
    <button mat-button class="button" (click)="closeSnackBar()">close</button>
  </span>
</div>
