<div *ngIf="data?.resp" class="toast-container">
    <div class="toast-header">
      <img [src]="data?.imageUrl" alt="Icon" class="toast-icon">
      <span class="toast-title">{{ data?.resp.title }}</span>
      <button class="custom-close-button" (click)="closeToast()">x</button>
    </div>
    <div class="toast-body">
      <p class="toast-message" *ngIf="isHTML(data?.resp.message); else plainText" [innerHTML]="sanitizeHTML(data?.resp.message)"></p>
      <ng-template #plainText>
        <p class="toast-message">{{ data?.resp.message }}</p>
      </ng-template>
    </div>
  </div>
    