import { Component } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { CveAssetsService } from '../../services/cve-assets.service';

@Component({
  selector: 'app-cve-assets-details',
  templateUrl: './cve-assets-details.component.html',
  styleUrls: ['./cve-assets-details.component.scss']
})
export class CveAssetsDetailsComponent {
  public dataForCveDetails: any;
  public isCVEdetails: boolean = false;
  constructor(public filterService: FilterService,
    private cveAssetsService: CveAssetsService
  ) {
    this.cveAssetsService.dataForCveDetails.subscribe((data)=>{
      this.dataForCveDetails = data;
      this.isCVEdetails = data.isCveDetails;
    })
  }
  
  public closeSidenav() {
    this.filterService.closeSidenav();
    this.cveAssetsService.closeSideNav.emit(true);
  }
}
