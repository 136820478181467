import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDailog } from '../../model/Dialog.model';

@Component({
  selector: 'app-unsaved-dialog',
  templateUrl: './unsaved-dialog.component.html',
  styleUrls: ['./unsaved-dialog.component.scss']
})
export class UnsavedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog) {
  }

  onClose(callBack: Function | undefined) {
      if (callBack) {
          callBack();
      }
  }
}
