import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { ResetPasswordComponent } from 'src/app/modules/login/components/reset-password/reset-password.component';
import { ProfileImageComponent } from 'src/app/modules/shared/components/profile-image/profile-image.component';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { OrganisationSetupService } from 'src/app/modules/shared/services/organisation-setup.service';
import { ProfileService } from 'src/app/modules/shared/services/profile.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { Utils } from 'src/app/modules/shared/utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userForm: FormGroup
  userDetails: IUserDetail = {};
  hideUserdetail: boolean = true;
  imageSrc: string | undefined | any; 
  intialImage: string | undefined;
  initialUserDetails: IUserDetail = {};
  userProfile: IUserDetail = {};
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  isSSOLogin:boolean = false;
  formData:any;
  sanitizeImagePreview:any;
  validation_messages = {
    first_name: [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_MSG},
      { type: 'minlength', min: 1, message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_MIN_LENGTH },
      { type: 'maxlength', max: 32, message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_MAX_LENGTH },
      { type: 'pattern', pattern: /^[a-zA-Z]+(\s[a-zA-Z]+)*$/, message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_PATTERN }
    ],
    last_name: [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_MSG },
      { type: 'minlength', min: 1, message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_MIN_LENGTH },
      { type: 'maxlength', max: 32, message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_MAX_LENGTH },
      { type: 'pattern', pattern: /^[a-zA-Z]+(\s[a-zA-Z]+)*$/, message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_PATTERN}
    ],
    country_code: [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_COUNTRY_CODE_MSG },
      { type: 'maxlength', max: 4, message: CONSTANT.AUTHENTICATION.AUTH_COUNTRY_CODE_MAX_LENGTH },
      { type: 'pattern', pattern: /^\+[0-9]{0,3}$/, message: CONSTANT.AUTHENTICATION.AUTH_COUNTRY_CODE_PATTERN }
    ],
    phone_number: [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_PHONE_NUM_MSG},
      { type: 'pattern', pattern: /^\d{1,15}$/, message: CONSTANT.AUTHENTICATION.AUTH_PHONE_NUM_INVALID },
      { type: 'minlength', message: CONSTANT.AUTHENTICATION.AUTH_PHONE_NUM_MIN_LENGTH },
      { type: 'maxlength', message: CONSTANT.AUTHENTICATION.AUTH_PHONE_NUM_MAX_LENGTH }
    ]
  };

  constructor(private profileService: ProfileService,
    private spinnerService: SpinnerService,
    private messageService: MessageServiceService,
    private fb: FormBuilder,
    private headerService: HeaderService,
    public dialogService:DialogService,
    public authService:AuthService,
    private filterService: FilterService,
    private dialog: MatDialog) {

    this.userForm = this.fb.group({
      "first_name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32),
        Validators.pattern(/^[a-zA-Z]+(\s[a-zA-Z]+)*$/)
      ])),
      "last_name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32),
        Validators.pattern(/^[a-zA-Z]+(\s[a-zA-Z]+)*$/)
      ])),
      "country_code": new FormControl("", Validators.compose([
        Validators.maxLength(4),
        Validators.pattern(/^\+[0-9]{0,3}$/)
      ])),
      "phone_number": new FormControl("", Validators.compose([
        Validators.pattern(/^\d{1,15}$/),
        Validators.minLength(7),
        Validators.maxLength(15),
      ])),
    })
  }

  ngOnInit(): void {
    // this.headerService.setHeading('My Profile');
    this.userProfile = this.profileService.getUserDetails();
    this.loadProfile();
    this.loadUserImage();
  }

  private loadProfile(isApicall?:boolean): void {
    this.profileService.setUserDetails({});
    this.profileService.fetchUserDetails(this.userProfileCallback,isApicall);
  }
  isSsouser:boolean=false
  public userProfileCallback = (results: IUserDetail) => {
    results.firstname = this.makeFirstLetterCapital(results.firstname);
    results.lastname = this.makeFirstLetterCapital(results.lastname);
    this.userDetails = results;
      this.initialUserDetails = _.cloneDeep(results);
    if(this.userDetails.sso_enabled) {
      this.isSsouser=true
      this.userForm.controls['first_name'].disable();
      this.userForm.controls['last_name'].disable();
    } 
  }

  openFilePicker() {
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.value = '';
  }
  handleImageInput(files: FileList | null | undefined) {
    if (files && files.length > 0) {
      const selectedFile: File = files[0];
      const maxSize = 5 * 1024 * 1024;
      if(selectedFile.size <= maxSize){
      this.formData = new FormData();
      const reader = new FileReader();
      reader.onload = (event: any) => {
        // this.imageSrc = event.target.result;
          this.dialog
          .open(ProfileImageComponent, { data: { selectedFile }, panelClass: 'hide-overflow', width: '600px' })
          .afterClosed().toPromise().then((event)=>{
            if(event) {
              if(event?.base64) {
                this.resizeImage(event?.base64, (resizedBase64) => {
                  if (resizedBase64) {
                    try {
                      const file = resizedBase64
                        this.formData = new FormData();
                        this.formData.append('picture', file, selectedFile.name);
                         if (this.formData) {
                          this.profileService.saveUserImage(this.formData, this.saveImageCallback);
                        } 
                    } catch (error) {
                      console.error('Error converting base64 to file:', error);
                    }
                  }
                });
              }
            }
          })
          };
      reader.readAsDataURL(selectedFile);
    } else {
      const snakMsg: IToastrOptions = {
        message: 'Image file size cannot exceed 5MB.'
      }
      this.messageService.showError(snakMsg);
    }
  }
  }

  resizeImage(base64: string, callback: (resizedBase64: Blob) => void) {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const maxWidth = 1024; 
      const maxHeight = 1024; 
  
      let width = img.width;
      let height = img.height;
  
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
  
      canvas.width = width;
      canvas.height = height;
      ctx?.drawImage(img, 0, 0, width, height);
  
      canvas.toBlob((blob) => {
        if (blob) {
          callback(blob);
        } else {
          console.error('Error converting canvas to blob.');
        }
      }, 'image/jpeg', 0.7);
    };
    img.src = base64;
  }

  // public profileSave(){
  //   this.hideUserdetail = true;
  //   if (this.formData) {
  //   this.profileService.saveUserImage(this.formData, this.saveImageCallback);
  //   } 
  // }

  public saveImageCallback = (response: any) => {
    this.spinnerService.hide();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.profileService.setUserImage('', '');
      this.profileService.userImageSubject.next();
      this.loadUserImage();
    }
  }
 
  public uploadProfileResponse = (response: any) => {
    this.spinnerService.hide();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.hideUserdetail = true;
      this.filterService.hideDetailsSubject.next(false);
      this.profileService.setUserDetails({});
      this.loadProfile(true);
    }
  }

  public showHideDetails() {
    this.hideUserdetail = false;
    this.filterService.hideDetailsSubject.next(true);
    this.userForm.valueChanges.subscribe(() => {
      if (this.userForm.dirty) {
        this.filterService.profileFormSubject.next(true);
      }
    });
    this.userForm.controls['first_name'].setValue(this.userDetails.firstname);
    this.userForm.controls['last_name'].setValue(this.userDetails.lastname);
  }

  setHideUserDetailTrue() {
    this.hideUserdetail = true;
    this.filterService.hideDetailsSubject.next(false);
  }

  public uploadProfileConnect(userForm: FormGroup) {
    this.hideUserdetail = true;
    this.filterService.hideDetailsSubject.next(false);
    if (this.userForm.valid) {
      const { first_name, last_name } = userForm.value
      const data = { first_name, last_name }
      this.profileService.postUserDetails(data, this.uploadProfileResponse);
    }
  }

  public loadUserImage() {
    this.profileService.fetchUserImage(this.fetchImageCallback,100,100, 'profile');
  }

  public fetchImageCallback = (response: any) => {
    this.imageSrc = response;
    this.intialImage = response
    if(!this.profileService.getUserImage()) {
      this.profileService.fetchUserImage((response: any)=>{
        this.profileService.updateImage.next(response);
      },40,40);
    }
  }

  public hideDetails(): void {
    this.hideUserdetail = true;
    this.filterService.hideDetailsSubject.next(false);
    this.userDetails = _.cloneDeep(this.initialUserDetails);
    this.imageSrc = this.intialImage;
  }

  public makeFirstLetterCapital(str: string | undefined): string {
    return Utils.makeFirstLetterCapital(str)
  }

  onChangePassWord(): void{
    this.filterService.closeSidenav();
    const payload: IDialog = {
      component: ResetPasswordComponent,
      width: '350px',
      data: {'isChangePassword': true}
    };
    this.dialogService.openDialog(payload);
  }

  logout() {
    this.profileService.reset();
    this.authService.logoutUser().subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.authService.logOut();
          this.authService.userLoggedOutSubject.next();
        }
      },
      error: (error) => {
        console.error('Error during logout:', error);
        this.authService.logOut();
        this.authService.userLoggedOutSubject.next();
        // Handle the error as needed, such as displaying a message to the user
      }
    });
  }

}
