import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ResponseService } from '../../services/response.service';
import { RestService } from '../../services/rest.service';
import { ActiveElement, Chart, ChartEvent, ChartType, registerables } from 'chart.js';
import { ICyberDrop, ICyberResilience, ICyberResilienceResponse, IEventData, cyberConstant } from './cyber-reilience.model';
import { END_POINTS } from '../../constants/endpoints';
import { IDurationInfo, IDurationResponse } from 'src/app/modules/dashboard/components/controlpanel/controlPanelModel';
import { CONSTANT } from '../../constants/constant-data';
import { AuthService } from '../../services/auth.service';
import * as _ from 'lodash';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Utils } from '../../utils';
import { DateAdapter } from '@angular/material/core';
import * as echarts from 'echarts/core';
import {DatePipe, formatDate} from '@angular/common';
import {
  DatasetComponent,
  DatasetComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  TransformComponent
} from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { Router } from '@angular/router';
import { Ihistorydata } from 'src/app/modules/whitelist/components/whitelist/whitelist.model';
import { WhitelistService } from '../../services/whitelist.service';
import { IDialog } from '../../model/Dialog.model';
import { WhitelistHistoryDetailsComponent } from 'src/app/modules/whitelist/components/whitelist-history-details/whitelist-history-details.component';
import { DialogService } from '../../services/dialog.service';
import { SpinnerService } from '../../services/spinner.service';
import { MatDatepickerInput } from '@angular/material/datepicker';
echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
type EChartsOption = echarts.ComposeOption<
  | DatasetComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LineSeriesOption
>;

Chart.register(...registerables);
@Component({
  selector: 'app-cyber-reilience',
  templateUrl: './cyber-reilience.component.html',
  styleUrls: ['./cyber-reilience.component.scss'],
  providers: [DatePipe]
})
export class CyberReilienceComponent {
  @Input() title: string = '';
  @Input() showFilter: boolean = false;
  @Input() apiName: string = '';
  @Input() filterPage: string = '';
  @Input() deviceId: string = '';
  @Input() payLoad: any = {};
  @Input() isCompareAvailable: boolean = false;
  @ViewChild('cyberResilience') cyberResilience!: ElementRef;
  public cyberResilienceData: number[] = [];
  public cyberResilienceLabels: string[] = [];
  public cyberResilienceLineChart!: echarts.ECharts;
  public chartResponse : ICyberResilience[] | any = [];
  public eventData: IEventData[] = [];
  public currentSelectedDateEvent : ICyberResilience | any= {};
  public isSpinner: boolean = false;
  public selectedMonth: string | undefined = "";
  public selectedMonthLabel: string | undefined = "";
  public monthFilter: ICyberDrop[] = [];
  isClicked: boolean = false;
  whitelistHistoryData:any;
  public loadEvents: boolean = false;
  public loaderLength:number = 0;
  loaderHeights = ['110px', '80px', '110px', '90px', '110px', '80px', '110px', '80px'];
  public constant = CONSTANT;
  public minimumDate = Utils.getMonthDate(new Date(), -6);
  public maximumDate = new Date();
  public tablesObjects: any = {
    'device_decom': { title: 'Assets' , url: '/admin/asset-management' },
    'new_scan': { title: 'Assets' ,  url: 'scan-details' },
    'rescan': { title: 'Assets' , url: 'scan-details' },
    'accepted_risk': {title:'Accepted risk'},
    'device_recom':{ title: 'Assets' , url: '/admin/asset-management' }
  }
  datePickerRange: FormGroup | any;
isLiLoading: any;
  get custom() {
    return cyberConstant.custom_range;
  }
  public position: any = {
    left:0,
    top:0
  };
  showPopup: boolean = false;
  popupOptions = {
    showDelay: 0,
    hideDelay: 0,
    placement: 'right',
    offset: 0,
    trigger: 'click',
    contentType:'template'
  }
  public dateRange: any = {
    startDate: {
      value: null,
      index: ''
    },
    endDate: {
      value: null,
      index: ''
    }
  };
  @ViewChild('dateInput', { read: MatDatepickerInput }) dateInput!: MatDatepickerInput<any>;
  constructor(private responseService: ResponseService,
    private datePipe: DatePipe,
    private restService: RestService,
    private authService: AuthService,
    private _adapter: DateAdapter<any>,
    private router: Router,
    private whitelistService: WhitelistService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,) {
      this._adapter?.setLocale('en-US');
      const yesterday = new Date();
    //yesterday.setDate(yesterday.getDate() - 7);
    // Set maxDate to yesterday
    this.maxDate = yesterday;
     this.datePickerRange = new FormGroup(
  {
    startDate: new FormControl(null, Validators.required),
    endDate: new FormControl(null, Validators.required)
  },
  { validators: this.dateRangeValidator }
);

    
  }
  ngOnInit(): void {
    this.cyberResilienceData = [];
    this.cyberResilienceLabels = [];
    this.chartResponse = [];
    this.isClicked = false;
    if (this.cyberResilienceLineChart) {
      this.cyberResilienceLineChart.dispose();
    }
    if (this.showFilter && this.apiName) {
      this.loadFilters(this.fetchDurationFilterCallback);
    }
  }
  ngAfterViewInit(): void {
    
  }

  addDisableCursorClass(index: number) {
    return this.isClicked && this.loaderLength === index;
}


  navigateToUrl(event:any,index:number) {
    this.isClicked = true;
    //this.loadEvents = true;
    this.loaderLength = index;
    this.isLiLoading[index] = true;
    if(event.event_code=='new_scan' || event.event_code=='rescan') {
     const tempUrl = 'dashboard/'+this.tablesObjects[event.event_code]?.url + '/'+event.reference_id+'/status/' +2;
     this.router.navigateByUrl(tempUrl)
    } else if(event.event_code=='accepted_risk' && this.isClicked) {
       this.fetchWhitelistHistory(event.reference_id)
    }else {
      this.router.navigateByUrl(this.tablesObjects[event.event_code]?.url)
    }
  }

  fetchWhitelistHistory(item: any){
    //this.whitelistHistoryData = {};
    const id = Number(item);
   // this.spinnerService.show();
    this.whitelistService.getWhitleListHistoryDetails(id,this.WhitelistHistoryDataCallback)
  }
  public WhitelistHistoryDataCallback = (results: any) => {
    if (results && results.status === 200 && results.data) {
      this.whitelistHistoryData = results.data;
    }
   // this.spinnerService.hide();
    const payload: IDialog = {
      component:WhitelistHistoryDetailsComponent,
      width: '636px',
      class:'history-popup',
      data:{whitelistHistoryData: this.whitelistHistoryData},
    }
    this.isClicked= false;
    this.isLiLoading[this.loaderLength] = false;
    //this.loadEvents = false;
   // this.isLiLoading[this.loaderLength] = false;
    this.dialogService.openDialog(payload);
  }
  
  public loadCyberResilienceChart(callback: Function, value: string | undefined): void {
    this.isSpinner = true;
    let req: any = {
      duration: value
    };
    if (this.payLoad) {
      req = { ...req, ...this.payLoad };
    }
    if(this.deviceId) {
      req.device_uuid = this.deviceId;
    }
    if(value === this.custom.id) {
      const savedSelectedCookies = JSON.parse(this.authService.getCookie(this.filterPage) || '{}');
      if(savedSelectedCookies.startDate) {
        this.datePickerRange.controls['startDate'].setValue(new Date(savedSelectedCookies?.startDate || ''));
      }
      if(savedSelectedCookies.endDate) {
        this.datePickerRange.controls['endDate'].setValue(new Date(savedSelectedCookies?.endDate || ''));
      }
    }
    const dateRange = this.datePickerRange.value;
    if(value === this.custom.id && dateRange?.startDate && dateRange?.endDate) {
      req.start_date = formatDate(new Date(dateRange?.startDate), 'yyyy-MM-dd', 'en');
      req.end_date = formatDate(new Date(dateRange?.endDate), 'yyyy-MM-dd', 'en');
    }
    if(Intl.DateTimeFormat().resolvedOptions().timeZone){
      req.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.restService.postApi(this.apiName, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public loadCyberResilienceChartCallback = (response: ICyberResilienceResponse | any) => {
    this.isSpinner = false;
    this.cyberResilienceData = [];
    this.cyberResilienceLabels = [];
    this.chartResponse = [];
    if (this.cyberResilienceLineChart) {
      this.cyberResilienceLineChart.dispose();
    }
    if (response.status === 200 && response.data && response.data.graph_data?.length) {
      this.chartResponse = response.data.graph_data;
      //this.chartResponse = response.data.graph_data.filter((item: ICyberResilience)=> item.new_event === true);
      response.data.graph_data.forEach((obj: ICyberResilience) => {
        if(!_.isNull(obj.security_rating)) {
          this.cyberResilienceLabels.push(this.getDateAndMonth(new Date(obj.date_time)));
          this.cyberResilienceData.push(obj.security_rating.toFixed(2));
        }
      });
      
      this.cyberResilienceChart();
      
    }
  }
  public loadFilters(callback: Function): void {
      this.isSpinner = true;
      const filterDetails = JSON.parse(this.authService.getCookie('duration') || '[]');
      this.monthFilter = filterDetails;
      if (this.monthFilter && this.monthFilter.length) {
        callback(this.monthFilter);
      } else {
        this.restService.getApi(END_POINTS.DASHBOARD_DURATION_FILTER).subscribe({
          next: (response) => {
            const data = this.responseService.successResponse(response);
            if(data.status === 200 && data?.data?.duration) {
              this.monthFilter = Utils.sortByDesiredOrder(data?.data?.duration)
              this.setFilter('duration', this.monthFilter);
              const lastSixMonths = this.monthFilter.find(duration => duration.id === 'last_6_months');
              if (lastSixMonths) {
                this.selectedMonthLabel = lastSixMonths.name;
                this.selectedMonth = lastSixMonths.id;
            } else {
              this.selectedMonthLabel =  this.monthFilter[0].name;
              this.selectedMonth = this.monthFilter[0].id;
            }
            const duration = Utils.sortByDesiredOrder(data?.data?.duration)

              callback(duration);

            }
          },
          error: (err) => {
            this.setFilter('duration', []);
            callback([]);
            this.responseService.errorResponse(err);
          },
        })
      }
  }
  public setFilter(type: string, data: any) {
    this.monthFilter = data;
    this.authService.setCookie(type, JSON.stringify(data));
  }
  public fetchDurationFilterCallback = (response: ICyberDrop[]) => {
    this.isSpinner = false;
    if (response.length) {
      

      this.setSelectedCookies();
      this.loadCyberResilienceChart(this.loadCyberResilienceChartCallback, this.selectedMonth);
    }
  }
  setSelectedCookies(){
    if(this.filterPage) {
      const savedSelectedCookies = JSON.parse(this.authService.getCookie(this.filterPage) || '{}');
      if(savedSelectedCookies && Object.keys(savedSelectedCookies).length) {
        this.selectedMonthLabel = savedSelectedCookies.label;
        this.selectedMonth = savedSelectedCookies.id;

      } else {
       // this.monthFilter = response;
       const lastSixMonths = this.monthFilter.find(duration => duration.id === 'last_6_months');
       if (lastSixMonths) {
         this.selectedMonthLabel = lastSixMonths.name;
         this.selectedMonth = lastSixMonths.id;
     } else {
       this.selectedMonthLabel =  this.monthFilter[0].name;
       this.selectedMonth = this.monthFilter[0].id;
     }
      }
      this.authService.setCookie(this.filterPage, JSON.stringify(
        {
          label:this.selectedMonthLabel,
          id: this.selectedMonth,
          startDate: savedSelectedCookies?.startDate || null,
          endDate: savedSelectedCookies?.endDate || null
        }));
    }
  }

  fromDate!: Date;
  toDate!: Date;
  maxDate!: Date;
 
  applyDateRange() {
    if (this.datePickerRange.get("startDate").value && this.datePickerRange.get("endDate").value) {
      
      let fromDate = this.datePickerRange.get("startDate").value;
      let toDate = this.datePickerRange.get("endDate").value;
      // let from = new Intl.DateTimeFormat('en-US').format(fromDate);
      // let to = new Intl.DateTimeFormat('en-US').format(toDate);
      // Format dates with leading zeros
      const padWithZero = (number: number) => (number < 10 ? '0' : '') + number;

      let from = `${padWithZero(fromDate.getMonth() + 1)}/${padWithZero(fromDate.getDate())}/${fromDate.getFullYear()}`;
      let to = `${padWithZero(toDate.getMonth() + 1)}/${padWithZero(toDate.getDate())}/${toDate.getFullYear()}`;


      this.selectedMonthLabel = `${from} - ${to}`;
      this.selectedMonth=this.custom.id;
      
      this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedMonthLabel, id: this.selectedMonth,
        startDate: this.datePickerRange.get("startDate").value, endDate: this.datePickerRange.get("endDate").value}));
        if (this.cyberResilienceLineChart) {
          this.cyberResilienceLineChart.dispose();
        }
  
     this.loadCyberResilienceChart(this.loadCyberResilienceChartCallback, this.custom.id);
    } else {
      
    }
  }
  
  public filterMonth(selectedFilter: ICyberDrop) {
      if(selectedFilter &&selectedFilter.id &&   selectedFilter.id!== this.custom.id) {
        this.selectedMonth = selectedFilter.id;
        this.selectedMonthLabel = selectedFilter.name;
        this.datePickerRange.reset();
        
      }

      
      if (this.cyberResilienceLineChart) {
        this.cyberResilienceLineChart.dispose();
      }

      if(this.filterPage && this.selectedMonth!== this.custom.id) {
        this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedMonthLabel, id: this.selectedMonth,
          startDate: '', endDate: ''}));
      } 
      
      this.datePickerRange?.reset();

      if(this.selectedMonth!== this.custom.id) {
        
        this.loadCyberResilienceChart(this.loadCyberResilienceChartCallback, this.selectedMonth);
      }
  }

  public cyberResilienceChart() {
    const chartDom = this.cyberResilience.nativeElement;
    this.cyberResilienceLineChart = echarts.init(chartDom);
    let option: EChartsOption;
    option = {
      grid:{
        top: '10px',
        bottom: '20px',
        right: '25px',
        left: '30px'
      },
      dataset: [
        {
          id: 'dataset_raw',
          source: this.cyberResilienceData
        }
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const dataFromCurrentIndex = this.chartResponse[params[0].dataIndex];
          return dataFromCurrentIndex && dataFromCurrentIndex.new_event ? `<div>
          <div ><div class='tooltip-color'>${params[0].name}</div>
          <div class='tooltip-bottom'>
          <div>
          <div>
          <span class='badge'></span>
          <span class='badge-value'>${params[0].value}</span>
          <div style="clear:both"></div>
          </div>
          <div style="clear:both">
          </div></div>
          <div style="clear:both"></div>
          </div><div style="clear:both"></div></div>
          <div style="clear:both"></div>
          </div>` : '';
        }
      },
      xAxis: {
        type: 'category',
        nameLocation: 'start',
        boundaryGap: false,
        data: this.cyberResilienceLabels
      },
      yAxis: {
        // min: 0,
        // max: 100,
        // offset: 0
      },
      triggerLineEvent: true,
      series: [
        {
          type: 'line',
          datasetId: 'dataset_raw',
          showSymbol: false,
          data: this.cyberResilienceData
        }
      ]
    };
    this.cyberResilienceLineChart.setOption<echarts.EChartsCoreOption>(option);
    this.cyberResilienceLineChart.on('click', (params)=> {
      const dataFromCurrentIndex = this.chartResponse[params.dataIndex];
      if(dataFromCurrentIndex && dataFromCurrentIndex.new_event) {
        this.eventData = [];
        this.currentSelectedDateEvent = {};
        this.loadEvents = true;
        this.position.left = params && params.event?.offsetX;
        this.position.top = params && params.event?.offsetY;
        this.showPopup = !this.showPopup;
        this.currentSelectedDateEvent = dataFromCurrentIndex;
        this.selectDateEvent(dataFromCurrentIndex);
      }
    });
    this.cyberResilienceLineChart.on('mouseover', (params: any)=> {
      const dataFromCurrentIndex = this.chartResponse[params.dataIndex];
      if(params && params.event && params.event.target) {
        if(dataFromCurrentIndex && dataFromCurrentIndex.new_event) {
          params.event.target.cursor = 'pointer';
        } else {
          params.event.target.cursor = 'none';
        }
      }
    });
    window.addEventListener('resize', ()=>{
      this.cyberResilienceLineChart.resize();
    })
  }
  
  public selectDateEvent(data: ICyberResilience) {
    if(data.new_event ) {
      
      let req: any = {
        date: data.date_time,
      };
      if(Intl.DateTimeFormat().resolvedOptions().timeZone){
        req.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      if (this.payLoad) {
        
        req = { ...req, ...this.payLoad };
      }
      this.restService.postApi(END_POINTS.DAILY_EVENT, req).subscribe({
        next: (response) => {
          this.selectDateEventCallback(this.responseService.successResponse(response));
        },
        error: (err) => {
          this.selectDateEventCallback(this.responseService.errorResponse(err));
        },
      })

    }
    
  }
  public selectDateEventCallback(response: any) {
    this.loadEvents = false;
    if (response.status === 200 && response.data) {
      this.eventData = response.data.event_data
      this.isLiLoading=Array.from({ length: this.eventData.length }, () => false);
    }
  }
  public getDateAndMonth(date: Date): string {
    const dt = new Date(date);
    const month = dt.toLocaleString('default', { month: 'short' });
    return dt.getDate() + ' ' + month;
  }
  public hasDate() {
    let isDateAvailable = false;
    if(this.dateRange.startDate.value !== null && this.dateRange.endDate.value!==null) {
      isDateAvailable = true;
    }
    return isDateAvailable;
  }
  public validateDate() {
    if ((!this.dateRange.endDate.value || !this.dateRange.startDate.value) || 
      (new Date(this.dateRange.endDate.value) < new Date(this.dateRange.startDate.value))) {
      return true
    }
    return false;
  }
  public overlayClick() {
    this.showPopup = false;
  }
  public compareData() {
    // redirection code and set value
    // this.dateRange variable has start and end date value.
  }
  public getCustomRangeData() {
    if(this.validateDatePicker()) {
      const startDate = this.datePickerRange.get("startDate").value;
      const endDate = this.datePickerRange.get("endDate").value;
      // this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedMonthLabel, id: this.selectedMonth,
      //    startDate: startDate, endDate: endDate}));
      //this.loadCyberResilienceChart(this.loadCyberResilienceChartCallback, this.selectedMonth);
    }
  }
  public validateDatePicker = ()=> {
    let validDate = false;
    const startDate = this.datePickerRange.get("startDate").value;
    const endDate = this.datePickerRange.get("endDate").value;
    if ((startDate && endDate) && (startDate <= endDate)) {
      validDate = true
    }
    return validDate;
  }
  public dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup;
    const from = group.get("startDate")?.value;
    const to = group.get("endDate")?.value;
  
    if (!from || !to) {
      return { invalidRange: true };  // Invalid if either date is missing
    }
  
    return new Date(from).valueOf() <= new Date(to).valueOf() ? null : { invalidRange: true };
  };

  onDateInput(event: any, controlName: string): void {
    const inputDate = event.target.value;
    const parsedDate = this.parseDate(inputDate);
  
    if (parsedDate) {
      this.datePickerRange.get(controlName)?.setValue(parsedDate);
      this.datePickerRange.get(controlName)?.setErrors(null); // Clear previous errors
    } else {
      this.datePickerRange.get(controlName)?.setErrors({ invalidDate: true });
    }
  }
  
  

  parseDate(dateString: string): Date | null {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const month = parseInt(parts[0], 10) - 1; // Months are 0-based in Date object
      const day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);
  
      // Simple validation for date parts
      if (month >= 0 && month <= 11 && day > 0 && day <= 31 && year >= 1900) {
        const date = new Date(year, month, day);
        // Check if the created date matches the input
        if (date.getDate() === day && date.getMonth() === month && date.getFullYear() === year) {
          return date;
        }
      }
    }
    return null; // Return null if parsing fails
  }

  onDateChange(event: any,controlName: string) {
    const formattedDate = this.datePipe.transform(event.value, 'MM/dd/yyyy');
   // this.datePickerRange.get(controlName)?.setValue(formattedDate);
  }
  
  
  


  
  // public dateRangeValidator = () => {
  //   let invalid = false;
  //   const from = this.datePickerRange && this.datePickerRange.get("startDate").value;
  //   const to = this.datePickerRange && this.datePickerRange.get("endDate").value;
  //   if (from && to) {
  //     invalid = new Date(from).valueOf() > new Date(to).valueOf();
  //   }
  //   return invalid ? { invalidRange: { from, to } } : null;
  // };
  public getPositiveValue(value: number | null){
    let val = value ?? 0;
    if (val !== 0) {
      val = Math.abs(val);
    } 
    return val?val:null;
  }


  setDates() {
    const savedSelectedCookies = JSON.parse(this.authService.getCookie(this.filterPage) || '{}');
      if(savedSelectedCookies.startDate) {
        this.datePickerRange.controls['startDate'].setValue(new Date(savedSelectedCookies?.startDate || ''));
      } else {
        this.datePickerRange.reset();
      }
      if(savedSelectedCookies.endDate) {
        this.datePickerRange.controls['endDate'].setValue(new Date(savedSelectedCookies?.endDate || ''));
      } else {
        this.datePickerRange.reset();
      }
   
  }
}

