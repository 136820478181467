import { Injectable } from '@angular/core';
import { ResponseService } from './response.service';
import { Observable, of } from 'rxjs';
import { isArray } from 'lodash';
import { RestService } from './rest.service';
import { CONFIG } from '../constants/config';
import * as _ from 'lodash';
export interface IAPIDetails {
  url: string[] | string | any;
  method: string;
  req: any;
  responseType: string;
  callBack: Function | null | any;
  page_no: number | any;
  rows_count: number | any;
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public defaultCofig: IAPIDetails | any = {
    url: '',
    method: CONFIG.METHOD.GET,
    req: '',
    responseType: CONFIG.RESPONSE_TYPE.NONE,
    callBack: null,
    page: 1,
    rowCount: null
  }
  constructor(private responseService: ResponseService,
     private restService: RestService) {

   }
  callAPI(apiDetails = this.defaultCofig) : Observable<any> {
    if(apiDetails.method === CONFIG.METHOD.GET) {
     if(apiDetails.responseType === CONFIG.RESPONSE_TYPE.NONE) {
       if(!isArray(apiDetails.url)) {
          this.restService.getApi(apiDetails.url).subscribe({
            next: (response) => {
              apiDetails?.callBack(this.responseService.successResponse(response));
            },
            error: (err) => {
              apiDetails?.callBack(this.responseService.errorResponse(err));
            },
          });
       }
     } else if(apiDetails.responseType === CONFIG.RESPONSE_TYPE.ASYNC) {
      if(isArray(apiDetails.url)) {
       apiDetails.url.forEach((url: string)=>{
         this.restService.getApi(url).subscribe({
           next: (response) => {
             apiDetails?.callBack(this.responseService.successResponse(response));
           },
           error: (err) => {
             apiDetails?.callBack(this.responseService.errorResponse(err));
           },
         });
       })
      }
    } else if(apiDetails.responseType === CONFIG.RESPONSE_TYPE.SINGLE) {
       if(_.isArray(apiDetails.url)) {
         return this.restService.multipleGetApiCall(apiDetails.url);
       }
     }
    } else if(apiDetails.method === CONFIG.METHOD.POST) {
     if(apiDetails.responseType === CONFIG.RESPONSE_TYPE.NONE) { // single normal call
       if(!_.isArray(apiDetails.url)) {
          this.restService.postApi(apiDetails.url, apiDetails.req).subscribe({
            next: (response) => {
              apiDetails?.callBack(this.responseService.successResponse(response));
            },
            error: (err) => {
              apiDetails?.callBack(this.responseService.errorResponse(err));
            },
          });
       }
     } else if(apiDetails.responseType === CONFIG.RESPONSE_TYPE.ASYNC) {
       if(_.isArray(apiDetails.url)) { // call multiple api with async...even for same api async should have correct api request.
        apiDetails.url.forEach((url: string, index: number)=>{
          let req: any = apiDetails.req;
          if(_.isArray(apiDetails.req)) {
            req = apiDetails.req[index];
          } 
          this.restService.postApi(url, req).subscribe({
            next: (response) => {
              apiDetails?.callBack(this.responseService.successResponse(response));
            },
            error: (err) => {
              apiDetails?.callBack(this.responseService.errorResponse(err));
            },
          });
        })
     }
    } else if(apiDetails.responseType === CONFIG.RESPONSE_TYPE.SINGLE) {
      if(_.isArray(apiDetails.url)) {
        return this.restService.multiplePostApiCall(apiDetails.url, apiDetails.req);
      }
    }
  }
  return of('Wrong configuration');
 }
}
