import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { END_POINTS } from '../constants/endpoints';
import { ResponseService } from './response.service';
import { IApply, ITreeView } from '../components/filter/filter.model';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GlobalFilterService {
  public toggleFilter = new Subject<boolean>();
  public toggleFilterState = this.toggleFilter.asObservable();

  public gobalSearch = new Subject<boolean>();
  public gobalSearchState = this.gobalSearch.asObservable();
  
  public updateAfterApply = new Subject<boolean>();
  public updateAfterApplyState = this.updateAfterApply.asObservable();

  public sideNavFilter = new Subject<boolean>();
  public sideNavFilterState = this.sideNavFilter.asObservable();

  public accountFilter = new Subject<boolean>();
  public accountNavFilterState = this.accountFilter.asObservable();

  public isFilter: boolean = false;

  public deviceSubs!: Subscription;

  constructor(private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService,
    private httpClient: HttpClient) {

  }
  public fetchFilterRegion(callback: Function) {
    this.restService.getApi(END_POINTS.GLOBAL_DASHBOARD_L_REGION).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public fetchFilterDevices(reqData: any, callback: Function) {
    const req = {
      regions_list: reqData.region || [],
      vendor_list: reqData.vendor || [],
      category_list: reqData.cat || []
    };
   this.deviceSubs =  this.restService.postApi(END_POINTS.GLOBAL_DASHBOARD_L_DEVICE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  finalFilterData(data: ITreeView[] | undefined) {
    data?.map(item => {
      if (('data' in item)) {
        delete item.parent;
        delete item.lavel;
        this.finalFilterData(item.data || []);
      } else {
        delete item.lavel;
        delete item.parent;
      }
    })
  }
  public saveFilter(filterDetails: any, callback: Function) {
    let req: any = {};
    req.all_regions = filterDetails?.all_regions || false;
    req.all_vendors = filterDetails?.all_vendors || false;
    req.all_categories = filterDetails?.all_categories || false;
    this.finalFilterData(filterDetails?.regions_list);
    this.finalFilterData(filterDetails?.vendor_list) 
    this.finalFilterData(filterDetails?.category_list)
    req.regions_list = filterDetails?.regions_list?.length ? filterDetails?.regions_list : [];
    req.vendor_list = filterDetails?.vendor_list?.length ? _.map(filterDetails?.vendor_list, 'id') : [];
    req.category_list = filterDetails?.category_list?.length ? _.map(filterDetails?.category_list, 'id') : [];

    req.all_devices = filterDetails?.all_devices || false;
    req.devices_list = filterDetails?.devices_list?.length ? filterDetails?.devices_list : [];

    this.restService.postApi(END_POINTS.SAVE_DASHBOARD_FILTER, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public prepareDataForGlobalFilter(globalFilter: ITreeView, reqObj: any = {}) {
    reqObj.id = globalFilter.id;
    reqObj.vendor = [];
    globalFilter?.data?.forEach((vendorObj: ITreeView) => {
      const currentVendorObj: any = {};
      currentVendorObj.id = vendorObj.id;
      currentVendorObj.device = [];
      vendorObj?.data?.forEach((deviceObj: ITreeView) => {
        const currentDeviceObj: any = {};
        currentDeviceObj.id = deviceObj.id;
        currentDeviceObj.hostname = deviceObj.label;
        currentVendorObj.device.push(currentDeviceObj);
      })
      reqObj.vendor.push(currentVendorObj);
    })
    return reqObj;
  }
  public showFilter (flag: boolean) {
    this.isFilter = flag;
  }
}
