import { Component, ElementRef, Inject, Input, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { ITokenDetail } from 'src/app/modules/shared/model/scan/scan';
import { RestService } from 'src/app/modules/shared/services/rest.service';
import { END_POINTS } from 'src/app/modules/shared/constants/endpoints';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { Utils } from 'src/app/modules/shared/utils';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { PrivacypolicyComponent } from '../privacypolicy/privacypolicy.component';
import { TermConditionsComponent } from '../terms-conditions/term-conditions.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./../login/login.component.scss', './reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public changePassAttempt = 0;
  showSuccessMessage: boolean = false;
  activationDetail: ITokenDetail = {};
  password!: string;
  confirmPassword!: string;
  public resetPwdForm!: FormGroup;
  oldPassHide = true;
  hide = true;
  hideConfirmPassword = true;
  resetTokenId: string = ""
  resetId: string = ""
  isChangePassword = false;
  upperCaseFlag = false;
  lowerCaseFlag = false;
  numberFlag = false;
  symbolFlag = false;
  minimumFlag = false;
  maximumFlag = false;
  isLoginWindow = false;
  isOrgUser:boolean = false;
  responseData:any
  activatedCallCount: number = 0;
  createChangePasswordLabel = CONSTANT.CHANGE_PASSWORD_LABEL;
  firstName = "";
  lastName = "";
  email = "";
  isNewUser:boolean = false; 
  isSpinner: boolean = false;
  validTokenTime = true;
  @Input() responseInput: any; 
  @ViewChild('myButton') myButton!: ElementRef;
  isResend: boolean= false;
  isUserDeleted: boolean = false;
  isUserDeletedMessage:any
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.length != 12) {
      return { 'password': true };
    }
    return null;
  }

  validatePassword(control: AbstractControl) {
    let pwd = control.value
    if (pwd.length > 0) {
      this.upperCaseFlag = /[A-Z]/.test(pwd);
      this.lowerCaseFlag = /[a-z]/.test(pwd);
      this.numberFlag = /[0-9]/.test(pwd);
      this.symbolFlag = /\W/.test(pwd);
      this.minimumFlag = pwd.length >= 12;
      this.maximumFlag = this.minimumFlag && pwd.length <= 20;

      if (this.resetPwdForm) {
        if (this.resetPwdForm.controls['password'] && this.validatePasswordPattern()) {
          return null;
        } else {
          return { pattern: true }
        }
      }
    }else{
      this.upperCaseFlag = false;
      this.lowerCaseFlag = false;
      this.numberFlag = false;
      this.symbolFlag = false;
      this.minimumFlag = false;
      this.maximumFlag =false;
    }
    return null;
  }

  validatePasswordPattern() {
    return (this.upperCaseFlag && this.lowerCaseFlag && this.numberFlag && this.symbolFlag && this.minimumFlag && this.maximumFlag)
  }

  validation_messages = {
    'oldPassword': [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_OLD_PASSWORD_REQUIRED },
      { type: 'maxlength', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MAX_LENGTH },
      { type: 'minlength', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MIN_LENGTH },
      { type: 'noSpaceAllowed', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_NO_SPACE }
    ],
    'password': [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_NEW_PASSWORD_REQUIRED },
      { type: 'maxlength', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MAX_LENGTH },
      { type: 'minlength', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MIN_LENGTH },
      { type: 'pattern', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_FORMAT },
      { type: 'noSpaceAllowed', message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_NO_SPACE }
    ],
    'confirmPassword': [
      { type: 'required', message: CONSTANT.AUTHENTICATION.CONFIRM_PASSWORD_REQUIRED },
      { type: 'mustMatch', message: CONSTANT.AUTHENTICATION.CONFIRM_PASSWORD_MUST_MATCH },
    ],
    'firstname': [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_MSG },
      { type: 'maxlength', message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_MAX_LENGTH },
      { type: 'minlength', message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_MIN_LENGTH },
      { type: 'pattern', message: CONSTANT.AUTHENTICATION.AUTH_FIRST_NAME_PATTERN } 
    ],
    'lastname': [
      { type: 'required', message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_MSG },
      { type: 'maxlength', message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_MAX_LENGTH},
      { type: 'minlength', message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_MIN_LENGTH },
      { type: 'pattern', message: CONSTANT.AUTHENTICATION.AUTH_LAST_NAME_PATTERN } 
    ]
  };

  constructor(private fb: FormBuilder,
    public router: Router,
    private httpService: AuthService,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private dialogService: DialogService,
    private messageService: MessageServiceService,
    private renderer: Renderer2,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    
  }

  ngOnInit(): void {
    this.isLoginWindow = false;
    this.createResetPwdForm();
    if (this.data && this.data.data && this.data.data.isChangePassword) {
      this.activationDetail.valid = true;
      this.isChangePassword = true;
      this.resetPwdForm.get('firstname')?.setValidators(null);
      this.resetPwdForm?.get('firstname')?.updateValueAndValidity();
      this.resetPwdForm.get('lastname')?.setValidators(null);
      this.resetPwdForm?.get('lastname')?.updateValueAndValidity();
      return;
    }

    this.activatedRoute.params.subscribe((param: Params) => {
      //console.log(this.router.url)
      if (param['id'] && param['token']) {
        this.createChangePasswordLabel = CONSTANT.CREATE_PASSWORD_LABEL;
        this.isLoginWindow = true;
        this.resetPwdForm.get('oldPassword')?.setValidators(null);
        this.resetPwdForm?.get('oldPassword')?.updateValueAndValidity();
        this.resetPwdForm.get('firstname')?.setValidators(null);
        this.resetPwdForm?.get('firstname')?.updateValueAndValidity();
        this.resetPwdForm.get('lastname')?.setValidators(null);
        this.resetPwdForm?.get('lastname')?.updateValueAndValidity();
        this.resetTokenId = param['token'];
        this.resetId = param['id'];
        //this.spinnerService.show();
        if(this.responseInput) {
          this.processResponse(this.responseInput)
        } else {
          this.restService.getApi(`${END_POINTS.RESET_PASS_TOKEN_API}/${param['id']}/${param['token']}`).subscribe({
            next: (response) => {
              this.spinnerService.hide();
              if (response.body.data && response.body.data.acount_status) {
                this.isUserDeleted = response.body.data.acount_status === "acount_deleted";
                this.isUserDeletedMessage = response.body.message;
            }
              this.processResponse(response)
            },
            error: (err) => {
              this.spinnerService.hide();
              this.activationDetail.valid = false;
              this.validTokenTime= err.error.data.valid_token_time;
              this.isResend = err.error.data.resend_email;
              this.showSuccessMessage = true;
              if(!this.isResend) {
                this.activationDetail.message = CONSTANT.INVALID_RESET_TOKEN_MSG;
                const snakMsg: IToastrOptions = {
                  message: CONSTANT.INVALID_RESET_TOKEN_MSG,
              }
                this.messageService.showError(snakMsg);
                this.router.navigate(["login"]);
              }
              
            },
          })
        }
        
      } else {
        this.resetPwdForm.get('oldPassword')?.setValidators(Validators.required);
      }
      this.resetPwdForm?.get('oldPassword')?.updateValueAndValidity();
    })
  }

  resendEmail() {
    this.activatedRoute.params.subscribe((param: Params)=>{
      if (param['id'] && param['token']) {

        const snakMsg: IToastrOptions = {}
      this.spinnerService.show();
      if (this.showSuccessMessage) {
        this.activatedCallCount++;
        if(this.activatedCallCount >= 3){
          if(this.activatedCallCount === 2){
           // console.log(this.activatedCallCount)
            this.router.navigate(["login"]);
          }
        return;
      }
    }
      if(this.showSuccessMessage){
      this.restService.getApi(`${END_POINTS.RESEND_EMAIL}/${param['id']}/${param['token']}`).subscribe({
        next: (response) => {
          this.spinnerService.hide();
          if(response.status === 200) {
            this.responseData =response; 
            this.validTokenTime = false;
            this.activationDetail.message = response.body.message;
            //this.email = response.body.data.email;
            //this.validTokenTime = response.body.data.valid_token_time;
             //this.isOrgUser = response.body.data.org_user;
            // if(this.activationDetail.message === CONSTANT.AUTHENTICATION.INVALID_TOKEN_MSG.toLocaleLowerCase()) {
            //   this.activationDetail.valid = false;
            // } else {
            //   this.activationDetail.valid = true;
            // }
            snakMsg.message = response.body.message;
            
            this.messageService.showSuccess(snakMsg);
            this.router.navigate(["login"]);
          
            
          }
        },
        error: (err) => {
          this.spinnerService.hide();
          this.validTokenTime = err.error.data.valid_token_time;
          
          snakMsg.message = err.body.message;
            this.messageService.showError(snakMsg);
            this.router.navigate(["login"]);
        },
      })
    }
      }
    })
  }

  processResponse(response:any) {
    if (response.status === 200) {
      this.activationDetail.message = response.body.message;
      if (this.activationDetail.message === CONSTANT.INVALID_RESET_TOKEN_MSG) {
        this.activationDetail.valid = false;
      } else {
        this.activationDetail.valid = true;
        if (response.body != undefined && response.body.data != null) {
          this.validTokenTime = response.body.data.valid_token_time;
          this.isResend = response.body.data.resend_email;
          this.email = response.body.data.email;
          if(response.body.data.first_name !==undefined || response.body.data.last_name !==undefined) {
            this.firstName = this.makeFirstLetterCapital(response.body.data.first_name);
            this.lastName = this.makeFirstLetterCapital(response.body.data.last_name);
          } else {
            this.firstName = this.makeFirstLetterCapital(response.body.data.firstname);
            this.lastName = this.makeFirstLetterCapital(response.body.data.lastname);
          }
          
          this.email = response.body.data.email;
          if(this.firstName || this.lastName){
            this.isNewUser = false;
          }
          else {
            this.isNewUser = true;
            const validatorsToKeep = [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(32),
              Validators.pattern(/^[a-zA-Z]+( [a-zA-Z]+)*$/)
            ];
            this.resetPwdForm.get('firstname')?.setValidators(validatorsToKeep);
            this.resetPwdForm?.get('firstname')?.updateValueAndValidity();
            this.resetPwdForm.get('lastname')?.setValidators(validatorsToKeep);
            this.resetPwdForm?.get('lastname')?.updateValueAndValidity();
          }
        }
      }
    } else {

      this.activationDetail.valid = false;
    }
  }

  onClose(): void {
    this.dialogService.closeDialog();
  }
  createResetPwdForm() {
    this.resetPwdForm = this.fb.group({
      oldPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(20)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(20),
        Utils.noSpaceAllowed,
        this.validatePassword.bind(this)
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required
      ])),
      firstname: new FormControl('',Validators.compose([
        Validators.required 
      ])),
      lastname: new FormControl('',Validators.compose([
       Validators.required
      ])),
      accept: this.isChangePassword ? false : true
    },
      { validators: this.mustMatch('password', 'confirmPassword') })
    setTimeout(() => {
      this.resetPwdForm?.get('oldPassword')?.markAsUntouched();
    }, 10);
  }

  mustMatch(password: any, confirmPassword: any) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (confirmPasswordControl.errors && !confirmPasswordControl.errors['mustMatch']) {
        return
      }
      if (passwordControl.value != confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ mustMatch: true });
      }
      else {
        confirmPasswordControl.setErrors(null);
      }
    };

  }

  public onChangeEvent(e: any, length: any) {
    const validationRules = {
      firstname: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
      lastname: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
    };
    type ValidationField = keyof typeof validationRules;
    const name: ValidationField = e.target.getAttribute('formcontrolname')
    const maxSize = length;

    if (e.target.value.length > maxSize) {
      this.resetPwdForm.controls[name].setValue(e.target.value.substring(0, maxSize))
      this.resetPwdForm.controls[name].setErrors({ maxlength: true });
    }
    else if (Object.keys(validationRules).includes(name)) {
      const regex = validationRules[name];
      if (regex && regex.test(e.target.value.trim())) {
        this.resetPwdForm.controls[name].setValue(e.target.value)
        this.resetPwdForm.controls[name].setErrors(null);
      } else {
        this.resetPwdForm.controls[name].setValue(e.target.value.substring(0, e.target.value.length - 1));
        this.resetPwdForm.controls[name].setErrors({ pattern: true });
      }
    } else {
      this.resetPwdForm.controls[name].setValue(e.target.value);
    }
    this.resetPwdForm.get(name)?.markAsTouched();
  }

  public onBlur(e: any) {
    const name = e.target.getAttribute('formcontrolname')
    this.resetPwdForm.controls[name].setErrors(null);
    this.resetPwdForm.controls[name].markAsTouched();
    this.resetPwdForm.controls[name].updateValueAndValidity();
  }

  get f() {
    return this.resetPwdForm.controls;
  }
  public createPassword() {
    if (this.resetPwdForm.valid) {
      // this.spinnerService.show();
      this.restrictFormAccess(true)
      this.isSpinner = true;
      let resetPassQueryString = '';
      if (this.resetId && this.resetTokenId) {
        resetPassQueryString = this.resetId + '/' + this.resetTokenId;
      }

      this.httpService.changePassword(this.resetPwdForm.value, resetPassQueryString, this.isLoginWindow, this.isNewUser).subscribe((result) => {
        this.spinnerService.hide();
        const snackMsg: IToastrOptions = {}
        if (this.isLoginWindow) { // true mean when user change pass in login screen.
          if (result.status === 200) { // for reset/forget password
            if (result.message == CONSTANT.PASSWORD_RESET_MESG) {
              this.showSuccessMessage = true;
            } else {
              snackMsg.message = CONSTANT.SUCCESS_CHANGE_FAIL_MSG;
              this.messageService.showError(snackMsg);
            }
          }
        } else { // for change password 
          if (result.status === 500) {
            this.changePassAttempt++;
            if (this.changePassAttempt === 3) {
              this.httpService.logOut();
            }
          }
          this.dialogService.closeDialog();
          snackMsg.message = CONSTANT.SUCCESS_CHANGE_SUCCEES_MSG;
          this.messageService.showSuccess(snackMsg);
          setTimeout(() => {
            this.httpService.logOut();
          }, 5000);
        }

      },
        (err) => {
          const errRes: IToastrOptions = {
            message: err.error.message ? err.error.message : err.statusText,
          }
          if (!this.isLoginWindow) {
            this.changePassAttempt++;
            errRes.message = err?.error?.message;
            if (this.changePassAttempt === 3 && err?.error?.status === 500) {
              this.httpService.logOut();
              this.dialogService.closeDialog();
            }
          }
          this.isSpinner = false;
          this.restrictFormAccess(false)
          this.spinnerService.hide();
          this.messageService.showError(errRes);
        });
    }
  }

  onPrivacy(){
    const payload: IDialog = {
      component: PrivacypolicyComponent,
      disableClose: true,
      width: '75%',
      data: {}
    }
    this.dialogService.openDialog(payload)
  }

  onTerms(){
    const payload: IDialog = {
      component: TermConditionsComponent,
      disableClose: true,
      callBack: function (data: any) {
       
      },
      width: '75%',
      data: {}
    }
    this.dialogService.openDialog(payload)
  }

  getClassValidation(flag: boolean): string {
    return flag ? 'green' : 'black'
  }
  restrictFormAccess(flag: boolean): void{
    Utils.restrictFormAccess(flag, this.renderer, this.resetPwdForm, this.myButton);
  }
  public makeFirstLetterCapital(value: string = ''): string{
    return Utils.makeFirstLetterCapital(value)
  }
}


