<div class="netsec-modelbox">
    <h2 mat-dialog-title class="title">{{ data.title }}</h2>
    <div class="modelbox-body">
        <p class="sub-text">{{ data.content }}</p>
        <form [formGroup]="vendorForm">
            <mat-radio-group formControlName="vendorSelection" class="report-radio-label">
                <div>
                    <mat-radio-button value=1>Selected vendor</mat-radio-button>
                    <span>Removes assets from other vendors</span>
                </div>
                <div>
                    <mat-radio-button value=0>Multiple vendor</mat-radio-button>
                    <span>Includes assets from other vendors</span>
                </div>
            </mat-radio-group>
        </form>
    </div> 
    <div class="modelbox-footer">
        <button *ngFor="let button of data.buttons" 
                [ngClass]="button.class" 
                class="btn" 
                mat-dialog-close
                (click)="button.callBack && button.callBack(vendorForm.value)" 
                [disabled]="button.label === 'Connect' && vendorForm.invalid">
            {{ button.label }}
        </button>
    </div>
</div>
