import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDailog } from '../../model/Dialog.model';
import { DialogService } from '../../services/dialog.service';

@Component({
    selector: 'app-active-session',
    templateUrl: './active-session.component.html',
    styleUrls: ['./active-session.component.scss']
})
export class ActiveSessionComponent {

    constructor(public dialogRef: MatDialogRef<ActiveSessionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
        public dialogService: DialogService
        ) {
    }

    signInAgain(): void {
        // this.dialogRef.close(1)
        this.data?.data?.setCallbackFunction(1);
        this.dialogService.closeDialog();
    }

    continueWithExisting(): void {
        // this.dialogRef.close(2)
        this.data?.data?.setCallbackFunction(2);
        this.dialogService.closeDialog();
    }
}
