<div class="chart-section security_score">
    <!-- <div *ngIf="isSpinner" class="loading-text">{{constant.LOADING}}</div> -->

    <h3 class="spark-sec-tittle" *ngIf="title" >{{title}} <span class="actual-score" *ngIf="!isSpinner && (actualScoreValue || actualScoreValue===0) && !showOldGraph"> <mat-icon [matMenuTriggerFor]="menu" class="info-icon">info</mat-icon> Actual Score: <span [ngClass]="getScoreStatus(actualScoreValue)">{{actualScoreValue}}</span></span></h3> 
    <mat-menu id="info-menu" #menu="matMenu" class="benchmark-info whitebox-info mat-menu-scroll">
        <div class="scroll">
            <strong>The actual score doesn't consider any risk that are accepted </strong>
        </div>
    </mat-menu>
    <div [hidden]="isSpinner" class="security-score" *ngIf="!isSpinner && !showOldGraph">
        <div class="image-scroe-section" *ngIf="securityValue || securityValue===0 && !showOldGraph">
            <img *ngIf="selectSecurityImage" src="../../assets/images/{{selectSecurityImage}}">
            <div class="score-value">{{securityValue}}</div>
        </div>
        <!-- <div class="security-score-legends" *ngIf="isGraphDataReceived">
            <ul class="legend">
                <li class="pad-bot-1"><span class="legend-security-score legend-dot"></span> <span>Security Score</span>
                </li>
                <li class="pad-bot-1"><span class="legend-secured-score legend-dot"></span> <span>Secured Parameters</span>
                </li>
                <li class="pad-bot-1"><span class="legend-unsecured-score legend-dot"></span> <span>Unsecured
                        Parameters</span> </li>
            </ul>
        </div> -->
    </div>
    <div class="security-score" *ngIf="!isSpinner && showOldGraph">
        <div class="security-score-chart"
            *ngIf="chartOptions?.series && chartOptions?.chart && chartOptions?.plotOptions && chartOptions?.labels && chartOptions?.colors && showOldGraph">
            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions"
                [labels]="chartOptions.labels" [legend]="chartOptions.legend" [colors]="chartOptions.colors"
                [responsive]="chartOptions.responsive"></apx-chart>
        </div>
    </div>
    <div class="security-score-loader" *ngIf="isSpinner && !showOldSkeleton">
        <ngx-skeleton-loader class="apx-chart-loader"  count="1" appearance="circle" [theme]="{ width: '120px', height: '120px' }"> </ngx-skeleton-loader> 
        <ngx-skeleton-loader class="apx-chart-loader"  count="1" [theme]="{ width: '60px', height: '60px' }"> </ngx-skeleton-loader> 
      </div>
    <div class="security-score-loader" *ngIf="isSpinner && showOldSkeleton">
    <ngx-skeleton-loader class="apx-chart-loader"  count="1" appearance="circle" [theme]="{ width: '120px', height: '120px' }"> </ngx-skeleton-loader> 
    </div>
</div>
