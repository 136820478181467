<div class="device-popup">
    <!-- <button class="popup-close-btn" mat-button [mat-dialog-close]="true"> <mat-icon>close</mat-icon></button> -->
    <div class="device-popup-header">
        <h3 class="text_center">{{title}}</h3>
        <p *ngIf="!userProfile?.sso_enabled" class="text_center">{{text}}</p>

    </div>
    <div class="device-data">
        <div class="device-popup-row">
            <form [formGroup]="confirmPasswordForm" (ngSubmit)="confirm()">
                <mat-form-field appearance="outline" class="full-width mb_3 border-field no-bg" *ngIf="!userProfile?.sso_enabled">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'"
                    formControlName="password">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngFor="let validation of validation_messages.password">
                        <mat-error class="error-message" *ngIf="confirmPasswordForm.get('password')?.hasError(validation.type) && 
                    (confirmPasswordForm.get('password')?.dirty || confirmPasswordForm.get('password')?.touched)">
                            {{validation.message}}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width textarea-field border-field no-bg" *ngIf="buttonLabel ==='Commission' || buttonLabel === 'Decommission'">
                    <mat-label>Reason</mat-label>
                    <textarea matInput #messageInput class="scroll" (input)="checkTextField($event)"
                        formControlName="reason" minlength="8" maxlength="120" spellcheck="false"></textarea>
                    <mat-error *ngFor="let validation of validation_messages.reason">
                        <mat-error class="error-message"
                            *ngIf="confirmPasswordForm.get('reason')?.hasError(validation.type) && 
                                (confirmPasswordForm.get('reason')?.dirty || confirmPasswordForm.get('reason')?.touched)">
                            {{validation.message}}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <!-- <p class="field_length">Please enter maximum 120 characters</p> -->
                <div  *ngIf="buttonLabel === 'Commission' ||  buttonLabel === 'Decommission'" class="d-flex popup-footer-btns">
                    <!-- <button mat-raised-button class="full-width" color="primary" [disabled]="!confirmPasswordForm.valid">Submit</button> -->
                    <button #myButton class=" btn btn-tartorange"
                    [ngClass]="{'disableMe':!confirmPasswordForm.valid, 'btn-tartorange': buttonLabel === 'Decommission',
                    'btn-primary': buttonLabel === 'Commission',}" [disabled]="!confirmPasswordForm.valid">
                    <span>{{buttonLabel}}</span>
                        <!-- <span class="btn-loader spin"><img src="../../assets/images/btn-loader.png" /></span> -->
                    </button>
                    <button class="btn  btn-lightblack-outline" [mat-dialog-close]="true"> Cancel</button>
                </div>
                <div *ngIf="!(buttonLabel ==='Commission' || buttonLabel === 'Decommission')" class="d-flex popup-footer-btns">
                    <button  class="btn  btn-primary"    [mat-dialog-close]="true" (click)="onClose()"> Cancel</button>
                    <button #myButton class="btn btn-tartorange-outline"
                    [ngClass]="{'disableMe': !confirmPasswordForm.valid && !userProfile.sso_enabled}"
                    [disabled]="!confirmPasswordForm.valid && !userProfile.sso_enabled">
                    <span>{{buttonLabel}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>