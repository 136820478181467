import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appSafariEllipsis]'
})
export class SafariEllipsisDirective implements AfterViewInit {
  
  constructor(private elementRef: ElementRef,
     private matTooltip: MatTooltip
  ) {}

  

  ngAfterViewInit(): void {
      const element = this.elementRef.nativeElement;

      

      if (this.isSafariBrowser()) {
        this.elementRef.nativeElement.removeAttribute('options');
        this.elementRef.nativeElement.removeAttribute('tooltip');
        if(this.matTooltip){
          this.matTooltip.disabled = true;
        }
        this.elementRef.nativeElement.setAttribute('title','');
      } else {
        this.elementRef.nativeElement.setAttribute('title','');
      }

  }
  private isSafariBrowser(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}

