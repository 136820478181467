import { Type } from '@angular/core';
import { VulnerabilityComponent } from '../components/vulnerability/vulnerability.component';

export const CONFIG = {
  PAGE_API: {
    ASSETS_DETAILS: 'assets',
    DECOMMISION_ASSETS: 'decommission',
    CVE_DASHBOARD: 'cve-list',
    MY_SCANS:'myScans',
    SCHEDULE_LIST:'schedule_list',
    SCAN_STATUS_LIST:'security_status_list',
    SECURITY_INFO_LIST:'security_info_list',
    SCAN_DETAILS:'scan_details_list',
    USER_MANAGEMENT: 'user-management',
    USER_ROLE: 'user-roles'
  },
  METHOD: {
    POST: 'post',
    GET: 'get',
  },
  RESPONSE_TYPE: {
    SINGLE: 'single',
    ASYNC: 'async',
    NONE: 'none',
  },
  API_CONFIG: {
    ASSETS: {
      NUMBER_OF_PAGES: 5,
    },
  },
  AG_GRID_CONFIG: {
    RESTRICT_CHECKBOX_SELECTION_ON_ROW_CLICK: true,
    SCROLL_WIDTH: 5,
    HIDE_CONTEXT_MENU: true,
    enableOnChange: true,
    pagination: {
      SHOW: true,
      DROP_DOWN: [10, 50, 100],
      PAGE_SIZE: 10,
    },
    ROW_SELETION: {
      SINGLE: 'single',
      MULTIPLE: 'multiple',
    },
    DEFAULT_COLUMN: {
      editable: false,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
    },
    SIDEBAR: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true, // Hide row groups
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: false,
            suppressColumnSelectAll: false,
            suppressColumnExpandAll: false,
            isToolPanelShowing: true,
          },
        },
      ],
      defaultToolPanel: null,
    },
  },
  TABLE_INFO: {
    ASSETS: {
      HEADER_LABEL: {
        BLANK: '',
        POSITION: '#',
        ASSETS_NAME: 'Asset Name',
        ASSETS_TYPE: 'Asset Type',
        OEM: 'OEM',
        REGION: 'Region',
        TOTAL_SCAN: 'Total & Last Scan',
        SECURITY_SCORE: 'Security Score',
        VULNERABILITY: 'Vulnerabilities',
        TYPE: 'Type',
        VENDOR: 'Vendor',
        CVES: 'CVE’s',
        LAST_UPDATED: 'Last Updated'
      },
      PROPS: {
        CHECKBOX: 'selection',
        ASSETS_NAME: 'device_name',
        ASSETS_TYPE: 'device_type',
        OEM: 'vendor',
        REGION: 'region_name',
        TOTAL_SCAN: 'last_scan_date',
        SECURITY_SCORE: 'security_score',
        VULNERABILITY: 'vulnerability',
        OPTIONS: 'assets-option',
        POSITION: 'position',
        CVES: 'cve',
        LAST_UPDATED: 'cve_last_modified'
      }
    },
    MY_SCANS: {
      HEADER_LABEL: {
        BLANK:'Options',
        SERIAL_NO: '#',
        SCAN_NAME: 'Scan Name',
        SCAN_TIME: 'Scan Time',
        STATUS: 'Status',
        ASSETS: 'Assets',
        SECURITY_SCORE: 'Security Score',
        VULNERABILITIES: 'Vulnerabilities',
        USER: 'User'
        
      },
      PROPS: {
        SERIAL_NO: 'position',
        SCAN_NAME: 'scanname',
        SCAN_TIME: 'date_time',
        STATUS: 'status',
        ASSETS: 'total_count',
        SECURITY_SCORE: 'securityrating',
        VULNERABILITIES: 'vulnerabilities',
        USER: 'user',
        OPTIONS: 'scan-options',
      },
    },
    SCAN_STATUS: {
      HEADER_LABEL:{
        SERIAL_NO: '#',
        FILE_NAME:'File Name',
        ASSET_NAME:'Asset Name',
        STATUS:'Status',
        REGION:'Region',
        TYPE:'Type',
        VENDOR:'Vendor',
        COMMENT:'Comments'


      },
      PROPS: {
        SERIAL_NO: 'position',
        FILE_NAME:'file_name',
        ASSET_NAME:'asset_name',
        STATUS:'Status',
        REGION:'region',
        TYPE:'category',
        VENDOR:'vendor',
        COMMENT:'reason'

      }
    },
    SCHEDULE_LIST: {
      HEADER_LABEL:{
        SERIAL_NO: 'Scan ID',
        SCAN_NAME:'Scan Name',
        FREQUENCY:'Frequency',
        CREATED_BY:'Created By',
        SCHEDULED_TIME:'Scheduled Time',
        NUBMER_OF_ASSETS:'Number of Assets',
        DATE:'Date',
        


      },
      PROPS: {
        SERIAL_NO: 'schedule_scan_id',
        SCAN_NAME:'schedule_name',
        FREQUENCY:'frequecy_name',
        CREATED_BY:'user_name',
        SCHEDULED_TIME:'time',
        NUBMER_OF_ASSETS:'num_of_assets',
        DATE:'date',

      }
    },
    SECURE_INFO: {
      HEADER_LABEL: {
        POSITION: '#',
        PARAMETERS: 'Parameters',
        BENCHMARK: 'Benchmark',
        ACCEPTED_RISK: 'Accepted Risk',
        WHITELIST: 'Whitelist',
        COMPLAINT_ASSETS: 'Compliant Assets',
        SEVERITY: 'Severity',
        
      },
      PROPS: {
        POSITION: 'position',
        PARAMETERS: 'paramter',
        DESCRIPTION:'desctiption',
        BENCHMARK: 'Benchmark',
        ACCEPTED_RISK: 'Accepted Risk',
        WHITELIST: 'Whitelist',
        COMPLAINT_ASSETS: 'Compliant Assets',
        SEVERITY: 'vulnerability',
      },
    },
    CVE_DASHBOARD: {
      HEADER_LABEL: {
        POSITION: '#',
        CVE_ID: 'CVE',
        CVSS: 'CVSS',
        SEVERITY: 'Severity',
        CVSS_DATE: 'CVE Issued Date',
        CVSS_UPDATE_DATE: 'CVE last updated on',
        CISA_KEY: "CISA's KEV",
        EPSS: 'EPSS',
        VECTOR: 'Vector',
        AFFECTED_ASSETS: 'Affected Assets',
      },
      PROPS: {
        POSITION: 'position',
        CVE_ID: 'cve_id',
        CVSS: 'score',
        SEVERITY: 'severity',
        CVSS_DATE: 'cve_issued_date',
        CVSS_UPDATE_DATE: 'cve_last_updated',
        CISA_KEY: 'cisas_key',
        EPSS: 'epss',
        VECTOR: 'vector_string',
        AFFECTED_ASSETS: 'asset_count',
      }
    },
    REGION_MANAGEMENT: {
      HEADER_LABEL: {
          BLANK: '',
          POSITION: '#',
          FIRST_NAME: 'First Name',
          LAST_NAME: 'Last Name',
          EMAIL: 'Email',
          PHONE: 'Phone',
          MFA: 'MFA',
          STATUS: 'Status',
          ROLE: 'Role'
      },
      PROPS:{
        CHECKBOX: 'selection',
        POSITION: 'position',
        FIRST_NAME: 'firstname',
        LAST_NAME: 'lastname',
        EMAIL: 'organization_email',
        PHONE:'phone_number',
        COUNTRY: 'country_code',
        MFA: 'mfa',
        STATUS: 'status',
        ROLE: 'role',
        OPTIONS: 'region-option',
      }
    },
  },
};
