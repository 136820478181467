import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-multiple-vendor-popup',
  templateUrl: './multiple-vendor-popup.component.html',
  styleUrls: ['./multiple-vendor-popup.component.scss']
})
export class MultipleVendorPopupComponent {
  vendorForm: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    this.vendorForm = this.fb.group({
      vendorSelection: [null, Validators.required]
    });
  }


}
