import { Component, Inject } from '@angular/core';
import { CustomDailog } from '../../model/Dialog.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-all-plans',
  templateUrl: './all-plans.component.html',
  styleUrls: ['./all-plans.component.scss']
})
export class AllPlansComponent {

  userDetails :any
  expiredOn: string | null = null; // For storing the formatted expiry message
  expiresInDays:any;
  subscriptionCode:string|null = '';
constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog,
private profileService: ProfileService,
public authService:AuthService) {
  this.userDetails = data.data;
  const currentDate = new Date();
  const expiryDate = new Date(this.userDetails.expiry_date);
  const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
  const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  this.expiresInDays = expiresInDays;
  if (expiresInDays <= 0) {
    this.expiredOn = this.formatDate(expiryDate);
  }
  this.subscriptionCode = this.userDetails.subscription_code
}
private formatDate(date: Date): string {
  const day = date.getDate();
  const monthNames = ["Jan", 
  "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const monthName = monthNames[date.getMonth()];
  const year = date.getFullYear();
  
  const suffix = this.getOrdinalSuffix(day);
  return `Expired on ${day}${suffix} ${monthName}, ${year}`;
}

private getOrdinalSuffix(day: number): string {
  if (day > 3 && day < 21) return 'th'; // for numbers like 11th, 12th, 13th, etc.
  switch (day % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
onClose(callBack: Function | undefined) {
  if (callBack) {
      callBack();
  }
}

logout() {
  this.profileService.reset();
  this.authService.logoutUser().subscribe({
    next: (result) => {
      if (result.status === 200) {
        this.authService.logOut();
        this.authService.userLoggedOutSubject.next();
      }
    },
    error: (error) => {
      console.error('Error during logout:', error);
      this.authService.logOut();
      this.authService.userLoggedOutSubject.next();
      
    }
  });
}

}
