import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { FilterService } from '../../services/filter.service';
import { IApply, ITreeView } from './filter.model';
import { TreeComponent } from './tree/tree.component';
import { SpinnerService } from '../../services/spinner.service';
import { HeaderService } from '../../services/header.service';
import { CONSTANT, ToolTipConfig } from '../../constants/constant-data';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Utils } from '../../utils';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  showParagraph:boolean = false;
  @Input() 
  isHeaderHide:boolean = true
  @Input() 
  isDividerHide:boolean = true
  @Input() 
  isRightClickMenuHide:boolean = true
  @Input()
  filterColumnData!: Map<String, ITreeView[] | any>;

  @Input()
  filterSecondLevelData: ITreeView[] | any = [];

  @Input()
  type: string = 'checkbox';

  @Input()
  saveBtnLabel: string = 'Apply';

  @Input()
  showCloseBtn: boolean = true;

  @Input()
  title!: string;

  @Input()
  saveButtonRequired: boolean = false;

  @Input()
  isFirstLevelFetchBtn: boolean = false;

  @Input()
  resetButtonRequired: boolean = false;

  @Input()
  clearButtonRequired: boolean = false;

  @Input()
  isSecondLevel: boolean = false;

  @Input()
  isFirstLevelApiCalled: boolean = false;

  @Input()
  isSecondLevelApiCalled: boolean = false;

  @Input()
  fetchBtnlebel: string = 'Fetch';

  @Input()
  autoUpdateBothLevel: boolean = false;

  @Input()
  isSingleLevel: boolean = false;

  @Input()
  placeholder: string = 'Search...';

  @Input()
  isGroupLevel: boolean = false;

  @ViewChild('searchField') searchField!: ElementRef;

  @Output()
  selectedFiltersEvent: EventEmitter<ITreeView[]> = new EventEmitter();

  @Output()
  selectedFirstLevelBtnEvent: EventEmitter<ITreeView[]> = new EventEmitter();

  @Output()
  selectedSecondLevelFiltersEvent: EventEmitter<ITreeView[]> = new EventEmitter();

  @Output()
  applyBasedOnSelectedData: EventEmitter<IApply>
   = new EventEmitter();
   
  @ViewChildren(TreeComponent) filterDatas!: QueryList<TreeComponent>;

  filterButtonClicked: boolean = false;

  selectedData: ITreeView[] | any = [];

  secondLevelSelectedData: ITreeView[] | any = [];

  backUpfilterColumnData!: Map<String, ITreeView[] | any>;

  isFirstLevelAllSelected: boolean = false;

  isSecondLevelAllSelected: boolean = false;

  searchText: string = '';

  allListSelected: boolean = false;

  public toolTipOption = _.cloneDeep(ToolTipConfig);

  public btnToolTipConfig = _.cloneDeep(ToolTipConfig);

  public toolTipMsg = CONSTANT.GLOBAL_FILTER_BTN_MSG;


  constructor(private filterService: FilterService, private spinnerService: SpinnerService,private headerService:HeaderService) {
    this.btnToolTipConfig.display =  this.isGroupLevel;
    this.toolTipOption.display = true;
  }
  
  ngOnInit(): void {
   this.headerService.getHeading().subscribe(heading => {
    console.log(heading);
    if(heading === 'Asset Management'){
      this.showParagraph = true;
    }
   })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['filterSecondLevelData'] &&
      _.isEmpty(changes['filterSecondLevelData'].currentValue)) {
        this.secondLevelSelectedData = [];
    }
    if (changes && changes['filterSecondLevelData'] &&
        (changes['filterSecondLevelData'].currentValue != changes['filterSecondLevelData'].previousValue )) {
          if(this.isSingleLevel) {
            this.btnToolTipConfig.display =  this.isSingleLevel;
            this.isAllSelected();
            this.secondLevelSelectedData = this.filterSecondLevelData.filter((obj: any)=>  obj.selected);
          }
    }
  }
  ngAfterViewInit(): void {
    this.backUpfilterColumnData = _.cloneDeep(this.filterColumnData);
    this.emitFirstLevelSelectedData();
    
  }
  filter(value: any) {
    if(!this.isSingleLevel) {
      this.filterService.filter.next(value);
    }
  }
  /* Emits selected filter nodes to parent component */
  saveNode() {
    this.applyBasedOnSelectedData.emit({firstLevel:this.selectedData, secondLevel: this.secondLevelSelectedData,
       isFirstLevelAllSelected: this.isFirstLevelAllSelected || false, isSecondLevelAllSelected: this.isSecondLevelAllSelected});
  }

  /* Resets all the selected nodes */
  resetAllFilters(): void {
    this.spinnerService.show();
    this.secondLevelSelectedData = [];
    this.filterSecondLevelData = [];
    this.searchField.nativeElement.value = '';
    this.filterColumnData.set('Region', _.cloneDeep(this.backUpfilterColumnData.get("Region")));
    this.spinnerService.hide();
  }
  clearAllFilters(): void {
    this.selectedData = [];
    this.filterDatas.filter(x => x.allSelected=false);
    this.secondLevelSelectedData = [];
    this.filterSecondLevelData = [];
    this.searchField.nativeElement.value = '';
    this.filterColumnData.forEach((value, key) => {
      this.clearData(this.filterColumnData?.get(key));
    })
    this.filterService.filter.next('clear');
  }
  private clearData(data: ITreeView[]): any {
    data?.forEach((obj, i) => {
      obj.selected = false;
      obj.indeterminate = false;
      this.clearData(obj.data || []);
    });
    return data;
  }
  getFilterValues(values: ITreeView[] | any): void {
    this.selectedData = values;
    this.selectedFiltersEvent.emit(this.selectedData);
  }
  emitFirstLevelSelectedData() {
    this.selectedFirstLevelBtnEvent.emit(this.selectedData);
  }
  firstLevelUpdatePartialInfo(event: boolean = false) {
    this.isFirstLevelAllSelected = event;
  }
  secondLevelFilterValues(values: ITreeView[] | any): void {
    this.secondLevelSelectedData = values;
    this.selectedSecondLevelFiltersEvent.emit(values);
    if(this.autoUpdateBothLevel) {
      this.applyBasedOnSelectedData.emit({firstLevel:this.selectedData, secondLevel: this.secondLevelSelectedData,
        isFirstLevelAllSelected: this.isFirstLevelAllSelected || false, isSecondLevelAllSelected: this.isSecondLevelAllSelected});
    }
  }
  secondLevelUpdatePartialInfo(event: boolean = false) {
    this.isSecondLevelAllSelected = event;
    if(this.autoUpdateBothLevel) {
      this.applyBasedOnSelectedData.emit({firstLevel:this.selectedData, secondLevel: this.secondLevelSelectedData,
        isFirstLevelAllSelected: this.isFirstLevelAllSelected || false, isSecondLevelAllSelected: this.isSecondLevelAllSelected});
    }
  }

  enabledDisaled(){
    let isEnable = true;
    if(this.isSecondLevel || this.isSingleLevel){
      if(this.filterService.isFilterTouch){
        if(this.secondLevelSelectedData.length && this.selectedData.length){
          isEnable = false;
        }
      } else {
        isEnable = true;
      }
    } else {
      if(this.selectedData.length){
        isEnable = false;
      }
    }
    return isEnable;
  }
  public closeFilter() {
    this.filterService.isFilterTouch = false;
    this.filterService.closeSidenav();
  }
  toggleAllSelection(): void {
    this.filterService.isFilterTouch = true;
    if (this.allListSelected) {
      this.filterSecondLevelData?.forEach((item: any) => {
        item.selected = true
      });
    } else {
      this.filterSecondLevelData?.forEach((item: any) => {
        item.selected = false
      });
    }
    this.secondLevelSelectedData = this.filterSecondLevelData.filter((obj: any)=>  obj.selected);
    this.isSecondLevelAllSelected = this.allListSelected;
  }
  
  isAllSelected(selectedItem: any = null): void {
    let newStatus = true;
    this.filterSecondLevelData?.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allListSelected = newStatus;
    this.isSecondLevelAllSelected = this.allListSelected;
  }  
  toggleSingleCheckBox(selectedItem: any): void {
    this.isAllSelected(selectedItem);
    this.secondLevelSelectedData = this.filterSecondLevelData.filter((obj: any)=> obj.selected);
    this.isSecondLevelAllSelected = this.allListSelected;
    this.filterService.isFilterTouch = true;
  }
}
