import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  isLoading = new Subject<ILoaderData>();

  constructor() {
  }

  show(msg: string = '', showDefaultSkel: boolean = false) {
     const msgDetails:ILoaderData = {
      message: msg || '',
      isLoader: true,
      showDefaultSkel: showDefaultSkel
     }
     this.isLoading.next(msgDetails);
  }

  hide() {
     const msgDetails: ILoaderData = {
      message: '',
      isLoader: false,
      showDefaultSkel: false
     }
     this.isLoading.next(msgDetails);
  }
}
export interface ILoaderData {
  isLoader?: boolean;
  message?: string;
  showDefaultSkel?: boolean;
}