<div class="cve-assets-details-section">
    <div class="cve-assets-details-header">
        <div class="cve-header cve-header-details" *ngIf="isCVEdetails">
            {{dataForCveDetails.selectedRow.cve_id}}
        </div>
        <div class="cve-header assets-header-details" *ngIf="!isCVEdetails">
            write dynamic changes for assets here
        </div>
        <div class="close-btn">
            <button (click)="closeSidenav()" mat-button class="btn close-sidebar">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <div class="cve-details" *ngIf="isCVEdetails">
        cve tab will be
    </div>
    <div class="assets-details" *ngIf="!isCVEdetails">
        Add assets details component here only
    </div>
</div>
