import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDailog } from '../../model/Dialog.model';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.component.html',
  styleUrls: ['./session-expired-modal.component.scss']
})
export class SessionExpiredModalComponent {
  sessionExpiredMessage: string = 'Your Session has been timeout. Please Sign In again to proceed with the system.';
  constructor(public dialogRef: MatDialogRef<SessionExpiredModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    public dialogService: DialogService){

  }

  onSignInClick(): void {
    // this.dialogRef.close(true)
    this.data?.data?.setCallbackFunction(true);
    this.dialogService.closeDialog();
  }

}
