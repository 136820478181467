import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { END_POINTS } from '../constants/endpoints';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notification = new Subject<boolean>();
  public notificationState = this.notification.asObservable();
  public notification_count!: number;
  public bellRefreshSubject = new Subject<void>();
  bellRefresh = this.bellRefreshSubject.asObservable();
  public alert_count!: number;
  constructor(
    private restService: RestService,
    private responseService: ResponseService,
  ) { }
 
  muteNotification(callBack: Function, mute_toggele: number){
    let url = END_POINTS.MUTE_NOTIFICATION
    url = url + `?mute_toggel=${mute_toggele}`
    this.restService.getApi(url).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }

  getNotification(callBack: Function){
    let url = END_POINTS.ALERT_NOTIFICATION;
    this.restService.getApi(url).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }

  markReadNotification(callBack: Function,notification_id?:string,read_all?:string,event_type_id?:string){
     let url = END_POINTS.READ_NOTIFICATION;
     let read_All = Number(read_all)
     if(notification_id && read_all && event_type_id){
       url = url + `?notification_id=${notification_id}&read_all=${read_All}&event_type_id=${event_type_id}`;
     }
     else {
       if(event_type_id && read_all){
        url = url + `?event_type_id=${event_type_id}&read_all=${read_All}`;
       }
     }
     this.restService.getApi(url).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }

  getUserDetails(callBack: Function){
    let url = END_POINTS.USER_DETAILS;
    this.restService.getApi(url).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }

  getNotificationData(callBack: Function, req:any){
    let url = END_POINTS.ALERT_NOTIFICATION_DATA;
    this.restService.postApi(url,req).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }
}
