import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
  constructor(private inject: Injector, private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getToken()) {
      // excludng auth type Bearer for logout and refresh api call, we would send auth type Basic in auth.service file for logout and refresh api
      if (req.url.includes('secure-auth/logout') !== true && req.url.includes('secure-auth/token') !== true) {
        const copyCurrentReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`  //token
          }
        });
        return next.handle(copyCurrentReq);
      } else {
        return next?.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }

}


// export class TokenInterceptorService {
//   refreshToken:any = null;
//   constructor(private inject: Injector, private authService: AuthService,private restService:RestService) { }
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
//     if (this.authService.getToken()) {
//       if(this.refreshToken==null) {
//         this.refreshToken= this.authService.getRefreshToken()
//       }
      
//       // excludng auth type Bearer for logout and refresh api call, we would send auth type Basic in auth.service file for logout and refresh api
//       if (req.url.includes('secure-auth/logout') !== true && req.url.includes('secure-auth/token') !== true) {
//         const copyCurrentReq = req.clone({
//           setHeaders: {
//             Authorization: `Bearer ${this.authService.getToken()}`  //token
//           }
//         });
//         return next.handle(copyCurrentReq);
//       } else {
//         return next?.handle(req);
//       }
//     } else {
      
//       return next.handle(req).pipe(
//         catchError(error => {
//           // Check if the error is due to unauthorized access
             
//              console.log("coming inside error token interceptor")
//             // Refresh the token
//             return this.authService.refreshToken(this.refreshToken).pipe(
//               switchMap((result: any) => {
//                 // Update the access tokens
//                 this.authService.setAccessTokens(result.data);
                
//                 // Retry the original request with the new token
//                 const newToken = this.authService.getToken();
//                 const newAuthReq = req.clone({
//                   setHeaders: {
//                     Authorization: `Bearer ${newToken}` // Set the new token
//                   }
//                 });
//                 return next.handle(newAuthReq);
//               }),
//               catchError(refreshError => {
//                 console.error('Error refreshing token:', refreshError);
//                 // Handle the refresh token error
//                 return throwError(refreshError);
//               })
//             );
          
//         })
//       );
      
//     }
    
//   }

// }