import { Injectable } from '@angular/core';
import { END_POINTS } from '../constants/endpoints';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Categoryoutput, Deviceindex, IAPIField, IdentifiedUnidentified, IDEVICELIST, IidentifiedUnidentifiedHeaderDropdown, INewScan, INewScanResponse, ISelectedRowDevices, ISnackBarData, ISSHField } from '../model/scan/scan';
import { IDialog } from '../model/Dialog.model';
import { IdentifiedUnidentifiedComponent } from '../../new-scan/components/identified-unidentified/identified-unidentified.component';
import { DialogService } from './dialog.service';
import { ITreeView } from '../components/filter/filter.model';
import { HttpEventType } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import * as _ from 'lodash';
import { IList } from '../components/list/list.model';
import { EncryptService } from './encrypt.service';
import { AuthService } from './auth.service';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class ScanService {
  shareNewScanInformation = new ReplaySubject<INewScanResponse>();
  shareFilesAfterUpload = new ReplaySubject<Array<File>>();
  fileUploadCount: number = 0;
  totalFileUpload: number = 0;
  private selectedItemSubject = new BehaviorSubject<any>('device');
  selectedItem$ = this.selectedItemSubject.asObservable();
  private displayDivSubject = new BehaviorSubject<boolean>(false);
  public displayDiv$ = this.displayDivSubject.asObservable();
  private ChangeDisplayNameSubject = new BehaviorSubject<any>('device');
  public changeDisplay$ = this.ChangeDisplayNameSubject.asObservable();
  private uploadedPercentage = new Subject<number>();
  private uploadingFiles: Set<string> = new Set<string>();
  private uploadingFilesSubject = new Subject<Set<string>>();
  uploadingFiles$: Observable<Set<string>> = this.uploadingFilesSubject.asObservable();
  private resetFormSubject = new Subject<void>();
  resetForm$ = this.resetFormSubject.asObservable();


  constructor(private restService: RestService,
    private responseService: ResponseService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    private encryptService: EncryptService,
    private authService: AuthService,
    private apiService:ApiService
   ) {
  }
  
  createRequestObject(req:any): any {
    
    


    const [timePart, ampm] = req.time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (ampm === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (ampm === 'AM' && hours === 12) {
      hours = 0;
    }
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    console.log(formattedTime);
    req.time=formattedTime;
   

    return req;
  }
  
  public credentialList(callback: Function,type:any){ 
    const url = `${END_POINTS.CREDENTIAL_LIST}?type=${type}`;
    this.restService.getApi(url).subscribe({

      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
   }

   public credentialTypes(callback: Function){
    this.restService.getApi(END_POINTS.CREDENTIAL_TYPES).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   }

   getRoles(callback: Function) {
    const roleReq: any = {
      "order_column": "name",
      "sort_by": "ASC"
    };
    this.restService.postApi(END_POINTS.ORG_ROLES, roleReq)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  loadUserList(callback: Function, req: any) {
    this.restService.postApi(END_POINTS.USER_MANAGEMENT_LIST, req)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    })
  }

  public getUploadedPercentageObservable() {
    return this.uploadedPercentage.asObservable();
  }

  public scanUploadFileData(files: Array<File>, callback?: any, sshUpload = false, isSaveSshAddress = false, name = '',  save?: boolean, event_id: any = null, vendorId: string = "",sshMulti?:any) {
    if(sshMulti){
    if (sshMulti.loginParameter === 'password') {
      delete sshMulti.privateFile;
    } else if (sshMulti.loginParameter === 'privatekey') {
      delete sshMulti.password;
    } else {
      delete sshMulti.password;
      delete sshMulti.privateFile;
    }
  }

  if(sshMulti){
    if(sshMulti.timzone){
      delete sshMulti.timzone;
    }
    if(sshMulti.timezone){
      delete sshMulti.timezone
    }
  }
  
  if(sshMulti !== undefined && sshMulti.new_cred){
    sshMulti = Object.assign(sshMulti, {
      password: this.encryptService.doEncryptLogin(sshMulti.password),
      username: this.encryptService.doEncryptLogin(sshMulti.username),
    })
    if(sshMulti.enable_password){
      sshMulti = Object.assign(sshMulti, {
        enable_password: this.encryptService.doEncryptLogin(sshMulti.enable_password)
      })
    }
  }

    let prepareReq: any = this.scanReq(files, name, save, event_id, vendorId,sshMulti);
    //console.log(prepareReq);
    const endpoint = !sshUpload ? END_POINTS.FILE_UPLOAD_TEMP : END_POINTS.SSH_UPLOAD;
    // Add the names of the files being uploaded to the set
    files.forEach((file) => this.uploadingFiles.add(file.name));

    // Emit the current uploadingFiles set to subscribers
    this.uploadingFilesSubject.next(this.uploadingFiles);
    this.restService.postApiFileUpload(endpoint, prepareReq).subscribe({
      next: (response) => {
        this.fileUploadApiEvent(response, callback);
      },
      error: (err) => {
        this.spinnerService.hide();
        this.fileUploadErrorEvent(err, callback);
      },
    })
  }

  public fileUploadErrorEvent(err: any, callBack: Function) {
    
    const updatedErr = this.responseService.errorResponse(err);
  
    // Update upload percentage to indicate error
    // this.uploadedPercentage.next(-1);
  
    // Remove the names of the files that encountered errors from the set
    this.uploadingFiles.clear();
  
    // Emit the current uploadingFiles set to subscribers
    this.uploadingFilesSubject.next(this.uploadingFiles);
  
    callBack(updatedErr);
  }

  setSelectedItem(item: any) {
    this.selectedItemSubject.next(item);
  }
  public fileUploadApiEvent(response: any, callBack: Function) {
    let uploadedPercentage = 0;
    switch (response.type) {
      case HttpEventType.Sent:
        this.uploadedPercentage.next(0)

        break;
      case HttpEventType.UploadProgress:
        if (response && response?.total) {
          this.uploadedPercentage.next(Math.round(response.loaded / response.total * 100));
        }
        break;
      case HttpEventType.Response:
        const updatedRes = this.responseService.successResponse(response);
        this.uploadedPercentage.next(100);
        // Remove the names of the files that have finished uploading from the set
        this.uploadingFiles.clear();

        // Emit the current uploadingFiles set to subscribers
        this.uploadingFilesSubject.next(this.uploadingFiles);
        callBack(updatedRes);
        break;
    }
  }

  public updateDisplayDiv(show: boolean): void {
    this.displayDivSubject.next(show);
  }

  public updateName(name: string): void {
    this.ChangeDisplayNameSubject.next(name);
  }

  public scanDownloadFileData(callBack?: Function) {
    const endpoint = END_POINTS.FILE_DOWNLOAD;
    this.restService.getApiDownload(endpoint).subscribe({
      next: (response) => {
        if (callBack) {
          callBack(this.responseService.successResponse(response));
        }
      },
      error: (err) => {
        this.responseService.errorResponse(err);
      }
    })
  }

  public fileuploadEvent(fileRequest: any, callBack?: any) {
    const isNewUserDetails = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
    if(isNewUserDetails?.device_count === 0){
      isNewUserDetails.isAddOrDeleteAction = true;
      this.authService.setCookie('isNewUserDetails', JSON.stringify(isNewUserDetails || {}));
    }
    const endpoint = END_POINTS.FILE_UPLOAD_PROCESS;
    this.restService.postApiFileUpload(endpoint, fileRequest).subscribe({
      next: (response) => {

        this.submitUploadApiEvent(response, callBack);

      },
      error: (err) => {
        this.spinnerService.hide();
        //const errCode = this.encryptService.doDecrypt(err.error)
        
        if(err) {
          const errCode = this.responseService.errorFileupload(err)
          if(errCode.data){
            const unscannedItem = errCode.data.find((item: { unscanned_files: any; }) => typeof item.unscanned_files !== 'undefined');
            if (unscannedItem && unscannedItem.unscanned_files.length > 0) {
              callBack(errCode);
            }
          } else {
            const errCode = this.responseService.errorResponse(err);
            callBack(errCode);
          }
          
        }
      }
    })
  }

  public submitUploadApiEvent(response: any, callBack: Function) {
    let uploadedPercentage = 0;
    switch (response.type) {
      case HttpEventType.Sent:
        this.spinnerService.show('0%');
        break;
      case HttpEventType.UploadProgress:
        if (response && response?.total) {
          uploadedPercentage = uploadedPercentage || 0;
          uploadedPercentage = Math.round(response.loaded / response.total * 100);
          //console.log("sfsdf>>>>>>>>>>>>>>")
          //console.log(uploadedPercentage);
          this.spinnerService.show(`${uploadedPercentage}% uploaded...`);
        }
        break;
      case HttpEventType.Response:
        const updatedRes = this.responseService.successResponse(response);

        callBack(updatedRes);
        break;
    }
  }


  public downloadFile(data: any) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'sample.csv';
    hiddenElement.click();
    hiddenElement.remove();
  }
  public fetchSshFieldData(sshField: ISSHField, callback?: any) {
    const isNewUserDetails = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
    if(isNewUserDetails?.device_count === 0){
      isNewUserDetails.isAddOrDeleteAction = true;
      this.authService.setCookie('isNewUserDetails', JSON.stringify(isNewUserDetails || {}));
    }
    if (sshField.loginParameter === 'password') {
      delete sshField.privateFile;
    } else if (sshField.loginParameter === 'privatekey') {
      delete sshField.password;
    } else {
      delete sshField.password;
      delete sshField.privateFile;
    }
    if (sshField && sshField.time) {
      let utcDate;
      if (sshField.date) {
        // Parse provided date and time
        const [day, month, year] = sshField.date.split('-').map(Number);
        const [hours, minutes] = sshField.time.split(':').map(Number);
        const localDate = new Date(year, month - 1, day, hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
        //utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
      } else {
        // Use current date if date is not provided
        const now = new Date();
        const [hours, minutes] = sshField.time.split(':').map(Number);
        const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
      }
  
      // Format date as "DD-MM-YYYY"
      const utcDay = String(utcDate.getDate()).padStart(2, '0');
      const utcMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
      const utcYear = utcDate.getFullYear();
      const formattedDate = `${utcDay}-${utcMonth}-${utcYear}`;
  
      // Format time as "HH:MM"
      const utcHours = String(utcDate.getHours()).padStart(2, '0');
      const utcMinutes = String(utcDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${utcHours}:${utcMinutes}`;
  
      sshField.date = formattedDate;
      sshField.time = formattedTime;
    }

    

    let payload: ISSHField = Object.assign(sshField, {
      host: this.encryptService.doEncryptLogin(sshField.host),
    })

    if(sshField.new_cred){
      payload = Object.assign(sshField, {
        password: this.encryptService.doEncryptLogin(sshField.password),
        username: this.encryptService.doEncryptLogin(sshField.username),
      })
      if(sshField.enable_password){
        sshField = Object.assign(sshField, {
          enable_password: this.encryptService.doEncryptLogin(sshField.enable_password)
        })
      }
    }


    this.restService.postApi(END_POINTS.SSH_FIELD, payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        const callbackData = (err.error && err.error.hasOwnProperty('data') && Array.isArray(err.error.data)) ? err.error.data : [];
        const errorResponse = callbackData ?
          (callbackData.find((item: { unscanned_files?: any }) => item.unscanned_files?.length > 0) ?
            err.error :
            this.responseService.errorResponse(err)) :
          this.responseService.errorResponse(err);
        callback(errorResponse);
      },
    })
  }
  public fatchShhUpload(files: Array<File>, callback?: any, name = '', save?: boolean, vendorId: string = "",sshMulti?: any) {
    this.scanUploadFileData([...files], callback, true, false, name, save, null, vendorId,sshMulti);
  }
  public fetchApiUpload(ApiFliedData: IAPIField, callback?: any) {
    const isNewUserDetails = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
    if(isNewUserDetails?.device_count === 0){
      isNewUserDetails.isAddOrDeleteAction = true;
      this.authService.setCookie('isNewUserDetails', JSON.stringify(isNewUserDetails || {}));
    }
    if (ApiFliedData && ApiFliedData.time) {
      let utcDate;
      if (ApiFliedData.date) {
        // Parse provided date and time
        const [day, month, year] = ApiFliedData.date.split('-').map(Number);
        const [hours, minutes] = ApiFliedData.time.split(':').map(Number);
        const localDate = new Date(year, month - 1, day, hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
        //utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
      } else {
        // Use current date if date is not provided
        const now = new Date();
        const [hours, minutes] = ApiFliedData.time.split(':').map(Number);
        const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
      }
  
      // Format date as "DD-MM-YYYY"
      const utcDay = String(utcDate.getDate()).padStart(2, '0');
      const utcMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
      const utcYear = utcDate.getFullYear();
      const formattedDate = `${utcDay}-${utcMonth}-${utcYear}`;
  
      // Format time as "HH:MM"
      const utcHours = String(utcDate.getHours()).padStart(2, '0');
      const utcMinutes = String(utcDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${utcHours}:${utcMinutes}`;
  
      ApiFliedData.date = formattedDate;
      ApiFliedData.time = formattedTime;
    }
    let payload: IAPIField = Object.assign(ApiFliedData, {
      host: this.encryptService.doEncryptLogin(ApiFliedData.host),
    })
    if(ApiFliedData.api_token){
      payload = Object.assign(ApiFliedData, {
        api_token: this.encryptService.doEncryptLogin(ApiFliedData.api_token),
      })
    }
    
    this.restService.postApi(END_POINTS.API_FIELD, payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        const callbackData = (err.error && err.error.hasOwnProperty('data') && Array.isArray(err.error.data)) ? err.error.data : [];
        const errorResponse = callbackData ?
          (callbackData.find((item: { unscanned_files?: any }) => item.unscanned_files?.length > 0) ?
            err.error :
            this.responseService.errorResponse(err)) :
          this.responseService.errorResponse(err);
        callback(errorResponse);
      },
    })
  }
  //Load undentified/identified component
  public popUpForIdentifiedAndUnidentified(files: File[], callBackF?: Function): void {
    const payload: IDialog = {};
    payload.component = IdentifiedUnidentifiedComponent;
    payload.width = '1100px';
    payload.class = 'main-identified-unidentified';
    payload.buttons = [{ 'label': 'Submit', callBack: callBackF }];
    this.dialogService.openDialog(payload);
    this.shareFilesAfterUpload.next(files);
  }
  public loadUnidentifiedIdentified(files: File[], sucessCallBack: Function, errorCallBack: Function) {
    let prepareReqArray = [];
    let filesDetails = [...files];
    while (filesDetails.length > 0) {
      const prepareReq: FormData = this.scanReq(filesDetails.splice(0, 2));
      this.restService.postApi('test', prepareReq).subscribe({
        next: (response) => {
          sucessCallBack(this.responseService.successResponse(response), prepareReq);
        },
        error: (err) => {
          errorCallBack({}, prepareReq);
        },
      });
    }
  }
  // update undentified/identified table
  public applySelectedValueFromDropdown(currentData: IdentifiedUnidentified[],
    selectedRows: IdentifiedUnidentified[],
    selectedDropdpwn: IidentifiedUnidentifiedHeaderDropdown,
    selectedRegion: string) {
    selectedRows?.forEach((selectedRow: IdentifiedUnidentified) => {
      currentData?.forEach((existingRow: IdentifiedUnidentified) => {
        if (selectedRow.fileName === existingRow.fileName) {
          selectedRow.vendor = selectedDropdpwn?.vendor;
          selectedRow.device = selectedDropdpwn?.device;
          selectedRow.region = selectedRegion;
        }
      })
    })
  }
  // Delete selected rows
  public deleteSelectedRow(currentData: IdentifiedUnidentified[],
    selectedRows: IdentifiedUnidentified[]): IdentifiedUnidentified[] {
    let updatedData: IdentifiedUnidentified[] = [];
    updatedData = currentData.filter((existingRow) => {
      return !selectedRows.find((selectedRow: IdentifiedUnidentified) => {
        return selectedRow.fileName === existingRow.fileName;
      })
    })
    return updatedData;
  }

  // prepare scan page Reqest
  private scanReq(files: Array<File>, name = '', save?: any, event_id = null, vendorId: string = "",sshData?:any): FormData {
    const formData = new FormData();
    [...files].forEach((item) => {
      formData.append("resumes", item, item.name);
    })
    if (name !== '') {
      formData.append("scanname", name);
    }
    if (event_id != null) {
      formData.append("event_id", event_id)
    }
    if (vendorId) {
      formData.append("vendor_key", vendorId);
    }
    if (save !== undefined && (!sshData || !sshData.hasOwnProperty('save'))) {
      formData.append("save", save.toString());
    }
    if (sshData && sshData.time) {
      let utcDate;
      if (sshData.date) {
        // Parse provided date and time
        const [day, month, year] = sshData.date.split('-').map(Number);
        const [hours, minutes] = sshData.time.split(':').map(Number);
        const localDate = new Date(year, month - 1, day, hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
        //utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
      } else {
        // Use current date if date is not provided
        const now = new Date();
        const [hours, minutes] = sshData.time.split(':').map(Number);
        const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
        utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
        );
      }
  
      // Format date as "DD-MM-YYYY"
      const utcDay = String(utcDate.getDate()).padStart(2, '0');
      const utcMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
      const utcYear = utcDate.getFullYear();
      const formattedDate = `${utcDay}-${utcMonth}-${utcYear}`;
  
      // Format time as "HH:MM"
      const utcHours = String(utcDate.getHours()).padStart(2, '0');
      const utcMinutes = String(utcDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${utcHours}:${utcMinutes}`;
  
      sshData.date = formattedDate;
      sshData.time = formattedTime;
    }

    if (sshData) {
      Object.keys(sshData).forEach(key => {
        formData.append(key, sshData[key]);
      });
    }
    return formData;
  }
  public sortArrayBasedPropertyValue(values: string[], mainArray: Categoryoutput[]) {
    const sortedArray: Categoryoutput[] = [];
    values?.forEach((value) => {
      mainArray?.forEach((cat: Categoryoutput) => {
        if (cat.category === value) {
          sortedArray.push(cat);
        }
      })
    })
    return sortedArray;
  }

  public loadRegionList(callBack: Function) {
    this.restService.getApi(END_POINTS.GLOBAL_DASHBOARD_REGION).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }
  fetchItemDetail(selectedRow: IList, scanId: string,
    deviceList: IDEVICELIST[] | undefined, callback: Function) {
    const updatedDeviceList: any[] = [];
    deviceList?.forEach((device: IDEVICELIST) => {
      updatedDeviceList.push({ id: device.device_id })
    })
    const req: any = {
      parameter_id: selectedRow.id,
      scan_summary_id: scanId
    };
    if (updatedDeviceList && updatedDeviceList.length) {
      req.data = updatedDeviceList;
    }
    this.restService.postApi(END_POINTS.SCAN_LIST_ROW_DETAILS, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        this.spinnerService.hide();
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  updateWhiteListItem(itemLists: ISelectedRowDevices[], callback: Function, comments: any) {
    const itemListsCopy = _.cloneDeep(itemLists);
    itemListsCopy?.forEach((item: ISelectedRowDevices) => {
      delete item.passed_value;
      delete item.device_category;
      delete item.vendor_category;
      delete item.vendor_category;
    })
    const req = {
      devices_ids: itemListsCopy,
      comments: comments
    }
    this.restService.postApi(END_POINTS.WHITE_LIST, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  public getDeviceParams(payload: any, callBack: Function) {
    this.restService.postApi(END_POINTS.SECURITY_DEVICE_PARAMS, payload).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }
  public removePropsSelectedAndIndterMinateProps(filter: ITreeView[]) {
    filter.map(item => {
      if (('data' in item)) {
        delete item.indeterminate;
        delete item.selected;
        this.removePropsSelectedAndIndterMinateProps(item.data || []);
      } else {
        delete item.indeterminate;
        delete item.selected;
      }
    })
  }

  triggerFormReset(): void {
    this.resetFormSubject.next();
  }
  public loadVendorList(callBack: Function) {
    this.restService.getApi(END_POINTS.VENDOR_LIST).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }

  public loadScheduleFrequency(callBack: Function) {
    this.restService.getApi(END_POINTS.SCHEDULE_FREQUENCY).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }
  public fetchSelectedScanDeviceList(req: any, callback: Function) {
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    //req.type = 1;
    apiDetails.req = req;
    apiDetails.url = END_POINTS.SCAN_DEVICE_LIST;
    this.apiService.callAPI(apiDetails);
    // this.restService.postApi(END_POINTS.SCAN_DEVICE_LIST, req).subscribe({
    //   next: (response) => {
    //     callback(this.responseService.successResponse(response));
    //   },
    //   error: (err) => {
    //     callback(this.responseService.errorResponse(err));
    //   },
    // })
  }
  
}
