import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuardService } from './modules/shared/services/role-guard.service';
import { SsoLoginComponent } from './modules/login/components/sso-login/sso-login.component';
import { NotFoundPageComponent } from './modules/shared/components/not-found-page/not-found-page.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    title: 'SparkUI',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [RoleGuardService],
    title: 'DashBoard',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
    title: 'SparkUI',
  },
  {
    path: 'sso/login',
    component: SsoLoginComponent,
    title: 'SparkUI'
  }, {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [RoleGuardService],
    title: 'SparkUI',
  },
  {
    path: 'super-admin',
    loadChildren: () =>
      import('./modules/super-admin/super-admin.module').then((m) => m.SuperAdminModule),
    canActivate: [RoleGuardService]
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    title: 'SparkUI',
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
