import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../components/custom-snackbar/custom-snackbar.component';
import { ISnackBar } from '../model/ISnackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  getSnackBar () {
    return this.snackBar;
  }

  showSnackBar = (response: ISnackBar) => { 
    if (response.autoDismiss) {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {data: response, duration: response.duration ? response.duration : 1000});
    } else {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {data: response})
    }
  }
}
