import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ILoaderData, SpinnerService } from 'src/app/modules/shared/services/spinner.service';
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  loader:ILoaderData = {};
  loaderSubscription: Subscription;
  constructor(private spinnerService: SpinnerService) { 
    this.loaderSubscription = this.spinnerService.isLoading.subscribe((details: ILoaderData)=>{
      this.loader = details;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
  }

}
