import { Injectable } from '@angular/core';
import * as forge from 'node-forge';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  constructor(public commonService: CommonService) {

  }
  public CLIENT_ID = 'd48iif4qdPzyoS8yZ0DqPQmBNIjD2Cc3wXWSaqxO';
  public CLIENT_SECRET = 'FOhKlt5Msj92FIlnd8dfnBZQUHCCcHwKI5iQMIlpwX5twR57sNqRQuTWJEMX0q0a0SGFZgVtLqmx3m84NyDrqzwKTke4o1UV7q8Zj9jXI1j8Jr6Iwj6KpAu4yytBfoV7';
  //fe using public 1 and private 2

  private publicLoginKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1Yh/FfeIechULUltM5mV
  2TRXcSTJq68AJLn2X6Sd9ktZYLwQhX7GDNErs32mhA59LfI8O4nJE8OpO4hw3M4X
  1a++rYVuKYJLMm13QHf+DrKxrFDmPkdsHa5L+txrb7VCU1WAOnbXNE3aV8CJlXOm
  5K877/z7eQ7Nw5Yt2yQbiwjY/VQvKCM1mrFbd7m4Zm/Q0sLuVieVEodpwpK5d3O5
  +XxlsSOkngIxv0mU2A31Zqe5sbJ9xt7CykG6n6Zqoppj9QEOH26/hz3+nY2eGhBh
  xGbsUCNa9FOqLVon6cmkHF13FgoVnkYC/FoAFHwv9Y0GHVEf2LN1/iPvbTjVIn36
  SQIDAQAB
  -----END PUBLIC KEY-----`;

  private publicKey: any = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAp7vLiWD+yCp1U4iInGYE
  IaaU1owKLf2szb5ApBPbd1qbHK4ixDW0EY3NVgzg53o7NkuEknBHeg6thaIetOM4
  cqurkIxIiaDzYN+dYvWjgWhmxI24gH8kFjfkuvhD1K4AGsLsmpb449vlMVr0O2+U
  E0gznfvS7E8ENTeMnFz5cY4Ru3MpsAzmsNmf1BN05ET3yFwXCjN50K/uE7qzRbFo
  3rg0ShCYzhVd1PvE1rx1zS4bedPUJkNxh1n0x+7JaOG1fEGQ1sVowtqy/bycWfBw
  ZK5OwDfAqyNDdVgcn687of+H3JoI+tfPdWug9jEuLJwz6yj+DatrGPOIhz6YNmEJ
  owIDAQAB
  -----END PUBLIC KEY-----`;

  private privateKey: any = `-----BEGIN RSA PRIVATE KEY-----
  MIIEogIBAAKCAQEAnlq2tMT8qjAziIlX8wcw+1wVA4FVuxhoYxP5r9cvm+0xOM89
  VkPwrikrQiPYRw03LYfEYJ0R1jjuPlixBcnVqOwGqS0Yw2eOThYkUL7m+RwbWB+J
  Z73QFhJHZJhaIDm9vM16kwzCV7VXae/L6yt8Vs3laPJkuNVxDs0qHd9W/fvI2Ddd
  4vQm7SHpcqdZkfhn8gdQX7IXoQKIcaW9iAodQH9pKpfxNsV4xb7BmLpBh7SBfI7+
  mDZGnu/FfFCTmOtfwWlAGbhnu2RqR5wTcDjYWT8K2yfzeCafJ4b/cE+sRJXIes6E
  rGP5R8AOty62TpqppYpx6I6vA8V1bm5Hgvq1GwIDAQABAoIBABlP9HhFLALY9/Es
  IcKyFGp8MnW1elU7q7qj/5upK4M4BnqyEc1qHcembV5B/uPYoR3Q38X2cC/3OLSp
  ll5lABdjkcBVej8InMQu9jjGqIK1s0ns/JijIZHYSSYzfjs8JO/67nbL/tK9+iOa
  a6pYm08AKYVoMvZ3/n2jCPJDzZLafe+3mz8PhqRqohlWUJ+HMWiPy4kFg1vLPfz/
  KE/Lz0tVv7DJkkpc4fMSvmOiFw6uKnlvXQn/xgSLe5qUwRLa/gd/Slb5E3Tel4kv
  ND6lJrvoNikKJrJGMS67ZW5uqwjM5L2ua6pB6IU81kRhqzTMU8AjsIPdeJW9I3oW
  3VHB3jECgYEAxm1ecfRnrd3smM7S5UqrUfO7hAoXaOJwsSDjpQ2DPG8mP8sKyRPK
  xAsybxlMhyDoch59FM/v/m1PSwnXws4aXzMMfxn8RD1KWdrj1xXv3zdq83j8VZcW
  SYWGt4f8LrjIOcq2T2BoU5RNftEWOvtfIbSFG+i3tAg/6k9NbOaA7wsCgYEAzEzX
  bHacybQcpCWLbb7S6N0kXcwWODmCwaVjh0H0s2jwdsG+8Qx0ymfT2+cqP5DBH+rI
  h2jgVWxChInEo/OOQTmvBpTeeYE0Q12L5GHobcgb0BgSwRY6yoajPYvYX2AFexuX
  ABF4bMmIuPKto+IvbN618JVrkWNpMvzC5B5PXDECgYB105P1ju/R6qoHwyiwRzqc
  G2MJ8QQdn/jS9Xi4FeIMDORllSf+ZuZMq2lzV05+HyhoO3qC1Ubpifc/08RvRPub
  9DBDeSa3Tr3LxZLtsXUS01GK6hjMqj7twYBRk0pIZClhR+IiGFla6AFsgLP2qjW/
  SfaQsAwFt7BcbmLHe8isKQKBgAYIMsgaDHEMJ1hVX3ODEdkcRmmakAFi+VBcYL7q
  nJ37eDYZbFjTpFMhk6jHf4DW9kzJ8tfEqfL7uk8NfkfU9htI57kLBjKSNQfUKEA6
  /9DV+rQ/MbaPwHagWQuFKINQY3kKDIBNA8A6hxXm3GedUwck4DxVPRLS25VMzzhL
  CM7RAoGAdDnNUjVeLHz+Munhxy5plebuIA52/cN6fY6l9JF/47h/WtsTPpUaXkJw
  cpWHLQWQ/4K1Wm1tztDibT3nqJUt5rV1aZT63zw8zsqvk9nZa5g9vZaWepVBjOrx
  3nCzVRC/5JiJI6nNr+ZCgGIkFsKGTbYKoxRH+Uxd4ry8+W5kKIo=
  -----END RSA PRIVATE KEY-----`;

  doEncryptLogin(value:string | undefined = ''){
    const publicKey = forge.pki.publicKeyFromPem(this.publicLoginKey);
    var encrypted = publicKey.encrypt(value, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });
    return forge.util.encode64(encrypted);
  }

  public doEncrypt(value: string | any = '') {
    const publicKey = forge.pki.publicKeyFromPem(this.publicKey);
    const chunkSize = 190;
    let encryptedChunks = [];
    for (let i = 0; i < value.length; i += chunkSize) {
      const chunk = value.slice(i, i + chunkSize);
      const encryptedIndexData = publicKey.encrypt(chunk, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
      });
      encryptedChunks.push(encryptedIndexData);
    }
    const encryptedData = btoa(encryptedChunks.join(''));
    return encryptedData;
  }




  public doDecrypt(value: string | any = '') {
    const privateKey = forge.pki.privateKeyFromPem(this.privateKey);
    const chunkSize = 256;
    let decryptedMessage = '';
    const encryptedChunks = forge.util.decode64(value);
    for (let i = 0; i < encryptedChunks.length; i += chunkSize) {
      const encryptedChunk = encryptedChunks.slice(i, i + chunkSize);
      const decryptedChunk = privateKey.decrypt(encryptedChunk, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
      });
      decryptedMessage += decryptedChunk;
    }
    try {
      return decryptedMessage ? JSON.parse(decryptedMessage) : null;
    } catch (error) {
      console.error('Error parsing decrypted JSON:', error);
      return null; // or handle the error as needed
    }
  }

  public splitStringIntoChunks(text: string, chunkSize: number) {
    const words = text; // Split the string into words
    const result = [];
    for (let i = 0; i < words.length; i += chunkSize) {
      var chunk = words.slice(i, i + chunkSize); // Get a chunk of words
      result.push(chunk); // Join the chunk into a string and add to result array
    }
    return result;
  }
}