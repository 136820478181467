import { Component, Inject } from '@angular/core';
import { CustomDailog } from '../../model/Dialog.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { IUserDetail } from '../../model/profile/profile';
@Component({
  selector: 'app-trail-message',
  templateUrl: './trail-message.component.html',
  styleUrls: ['./trail-message.component.scss']
})
export class TrailMessageComponent {
  userProfile!: IUserDetail;
  subscription_name!:string
  isNewUser: any = {};
  formattedDate:string='';
  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog,
  private profileService: ProfileService,
public authService:AuthService) {
  this.profileService.fetchUserDetails(this.userProfileCallback);
  
}

ngOnInit(): void {
  this.isNewUser = this.data.displayFlag
}
 
  public userProfileCallback = (results: IUserDetail) => {
    this.userProfile = results;
    const subscriptionNames = new Map<string, string>([
      ['free', 'free trial'],
      ['platinum', 'platinum license'],
      ['gold', 'gold license'],
      ['diamond', 'diamond license']
    ]);
    
    const subscriptionCode = this.userProfile.subscription_code;
    this.subscription_name = subscriptionCode && subscriptionNames.has(subscriptionCode) ? subscriptionNames.get(subscriptionCode)! : 'unknown subscription';
    const expiryDate = new Date(this.userProfile.expiry_date);
  expiryDate.setDate(expiryDate.getDate() + 14);
  const formattedDate = `${String(expiryDate.getDate()).padStart(2, '0')}/${String(expiryDate.getMonth() + 1).padStart(2, '0')}/${expiryDate.getFullYear()}`;
  this.formattedDate=formattedDate;
  console.log('Formatted Date:', formattedDate);
    
    
    //this.subscription_name = this.userProfile.subscription_name || ''; // Use empty string as default value
 
    //this.unique_devices = this.userProfile?.subscription_features?.unique_devices;
  }

  onClose(callBack: Function | undefined,label?: string | undefined) {
      if (callBack) {
        
          callBack();
          if(label == 'Sign Out'){
            this.logout();
          }
      }
  }

  logout() {
    this.profileService.reset();
    this.authService.logoutUser().subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.authService.logOut(true, true, true);
          //this.authService.userLoggedOutSubject.next();
        }
      },
      error: (error) => {
        console.error('Error during logout:', error);
        this.authService.logOut(true, true, true);
        //this.authService.userLoggedOutSubject.next();
        // Handle the error as needed, such as displaying a message to the user
      }
    });
  }

}
