<div class="terms-condition-container">
    <div class="header-session">
        <h4 class="header">Privacy Policy</h4>
        <button mat-button [mat-dialog-close]="true">
                <mat-icon>close</mat-icon></button>
    </div>
    <div class="modal-content scroll">

<div>
<div >
    <p >Verastel, Inc., a Delaware corporation, and its affiliates (hereinafter referred to as the “Company,” “us,” “we,” and “our”) is firmly committed to respecting your privacy and recognizing your need for appropriate protection and management of personally identifiable information you share with us. This Privacy Policy describes our practices in connection with information that we collect through activities that link to this Privacy Policy including website ( “Website”), through other services that we may offer in connections with our Website, as well as through email messages that we send to you. By providing Personal Information to us, you agree to the terms and conditions of this Privacy Policy.</p>
<div>
<p class="side-content">This Privacy Policy explains:</p>
<div class="list-content">
<ul>
<ul class="a">
<li>The types of information we collect and use</li>
<li>How we use and share such information</li>
<li>The security of your information</li>
<li>Your choices regarding our use of your information</li>
<li>Your ability to review and correct your information</li>
<li>Your ability to opt-out of data collection or delete such information</li>
</ul>
</ul>
</div>
<p>By accessing and using the Website, you consent to the collection and use of the personally identifiable information you share with us in accordance with this Privacy Policy.</p>
<p>“Personally Identifiable Information” and “Personal Information” mean any information by which you, individually, can be identified, such as your name, address, telephone number, user name, postal address (including billing and shipping addresses), telephone numbers, email addresses, country of residence, location data, profile picture, password, credit card information (if you transact business with us), and an online identifier of the natural person. It may also include information that you provide to us through our Website or which you authorize third parties to provide to us through our Website.</p>
</div>
<div>
<h6>Revisions to this Policy</h6>

<p>The Company reserves the right to modify the terms of this Privacy Policy at any time and in its sole discretion to reflect changes in our practices, technology, legal requirements, and other factors. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the Website. We therefore encourage you to review this Privacy Policy from time to time.</p>

<p>YOUR USE OR CONTINUED USE OF THE WEBSITE FOLLOWING OUR POSTING OF A CHANGE NOTICE WILL CONSTITUTE BINDING ACCEPTANCE OF THIS POLICY AND ALL APPLICABLE CHANGES.</p>
</div>
<div class="list-content">
<h6>EU General Data Protection Regulation (“GDPR”) Notices</h6>

<p>We will only collect and process Personal Information about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you), and “legitimate interests”. Where we rely on your consent to process Personal Information, you have the right to withdraw or decline your consent at any time, and where we rely on legitimate interests, you have the right to object. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact us at <a href="mailto:info@example.com">info@example.com</a>.</p>

<p  class="side-content">Users within the EU may email us at <a href="mailto:gdpr@example.com">gdpr@example.com</a> in order to exercise their GDPR rights to:</p>

<ul class="a">
    <li>Access, review, restrict processing of, or otherwise request erasure of your Personal Information;</li>
    <li>Obtain the identity of the source of any Personal Information collected;</li>
    <li>Request correction of any errors contained within your Personal Information;</li>
    <li>Request to transfer your Personal Information to another service provider;</li>
    <li>Object to the manner in which your Personal Information is processed; or</li>
    <li>Lodge a complaint with a supervisory authority.</li>
</ul>
<p>Where we rely on your consent to collect Personal Information, you may withdraw your consent through the contact information contained within this Section.</p>
<p>For all GDPR-based requests made pursuant to this section, we will (a) respond as required under applicable law, (b) provide a copy of any requested Personal Information in a structured, commonly used and machine-readable format, and (c) transmit such Personal Information to another service provider without restriction in accordance with applicable law.</p>
</div>
<div>
<h6>General Anonymity</h6>
<p>Individual users who visit the Website will remain anonymous unless (1) they voluntarily tell us who they are, and organizational users visiting the Website will remain unidentified in our records, except in circumstances where (1) the entity, through its authorized representatives, voluntarily discloses its identity to us, or (2) an individual such as a network security professional, IT consultant, or an employee authorized to handle data privacy and security matters, voluntarily communicates the entity's identity to us as part of the service engagement.</p>
<p>We may track the Internet domain address from which people visit the Website and analyze these data for trends and statistics regarding use of our Website.</p>
</div>
<div>
<h6>Other Information</h6>
<p>The term “Other Information” reflects any information that does not reveal your specific identity, such as: demographic information, high-level location information, such as the browser language and country during the user session. We may collect Other Information when you voluntarily provide such information to us, such as through survey responses. Such information constitutes Other Information because, unless combined with your name or other Personal Information, it does not personally identify you or any other user. We may use and disclose Other Information for any purpose, except where we are required to do otherwise under applicable law. If we are required to treat Other Information as Personal Information under applicable law, then we may use and disclose Other Information for all the purposes for which we use and disclose Personal Information</p>
<p>Additionally, we may aggregate Personal Information in such a manner that the end product does not personally identify you or any other user of the Services. For example, we might use Personal Information to calculate the percentage of our users who live in a particular state. Such aggregate information is considered Non-Personal Information for purposes of this Policy.</p>
</div>
<div>
<h6>Collection of Personal Information</h6>
<p>Sometimes (e.g., when you purchase or order a product or service) we ask you to provide information that is considered Personal Information.</p>
<p>We also maintain a record of your online purchases and we may ask you for confirmation of such Personal Information when you ask us for or download information. Please note that we do not store any credit card information and any purchases may be processed through a third-party processor.</p>
<p>Whether or not you choose to provide the information we request is entirely up to you. But if you choose not to provide the information we request, you may be unable to purchase products or services, or access certain services, offers, and content on the Website.</p>
</div>
<div class="list-content">
<h6>Use of Personal Information</h6>
<p  class="side-content">Your Personal Information enables us to:</p>
<ul class="a">
    <li>Fulfill your purchases</li>
    <li>Provide information you have requested</li>
    <li>Provide information about future products and services that may suit your particular interests</li>
    <li>Help identify you if you lose your userid or password</li>
    <li>Help you find information on the Website</li>
    <li>Provide features, functions, and benefits of services that you subscribe to</li>
    <li>Enhance, improve, add to, and further develop services we offer (e.g., creating new features or functions; refining or personalizing the user experience; increasing service technical performance)</li>
    <li>Analyze trends</li>
    <li>Track your activities on the website</li>
    <li>Otherwise gather information about individual users of our products, services, and market segments</li>
    <li>Obtain consumer satisfaction ratings and product feedback information</li>
</ul>
<div class="list-content">
    <h6>Sharing of Personal Information with Partners and Third Parties</h6>
    <p  class="side-content">We may share your Personal Information with our partners, independent contractors, service providers, and other third-parties for purposes of:</p>
    <ul class="a">
        <li>Providing services such as processing credit card transactions, website hosting, data analysis, order fulfillment, infrastructure provision, IT services, email delivery services, auditing services, and other services, to enable them to provide services.</li>
        <li>To a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).</li>
        <li>As we believe to be necessary or appropriate:
            <div class="list-content">
            <ol type="d">
                <li>Under applicable law, including laws outside your country of residence.</li>
                <li>To comply with legal process.</li>
                <li>To respond to requests from public and government authorities, including public and government authorities outside your country of residence.</li>
                <li>To enforce our Terms and Conditions of Use.</li>
                <li>To protect our operations or those of any of our affiliates.</li>
                <li>To protect our rights, privacy, safety, or property, and/or that of our affiliates, you or others.</li>
                <li>To allow us to pursue available remedies or limit the damages that we may sustain.</li>
            </ol>
        </div>
        </li>
    </ul>
</div>
</div>
<div class="list-content">
<h6>Cookies</h6>
<p>Our Website may use cookie and similar tracking technology (e.g., web beacons) to improve the quality of your experience. Cookies are small files sent by a Website and saved on your hard disk. Cookies store information that a Website may need in order to personalize your experience and gather Website statistical data.</p>
<p  class="side-content">Any time you visit our Website, we may collect and store the following:</p>
<ul class="a">
<li>Name of the domain and host from which you access the Internet (for example, msn.com, aol.com)</li>
<li>Internet protocol (IP) address of the computer you are using</li>
<li>Browser software you use and your operating system</li>
<li>Date and time you access the Website</li>
<li>Internet address of the Website from which you linked directly to the Website</li>
</ul>
<p>We use this information to measure the number of visitors to areas of the Website and to help us make the Website more useful and interesting to our visitors. We use information from cookies as a whole to analyze for trends and statistics and marketing purposes; we do not track individuals, only instances of entry onto the Website</p>
<p>To avoid cookie downloads, see the “Avoiding Cookie Downloads” section of this Privacy Policy.</p>
</div>
<div>
<h6>Third-Party Hosting</h6>
<p>The Company contracts with a third party to provide servers that maintain and host the Website. Therefore, any information you submit, including Personal Information, will be placed and stored on a computer server maintained by this third-party host. The third party has agreed to implement technology and security features and strict policy guidelines to safeguard the privacy of your Personal Information from unauthorized access or improper use</p>
</div>
<div>
<h6>Site Technologies</h6>
<p>The Company does not provide the technologies used to build the Website, and therefore, neither recommends nor endorses the same. Any information regarding identified technologies, including their capabilities, limitations, and applications, should be sought directly from their manufacturers. The Company hereby disclaims any rights to trademarks, service marks, trade names, logos, copyrights, patents, domain names, or other intellectual property interests of third parties</p>
</div>
<div>
<h6>Links to Other Websites</h6>
<p>Our Website may contain links to other websites which are not owned and operated by the Company. Your use of these other linked websites is subject to their respective terms of use and privacy policies. The Company has no control over these linked websites and no responsibility or liability whatsoever for the content or privacy practices of these linked websites.</p>
</div>
<div>
<h6>International Visitors to the Website</h6>
<p>
    Our Website is operated and managed on servers located and operated within the United States or any country where we provide services. By visiting and using our Website, residents and citizens of countries and jurisdictions outside the United States understand and consent to the transfer, processing, and sharing of your information to a company in the United States. You understand that the privacy laws of the United States may not be as comprehensive as those in your country or jurisdiction, and you agree and consent to the transfer, processing, and storage of your Personal Information.
</p>
</div>
<div>
<h6>Security</h6>
<p>We are committed to safeguarding your Personal Information and content. We use a variety of industry-standard, reasonable security technologies and procedures to protect your Personal Information.</p>
<p>Please be advised, however, that although we have endeavored to create a secure and reliable website for you, the confidentiality of any communication or material transmitted to/from this Website cannot be guaranteed. We urge you to exercise caution when conveying user-identifiable information (including any Personal Information) over the Internet. When disclosing any user-identifiable information, you should remain mindful of the fact that it is potentially accessible to the public and, consequently, may be collected and used by others without your consent. You should therefore consider carefully if you want to submit sensitive information that you would not want disclosed to the public. You should also recognize that your use of the Internet and the Website is solely at your own risk. You agree that we have no responsibility or liability for the security of user-identifiable information transmitted via the Website or via the Internet in general.</p>
</div>
<div>
<h6>Actions You Can Take</h6>
<p><i>Correcting/Updating Your Information</i></p>
<p>You may review and approve the information about you or your organization collected upon registration. If any of this information changes, or if you no longer wish to use the Website, we will correct, update, or remove the information that you have provided to us.</p>
<p><i>Avoiding Cookie Downloads</i></p>
<p>Some consumers may not know that cookies are being placed on their computers when they visit websites. If you want to know when this happens, or to prevent it from happening, you can set your browser to advise you when a website attempts to place a cookie on your computer.</p>
<p><i>Opting Out</i></p>  
<p>You may opt out of having your Personal Information shared for receiving product offers directly from us via e-mail or postal mail. Please clearly indicate that you request that we not disclose your Personal Information to affiliates and/or other third parties for their direct marketing purposes. Please note that changes may not be effective immediately. We will endeavor to comply with your request(s) as soon as reasonably practicable. Please also note that if you do opt-out of receiving marketing-related messages from us, we may still send you important administrative messages, and you cannot opt-out from receiving administrative messages.</p>  
<p>To do so, e-mail _______info@verastel.ai_______ identifying which communications you choose not to receive.</p>
<p>If you choose not to give us Personal Information, you can still access the Website; however, you will not be able to access certain areas of the Website or certain Services.</p>
</div>
<div>
<h6>Sensitive Information</h6>
<p>We ask that you not send us, and you not disclose, any sensitive Personal Information on or through the Website or otherwise to us.</p>
</div>
<div>
<h6>Retention Period</h6>
<p>We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
<p>We determine the appropriate retention period for Personal Information on the basis of the amount, nature, and sensitivity of your Personal Information, the potential risk of harm from unauthorized use or disclosure, and whether we can achieve the purposes of the processing through other means, as well as the applicable legal requirements (such as applicable statutes of limitation).</p>
<p>After expiry of the retention periods, your Personal Information will be deleted. If there is any information that we are unable, for technical reasons, to delete entirely from our systems, we will put in place appropriate measures to prevent any further use of the data.

</p>
</div>
<div>
<h6>Requesting a Disclosure (California Residents Only)</h6>
<p>If you are a California resident, California Civil Code Section 1798.83 permits our visitors who are California residents, and have an established business relationship with us, to request a notice disclosing the categories of Personal Information we have shared during the preceding calendar year with third parties for their direct marketing purposes. To submit a request, see the “Contacting Us” section of this Privacy Policy. Allow a minimum of 30 days for a response.</p>
<p>In addition, if you are a California resident under the age of 18 and a registered user to our services, California Business and Professions Code Section 22581 permits you to remove content or Personal Information you have publicly posted. If you wish to remove such content or Personal Information and you specify which content or Personal Information you wish to be removed, we will do so in accordance with applicable law. Please be aware that after such removal you will not be able to restore the removed content. In addition, such removal does not ensure complete or comprehensive removal of the content or Personal Information you have posted and that there may be circumstances in which the law does not require us to enable removal of content.</p>
</div>
<div>
<h6>Contacting Us</h6>
<p>If you have questions or wish to send us comments about this Privacy Policy, e-mail info@verastel.ai_ OR write us (using postal mail) at: 101 Arch Street, 8th Floor, Boston, MA 02110, USA _</p>
</div>
</div>
</div>