<table  *ngIf="dataSource" mat-table [dataSource]="dataSource" class="net-sec-table">
  <ng-container *ngFor="let col of columns; let i = index" [matColumnDef]="col">
    <ng-container *ngIf="col==='select'">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleRow() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null;currentSelection();"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container *ngIf="col!=='select'">
      <th mat-header-cell *matHeaderCellDef> <span *ngIf="columnLabel[i]">{{columnLabel[i]}}</span></th>
      <td mat-cell *matCellDef="let element">
         {{element[col]}}
         <mat-icon *ngIf="col===iconName" [ngClass]="iconName" aria-hidden="false" aria-label="icon" [fontIcon]="iconName" 
         (click)="iconEvent(element)">
         </mat-icon>
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns; sticky: isSticky"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;">
  </tr>
</table>