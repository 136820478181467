import { Component, ViewChild ,HostListener, Inject} from '@angular/core';
import { routes } from './app-routing.module'
import { AuthService } from './modules/shared/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { FilterService } from './modules/shared/services/filter.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredModalComponent } from './modules/shared/components/session-expired-modal/session-expired-modal.component';
import { Subscription } from 'rxjs';
import { ProfileService } from './modules/shared/services/profile.service';
import { IDialog } from './modules/shared/model/Dialog.model';
import { DialogService } from './modules/shared/services/dialog.service';
import { BrowserService } from './modules/shared/services/browser.service';
import { DOCUMENT } from '@angular/common';
import * as WebFont from 'webfontloader'
import { TrailMessageComponent } from './modules/shared/components/trail-message/trail-message.component';
import { AllPlansComponent } from './modules/shared/components/all-plans/all-plans.component';
import { SubscriptionRequestComponent } from './modules/shared/components/subscription-request/subscription-request.component';
import { DeleteDialogComponent } from './modules/shared/components/delete-dialog/delete-dialog.component';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ScheduleDialogComponent } from './modules/shared/components/schedule-dialog/schedule-dialog.component';
import { MultipleVendorPopupComponent } from './modules/new-scan/components/multiple-vendor-popup/multiple-vendor-popup.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'spark-ui';
  @ViewChild('globalFilter') globalFilter!: MatSidenav;
  @ViewChild('globalSearch')  globalSearch!: MatSidenav;
  @ViewChild('globalNotification')  globalNotification!: MatSidenav;
  @ViewChild('sideNavFilter') sideNavFilter!:MatSidenav;
  @ViewChild('accountFilter') accountFilter!:MatSidenav;
  @ViewChild('cveAssetsDetails') cveAssetsDetails!:MatSidenav;
  // private sessionTimeoutSubscription: Subscription | undefined;
  private _runTimer:any = null;
  idleState = 'Not started.';

  constructor(
    private authService: AuthService,
    private filterService: FilterService,
    private dialogService: DialogService,
    private bnIdle: BnNgIdleService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private browserService: BrowserService,
    @Inject(DOCUMENT) private document: Document,
    private idle: Idle,  
  ) {
    idle.setIdle(300);
    idle.setTimeout(5); 
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {this.idleState = 'No longer idle.'
    console.log("onIdleEnd",this.idleState)
  });
    idle.onTimeout.subscribe(() => this.handleTimeout());
    idle.onIdleStart.subscribe(() => {this.idleState = 'You\'ve gone idle!' 
    console.log("onIdleStart",this.idleState)});
  }
  routes = routes;
  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  ngOnInit(): void {
    if(this.isLoggedIn){
      routes[0].redirectTo = 'dashboard';
      this.reset()
    }
    // this.authService.userLoggedIn.subscribe(() => {
    //   this.reset()
    // });

    this.authService.userLoggedOut.subscribe(() => {
      this.closeAllModals();
      this.idle.stop();
    });

    this.authService.shouldStartTimer.subscribe(() => {
      this.runTokenTimer();
    });

    this.browserService.getBrowserClass();
    WebFont.load({
      custom: { families: ['Material Icons', 'Material Icons Outline'], },
    });
  
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    console.log("idleState",this.idleState)
  }

  handleTimeout() {
    this.idleState = 'Timed out!';
    console.log("Timed out!", this.idleState)
    this.openSessionExpiredModal(); 
    this.authService.logoutUser().subscribe((result) => {
      if (result.status === 200) {
        this.authService.userLoggedOutSubject.next();
      }
    })
    this.authService.logOut(false, false)
  }


  private runTokenTimer():void{
    const timer: number = this.authService.tokenValidTime;
    if(this._runTimer){
      clearTimeout(this._runTimer);
    }
    this._runTimer = setTimeout(() => {
        this.makeRefreshTokenAPI();
    }, timer);
  }

  makeRefreshTokenAPI() {
    this.authService.refreshToken().subscribe((result: any) => {
      this.authService.setAccessTokens(result.data);
    }, (err) => {
      console.log('Refresh Token API is Failed', err)
    });
  }


  public openSessionExpiredModal(): void{
    const payload: IDialog = {
      component:SessionExpiredModalComponent,
      width: '400px',
      data:{
        setCallbackFunction: (result:any) => {
           this.sessionCallback(result);
        }
      }
    }
    this.dialogService.openDialog(payload);
  }

  sessionCallback(result:any){
    if (result) {
      this.redirectToLoginPage();
    }
  }
  
  ngOnDestroy(): void {

  }

  redirectToLoginPage(): void {
    this.profileService.reset();
    this.authService.logoutUser().subscribe((result) => {
      if (result.status === 200) {
        this.authService.userLoggedOutSubject.next();
      }
    });
  this.authService.logOut();
  }
   
  ngAfterViewInit(): void {    
    if(this.globalFilter) {
      this.filterService.setSidenav(this.globalFilter, 'globalfilter');
    }
    if(this.globalSearch){
      this.filterService.setSidenav(this.globalSearch,'globalSearch');
    }
    if(this.sideNavFilter) {
      this.filterService.setSidenav(this.sideNavFilter,'sideNavFilter');
    }
    
    if(this.accountFilter) {
      this.filterService.setSidenav(this.accountFilter,'accountFilter');
    }
    if(this.globalNotification) {
      this.filterService.setSidenav(this.globalNotification,'globalNotification');
    }
    if(this.cveAssetsDetails) {
      this.filterService.setSidenav(this.cveAssetsDetails,'cveAssetsDetails');
    }
  }

  public closeAllModals(){
    this.dialog.openDialogs.forEach(dialogRef => {
      const componentInstance = dialogRef.componentInstance;
      if (!(componentInstance instanceof SessionExpiredModalComponent) && !(componentInstance instanceof TrailMessageComponent)&& !(componentInstance instanceof AllPlansComponent) 
        && !(componentInstance instanceof SubscriptionRequestComponent) && !(componentInstance instanceof DeleteDialogComponent)
        && !(componentInstance instanceof ScheduleDialogComponent)
        && !(componentInstance instanceof MultipleVendorPopupComponent)) {
        dialogRef.close();
      }
    });
  }
  @HostListener('document:keydown.escape', ['$event']) onEscapeKeydown(event: KeyboardEvent): void {
    this.handleEscapeKey();
    this.closeSidenav();
  }
  public handleEscapeKey(): void {
    this.closeAllModals();
  }

  closeSidenav() {
    this.sideNavFilter.close();
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: Event): void {
    const sideNavElement = document.querySelector('.global-filter-main11');
    const sideNavOpened = sideNavElement && sideNavElement.classList.contains('mat-drawer-container-has-open');
  
    if (sideNavOpened && event.target) {
      this.closeSidenav();
    }
  }

}
