import { Component, Inject } from '@angular/core';
import { CustomDailog } from '../../model/Dialog.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.scss']
})
export class DeleteDataComponent {
  userData: any;
  public profileData: any = {};
  deletedPercentage: Subscription = new Subscription;
  percentage: any = 0;
  isSuccessDelete:boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private profileService: ProfileService,
  public authService:AuthService) {
    
    this.deletedPercentage = this.profileService.getDeletedPercentageObservable().subscribe(
      percentage => {

        this.percentage = percentage;

      }
    );
    //this.profileService.deleteUserData(data.userDetails,this.deleteResponse);

  }
  

}
