<h5>Update Profile Image</h5>
<div mat-dialog-content class="scroll">
  <div class="row p-5">
    <div class="col-12">
      <image-cropper [imageFile]="data.selectedFile" [autoCrop]="false" [transform]="transform"></image-cropper>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end button-action-section">
  <button mat-button class="" (click)="onClose()">Close</button>
  <button mat-button class="btn btn-primary" (click)="croppedImage()">
    Apply
  </button>
</div>