<app-filter 
   *ngIf="regionGlobalFilterData.get('Region')?.length" 
   [title]="'Filter Hierarchy'" 
   [type]="'checkbox'"
   [filterColumnData]="regionGlobalFilterData"
   [filterSecondLevelData]="deviceGlobalFilterData"
   (selectedFiltersEvent)="fetchRegions($event)"
   [saveButtonRequired]="true"
   [clearButtonRequired]="true"
   [resetButtonRequired]="true"
   [isSingleLevel]="true"
   [isGroupLevel]="true"
   [saveBtnLabel]="'Apply'"
   [fetchBtnlebel]="'Fetch Devices'"
   [isFirstLevelApiCalled]="isFirstLevelApiCalled"
   [isSecondLevelApiCalled]="isSecondLevelApiCalled"
   [placeholder]="'Search Asset..'"
   (applyBasedOnSelectedData)="apply($event)" [isDividerHide]="false">
</app-filter>
<div class="item" *ngIf="!isFirstLevelApiCalled && !isSecondLevelApiCalled" >
   <ngx-skeleton-loader class="rhs-content-loader head" count="4"></ngx-skeleton-loader>
   <ngx-skeleton-loader class="rhs-content-loader global-search-loader" count="1"></ngx-skeleton-loader>
   <div class="global-loader-section">
      <div *ngIf="!isFirstLevelApiCalled">
         <ngx-skeleton-loader  class="rhs-content-loader checkbox-box-loader" *ngFor="let row of [].constructor(12)" count="2"></ngx-skeleton-loader>
      </div>
      <div *ngIf="!isSecondLevelApiCalled">
         <ngx-skeleton-loader  class="rhs-content-loader checkbox-box-loader" *ngFor="let row of [].constructor(12)" count="2"></ngx-skeleton-loader>
      </div>
   </div>
</div>