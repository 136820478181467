import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { CONFIG } from "../constants/config";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() public updateDataOnSocketNotify = new EventEmitter<any>();
  private _data: any =  {};
  public apiResponse: any = {};
  private isDashboardLoaderAction = new Subject<boolean | string>();
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document) { }

  onNavigateTopage(type: string, data: any){
    const obj: any = {
      'device': 'admin/asset-management',
      'whitelist': 'dashboard/acceptedrisk',
      'security': '/admin/security-information',
      'vendor': '/admin/security-information',
      'scan_summary': 'dashboard/scan-details'
    }
    this._data = data;
    this.router.navigate([obj[type]]);
  }

  setData(dt:any){
    this._data = dt;
  }

  getData(){
    return this._data;
  }

  clearData(){
    this._data = {};
  }

  toggleBodyClass(flag: boolean):void{
    if(flag){
      this.document.body.classList.add('header-stick');
    } else{
      this.document.body.classList.remove('header-stick');
    }
  }
  dispatchDashBoardLoaderActions(flag: boolean | string){
    this.isDashboardLoaderAction.next(flag);
  }

  getDashBoardLoaderActions(){
    return this.isDashboardLoaderAction.asObservable();
  }
  public clearAPIData(type: string){
    this.apiResponse[type] = null;
  }

  public setAPIData(type: string, response: any, isDataAvailable: boolean = false, isSearchData:boolean = false) {
    this.apiResponse[type] = {
      response: response,
      isDataAvailable: isDataAvailable,
      isSearchData: isSearchData
    };
  }
  public getAPIData(type: string): any {
     return this.apiResponse[type];
  }
  public setAPIEmptyData(){
    this.apiResponse={};

  }
  public updateApiData(socketData: any) {
    if(socketData && socketData?.data?.length) {
      socketData?.data?.forEach((moduleObj: any)=>{
        if(moduleObj && moduleObj.module && this.apiResponse &&
          this.apiResponse[moduleObj.module]
        ) {
          let currentData = [];
          if(this.apiResponse[moduleObj.module].response.data) {
            if(moduleObj.module === CONFIG.PAGE_API.SCAN_STATUS) {
              currentData = this.apiResponse[moduleObj.module].response.data?.scan_details || [];
              this.apiResponse[moduleObj.module].response.data.scan_details = this.currentModuleUpdatedData(CONFIG.UNIQUE_API_PROPS[moduleObj.module], moduleObj.module, currentData, socketData);
            }else if(moduleObj.module === CONFIG.PAGE_API.ASSETS_DETAILS) {
              currentData = this.apiResponse[moduleObj.module].response.data?.device_list || [];
              this.apiResponse[moduleObj.module].response.data.device_list = this.currentModuleUpdatedData(CONFIG.UNIQUE_API_PROPS[moduleObj.module], moduleObj.module, currentData, socketData);
            } else if(moduleObj.module === CONFIG.PAGE_API.MY_SCANS) {
              currentData = this.apiResponse[moduleObj.module].response.data || [];
              this.apiResponse[moduleObj.module].response.data = this.currentModuleUpdatedData(CONFIG.UNIQUE_API_PROPS[moduleObj.module], moduleObj.module, currentData, socketData);
            }
          }
        }
      })
    }
  }
  public currentModuleUpdatedData(uniqueProps: string, moduleName: string, currentData: any, socketData: any) {
    if(socketData && socketData?.data?.length) {
      socketData?.data?.forEach((moduleObj: any)=>{
        if(moduleObj && moduleObj.module === moduleName) {
           if(moduleObj.data && moduleObj.data.length) {
            moduleObj.data.forEach((socketObj: any)=>{
              const currentDataIndex = currentData?.findIndex((cDataObj: any)=>{
                return cDataObj[uniqueProps] === socketObj[uniqueProps];
              })
              if(currentDataIndex > -1) {
                currentData[currentDataIndex] = socketObj;
              } else {
                if(this.noNewRowAdd().indexOf(moduleName) === -1){
                  currentData.unshift(socketObj);
                }
              }
            })
           }
        }
      })
    }
    return currentData;
  }
  public noNewRowAdd() {
    return [CONFIG.PAGE_API.SCAN_STATUS]
  }
}
