import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class CustomDateAdapterService extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const [month, day, year] = value.split('/').map(val => parseInt(val, 10));

      if (year && month && day) {
        return new Date(year, month - 1, day);
      }
    }
    return super.parse(value);
  }

  override format(date: Date, displayFormat: string): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    
    return `${month}/${day}/${year}`;
  }
}
