<div class="login_page" [ngClass]="{'password-container': !isChangePassword, 'container': isChangePassword}">

    <div class="login_section_left" *ngIf="!isChangePassword">
        <div class="login_section_left_inner">
            <div>
                <div class="login-img">
                    <h2>“Precision Insights in Motion”</h2> </div> 
            </div>
        </div>
    </div>
    <div class="login_form" [ngClass]="{'change-password': isChangePassword}">
        <div class="login_form_inner">
            <div *ngIf="!showSuccessMessage && activationDetail.valid && validTokenTime">
                <div class="close-btn" *ngIf="isChangePassword" (click)="onClose()"><mat-icon>close</mat-icon>
                </div>
                <div class="login_logo" *ngIf="!isChangePassword">
                    <img src="../../assets/images/spark_logo.svg" />
                </div>
                <h5 class="mb_2 font_bold mt_0">{{createChangePasswordLabel}}</h5>
                <p class="mb_5">Enter your new password and confirm </p>
                <div *ngIf="!isChangePassword && (firstName || lastName)" class="org-setup">
                    <div class="org-row">
                        <div class="org-col half-width" *ngIf="firstName">
                            <label>First Name</label>
                            <P>{{makeFirstLetterCapital(firstName)}}</P>
                        </div>
                        <div class="org-col half-width" *ngIf="lastName">
                            <label>Last Name</label>
                            <P>{{makeFirstLetterCapital(lastName)}}</P>
                        </div>
                    </div>
                    <div class="org-row">
                        <div class="org-col">
                            <label>Email</label>
                            <P>{{email}}</P>
                        </div>
                    </div>
                </div>

                <form [formGroup]="resetPwdForm" (ngSubmit)="createPassword()">
                    <mat-form-field *ngIf="!isLoginWindow" appearance="outline" class="full-width mb_5">
                        <mat-label>Old password</mat-label>
                        <input matInput [type]="oldPassHide ? 'password' : 'text'"
                        formControlName="oldPassword" required>
                    <mat-icon matSuffix (click)="oldPassHide = !oldPassHide">{{oldPassHide ?
                        'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngFor="let validation of validation_messages.oldPassword">
                            <mat-error class="error-message"
                                *ngIf="resetPwdForm.get('oldPassword')?.hasError(validation.type) && (resetPwdForm.get('oldPassword')?.dirty || resetPwdForm.get('oldPassword')?.touched)">
                                {{validation.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="!isChangePassword && (!firstName || !lastName)" appearance="outline" class="full-width mb_5">
                        <mat-label>First Name</mat-label>
                        <input matInput (input)="onChangeEvent($event,32)" (blur)="onBlur($event)" tabindex="1"
                        formControlName="firstname" required>
                        <mat-error *ngFor="let validation of validation_messages.firstname">
                            <mat-error class="error-message"
                                *ngIf="resetPwdForm.get('firstname')?.hasError(validation.type) && (resetPwdForm.get('firstname')?.dirty || resetPwdForm.get('firstname')?.touched)">
                                {{validation.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="!isChangePassword && (!firstName || !lastName)" appearance="outline" class="full-width mb_5">
                        <mat-label>Last Name</mat-label>
                        <input matInput (input)="onChangeEvent($event,32)" (blur)="onBlur($event)" tabindex="1"
                        formControlName="lastname" required>
                        <mat-error *ngFor="let validation of validation_messages.lastname">
                            <mat-error class="error-message"
                                *ngIf="resetPwdForm.get('lastname')?.hasError(validation.type) && (resetPwdForm.get('lastname')?.dirty || resetPwdForm.get('lastname')?.touched)">
                                {{validation.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    

                    <mat-form-field appearance="outline" class="full-width mb_5">
                        <mat-label>New password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'"
                        formControlName="password" required>
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                        'visibility'}}</mat-icon>
                        <mat-error *ngFor="let validation of validation_messages.password">
                            <mat-error class="error-message"
                                *ngIf="resetPwdForm.get('password')?.hasError(validation.type) && (resetPwdForm.get('password')?.dirty || resetPwdForm.get('password')?.touched)">
                                {{validation.message}}</mat-error>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width mb_5">
                        <mat-label>Confirm password</mat-label>
                        <input matInput  [type]="hideConfirmPassword ? 'password' : 'text'"
                            formControlName="confirmPassword" required>
                        <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                            
                        <mat-error *ngFor="let validation of validation_messages.confirmPassword">
                            <mat-error class="error-message"
                                *ngIf="resetPwdForm.get('confirmPassword')?.hasError(validation.type) && (resetPwdForm.get('confirmPassword')?.dirty || resetPwdForm.get('confirmPassword')?.touched)">
                                {{validation.message}}</mat-error>
                            
                        </mat-error>
                    </mat-form-field>
                    
                    <div *ngIf="!isChangePassword">
                        <div class="full-width  password-info mb_4">
                            <span class="infoicon" mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon button with a menu icon"
                                #clickHoverMenuTrigger="matMenuTrigger">
                                <mat-icon>info</mat-icon>
                                <mat-menu id="info-menu" #menu="matMenu" class="password-hint white-bg">
                                    <a mat-menu-item [ngClass]="getClassValidation(upperCaseFlag)">
                                        <mat-icon
                                        [ngClass]="getClassValidation(upperCaseFlag)">check_circle_outline</mat-icon>1
                                        Uppercase Letter</a>
                                    <a mat-menu-item [ngClass]="getClassValidation(lowerCaseFlag)">
                                        <mat-icon
                                        [ngClass]="getClassValidation(lowerCaseFlag)">check_circle_outline</mat-icon>1
                                        Lowercase Letter</a>
                                    <a mat-menu-item [ngClass]="getClassValidation(numberFlag)">
                                        <mat-icon
                                        [ngClass]="getClassValidation(numberFlag)">check_circle_outline</mat-icon>1
                                        Number</a>
                                    <a mat-menu-item [ngClass]="getClassValidation(symbolFlag)">
                                        <mat-icon
                                        [ngClass]="getClassValidation(symbolFlag)">check_circle_outline</mat-icon>1
                                        Special Character</a>
                                    <a mat-menu-item [ngClass]="getClassValidation(minimumFlag)">
                                        <mat-icon
                                        [ngClass]="getClassValidation(minimumFlag)">check_circle_outline</mat-icon>Minimum
                                        12 characters are required</a>
                                    <a mat-menu-item [ngClass]="getClassValidation(maximumFlag)">
                                        <mat-icon
                                        [ngClass]="getClassValidation(maximumFlag)">check_circle_outline</mat-icon>Maximum
                                        20 characters are allowed</a>
                                </mat-menu>
                            </span>
                            <span class="infotext">Follow the criteria to while setting the password</span>
                        </div>

                        <div class="remember_link mb_3">
                            <mat-checkbox formControlName="accept">I Accept to the <a (click)="onPrivacy()">Privacy
                                    Policy</a> and <a  (click)="onTerms()">Terms & Conditions</a></mat-checkbox>
                        </div>
                    </div>
                
                    <div class=" ">
                            <button #myButton class="full-width btn btn-primary" [ngClass]="{'loader-btn disabled': isSpinner, 'disableMe':!resetPwdForm.valid}" color="primary" [disabled]="!resetPwdForm.valid || !resetPwdForm.get('accept')?.value">
                                <span>{{createChangePasswordLabel}}</span> 
                                <span class="btn-loader spin"><img src="../../assets/images/btn-loader.png" /></span>
                            </button>
                    </div>
                </form>
                
            </div>
            <div *ngIf="!validTokenTime">
                <div class="text_center success_icon ">
                    <mat-icon class="text-tertiary">close</mat-icon>
                </div>
                <h5 class="text_center" *ngIf="!isUserDeleted">Your account Verification link has expired!</h5>
                <h5 class="text_center" *ngIf="isUserDeleted">{{isUserDeletedMessage}}</h5>
                <p *ngIf="isResend" class="success-note text_center">Click on resend button to rend the verification link</p>
                <p class="success-email text_center">{{email}}</p>
             <button *ngIf="isResend" mat-raised-button class="full-width" color="primary" [disabled]="activatedCallCount >= 2" (click)="resendEmail()">Resend Email</button> 
            </div>
            <div class="success_container pt_4 text_center" *ngIf="showSuccessMessage && activationDetail.valid">
                <div class="text_center success_icon ">
                    <mat-icon>check</mat-icon>
                </div>
                <h5 class="text_center  ">New Password has been created!</h5>
                <p class="text-light">Sign in to access Spark</p>
                <div class="mb_3">
                    <button mat-raised-button (click)="router.navigate(['/login']);" class="full-width"
                        color="primary">Sign In</button>
                </div>
            </div>
            <!-- <div class="success_container pt_4 text_center" *ngIf="!activationDetail.valid">
                <div class="text_center fail_icon" *ngIf="!activationDetail.valid">
                    <mat-icon>cancel</mat-icon>
                </div>
                <h5 class="text_center  ">{{activationDetail.message}}</h5>
            </div> -->
        </div>
    </div>
</div>