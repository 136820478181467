import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CveAssetsService {
  @Output()
  closeSideNav: EventEmitter<any> = new EventEmitter();

  @Output()
  dataForCveDetails: EventEmitter<any> = new EventEmitter();

  @Output()
  dataForAssetsDetails: EventEmitter<any> = new EventEmitter();
  constructor() { }
}
