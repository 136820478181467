<div class="netsec-expiredbox"> 
    <div *ngFor="let btn of data.buttons">
        <div   *ngIf="btn.label=='close'">
            <button class="close-btn" (click)="onClose(btn?.callBack)" mat-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
        </div>

    </div>
    
  
    <div class="expiredbox-image">
        <img src="../../assets/images/Union.svg" />
    </div> 
    <h3 mat-dialog-title class="title">Upgrade Subscription Request!</h3>
    <p>We've received your request on the subscription upgrade, our team will reach out to you on the further information.</p> 
</div> 
<!-- <div class="netsec-expiredbox"> 
    <h3> Do you want to cancel Diamond subscription?</h3>
    <button class="diamond-sub-btn">DIAMOND SUBSCRIPTION</button>
    <p>Once you cancel the subscription you won't be able to access any of the features after the end of your current plan which is on DD-MM-YYY</p> 
    <div class="expiredbox-btns">
        <button>Don't Cancel</button>
        <button>Cancel Subscription</button>
    </div>
</div> -->
 <!-- <div class="netsec-expiredbox"> 
    <button class="close-btn" mat-button [mat-dialog-close]="true"> <mat-icon>close</mat-icon></button>
    <div class="expiredbox-image">
        <img src="../../assets/images/Union.svg" />
    </div> 
    <h3 mat-dialog-title class="title">Subscription Cancel Request!</h3>
    <p>We've received your request on the subscription cancel, our team will reach out to you on the further information.</p> 
    <p class="sub-content">Your data is securely stored with us for XX days after the end of your plan. Post that, it will be permanently deleted from our database. If you want to download the data go to Account>Subscription”</p>
</div> -->
