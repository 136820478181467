import { Inject, Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private _data: any =  {};
  public apiResponse: any = {};
  private isDashboardLoaderAction = new Subject<boolean | string>();
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document) { }

  onNavigateTopage(type: string, data: any){
    const obj: any = {
      'device': 'admin/asset-management',
      'whitelist': 'dashboard/acceptedrisk',
      'security': '/admin/security-information',
      'vendor': '/admin/security-information',
      'scan_summary': 'dashboard/scan-details'
    }
    this._data = data;
    this.router.navigate([obj[type]]);
  }

  setData(dt:any){
    this._data = dt;
  }

  getData(){
    return this._data;
  }

  clearData(){
    this._data = {};
  }

  toggleBodyClass(flag: boolean):void{
    if(flag){
      this.document.body.classList.add('header-stick');
    } else{
      this.document.body.classList.remove('header-stick');
    }  
  }
  dispatchDashBoardLoaderActions(flag: boolean | string){
    this.isDashboardLoaderAction.next(flag);
  }

  getDashBoardLoaderActions(){
    return this.isDashboardLoaderAction.asObservable();
  }
  public setAPIData(type: string, response: any, isDataAvailable: boolean = false) {
    this.apiResponse[type] = {
      response: response,
      isDataAvailable: isDataAvailable
    };
  }
  public getAPIData(type: string): any {
     return this.apiResponse[type];
  }
}
