import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { CONFIG } from '../../constants/config';
import { ColDef } from 'ag-grid-community';
import { AgGridComponent } from '../ag-grid/ag-grid.component';

@Component({
  selector: 'app-assets-tab',
  templateUrl: './assets-tab.component.html',
  styleUrls: ['./assets-tab.component.scss']
})
export class AssetsTabComponent {
  @Input() public assetsData: any = [];
  @Input() public assetLoader: any = false;
  @ViewChild('myGrid') grid!: AgGridComponent;
  @ViewChild('searchField') searchField!: ElementRef;
  public tableAssetsHeader = CONFIG.TABLE_INFO.ASSETS.HEADER_LABEL;
  public tableAssetsProps = CONFIG.TABLE_INFO.CVE_DASHBOARD.CVE_DETAILS_ASSETS_PROPS;
  public agDeviceColumnDefs: ColDef[] = [
    {
      headerName: this.tableAssetsHeader.ASSETS_NAME,
      field: this.tableAssetsProps.ASSETS_NAME,
      headerTooltip:this.tableAssetsHeader.ASSETS_NAME,
      tooltipField: this.tableAssetsProps.ASSETS_NAME,
      minWidth: 150,
      suppressColumnsToolPanel: true,
      lockPosition: true
    },
    {
      headerName: this.tableAssetsHeader.REGION,
      field: this.tableAssetsProps.REGION,
      headerTooltip:this.tableAssetsHeader.REGION,
      tooltipField: this.tableAssetsProps.REGION,
      lockPosition: true,
      width: 120,
      minWidth: 200,
    },
    {
      headerName: this.tableAssetsHeader.ASSETS_TYPE,
      field: this.tableAssetsProps.ASSETS_TYPE,
      headerTooltip:this.tableAssetsHeader.ASSETS_TYPE,
      tooltipField: this.tableAssetsProps.ASSETS_TYPE
    },
    {
      headerName: this.tableAssetsHeader.VENDOR,
      field: this.tableAssetsProps.VENDOR,
      headerTooltip:this.tableAssetsHeader.VENDOR,
      tooltipField: this.tableAssetsProps.VENDOR,
      width: 120,
      minWidth: 120,
    }
  ];
  constructor() {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['assetLoader'] &&
      changes['assetLoader']?.currentValue != changes['assetLoader']?.previousValue) {
      this.assetsData = changes['assetsData'].currentValue;
      this.assetLoader = changes['assetLoader']?.currentValue;
    }
  }
  filterAssetsField() {
    this.grid.grid.api.setGridOption(
      "quickFilterText",
      this.searchField?.nativeElement?.value
    );
  }
}
