
<div>
    <div class="severity-label" *ngIf="params.data.vulnerability === 'Critical' || params.data.severity === 'Critical'">
        <div *ngFor="let value of [].constructor(4)" class="color-indicator critical"></div>
    </div>


    <div class="severity-label" *ngIf="params.data.vulnerability === 'High' || params.data.severity ==='High'">
        <div *ngFor="let value of [].constructor(3)" class="color-indicator high"></div>
        <div class="color-indicator plain"></div>
    </div>


    <div class="severity-label" *ngIf="params.data.vulnerability === 'Medium'|| params.data.severity ==='Medium'">
        <div *ngFor="let value of [].constructor(2)" class="color-indicator medium"></div>
        <div *ngFor="let value of [].constructor(2)" class="color-indicator plain"></div>
    </div>


    <div class="severity-label" *ngIf="params.data.vulnerability === 'Low' || params.data.severity === 'Low'">
        <div class="color-indicator low"></div>
        <div *ngFor="let value of [].constructor(3)" class="color-indicator plain"></div>
    </div>
    <span *ngIf="params.data.vulnerability" matTooltip="{{params.data.vulnerability}}">{{params.data.vulnerability}}</span>
    <span *ngIf="params.data.severity" matTooltip="{{params.data.severity}}">{{params.data.severity}}</span>
</div>
