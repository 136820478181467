<div class="netsec-expiredbox"> 
    <div class="expiredbox-image">
        <img src="../../assets/images/Union.svg" />
    </div> 
    <h3 mat-dialog-title class="title">Your {{subscription_name}} account has been expired!</h3>
    <p>To continue with your account, upgrade your account with the paid subscriptions.</p>
    <p *ngIf="!(isNewUser?.new_user && isNewUser?.device_count === 0)">
        Your data will be securely stored with us until {{formattedDate}}. After that it will be permanently deleted. You can choose to delete your data right away.
      </p>
    <div class="expiredbox-btns">
            <div  *ngFor="let btn of data.buttons">
                <div *ngIf="btn.label!='delete'">
                    <button   [ngClass]="btn?.class" 
                    (click)="onClose(btn?.callBack,btn.label)" >{{btn.label}}</button>  
                </div>
            </div>
              
        
    </div>
    
    <p>(or)</p>
    <div class="expiredbox-deletebtns text-center">
        <div *ngFor="let btn of data.buttons">
            <div *ngIf="btn.label=='delete'">
                <button class="btn btn-plain red-text" (click)="onClose(btn?.callBack)" mat-button
                [disabled]="isNewUser?.new_user && isNewUser?.device_count === 0" [mat-dialog-close]="true">Delete data permanently</button>
            </div>
    
        </div>
       
    </div>
</div> 