// import { ViewEncapsulation } from '@angular/compiler';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { ISnackBarData } from '../../model/scan/scan';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSnackbarComponent implements OnInit {
  icons;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData,
    public snackbarRef: MatSnackBarRef<CustomSnackbarComponent>
  ) {
    if (data.type == 'success') {
      this.icons = 'check_circle_outline';
    } else if (data.type == 'failure') {
      this.icons = 'highlight_remove';
    }
  }

  ngOnInit(): void {}

  closeSnackBar = () => {
    this.snackbarRef.dismiss();
  };
}

