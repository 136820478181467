import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomDailog } from "../../model/Dialog.model";
import { MatRadioChange } from "@angular/material/radio";

@Component({
    selector: 'custom-dialog',
    templateUrl: 'custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialog {

    selectedValue: any=0;

    onSelectionChange($event: MatRadioChange) {
        
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog) {
        if(data?.data?.device){
            this.selectedValue = true;
        }
        if(data?.data.radioData){
          this.selectedValue =data?.data.radioData; 
        }
    }

    onClose(callBack: Function | undefined) {
        console.log(this.selectedValue)
        if (callBack) {
            if(this.data.data && this.data.data.radio){
              callBack(this.selectedValue,true)
            } else{
                callBack();
            }
            
        }
    }
}