<!-- <div class="create-role-modalbox text_center">
    
    <h3>Renaming the scan?</h3>
    <p class="sub-text">Please rename the scan name “ {{oldScanName}} ”</p>
    <div class="createrole-field">
        <div class="createrole-input">
            <mat-form-field class="example-full-width border-field no-bg" appearance="outline">
                <mat-label>Rename</mat-label>
                <input matInput placeholder="Scan Name" [(ngModel)]="newScanName">
            </mat-form-field>
        </div>
        <div class="renam-btns">
            <button (click)="newName(newScanName)" mat-raised-button class="btn btn-blue" [disabled]="!newScanName.trim()">Rename</button>
            <button (click)="cancelRename()" mat-raised-button class="btn btn-white">Cancel</button>
        </div>
    </div>
</div> -->
<div class="create-role-modalbox ">
  <h3 class="model-heading">Schedule Scan</h3>
  
  <p class="sub-text" *ngIf="selectedValue && isMyscansNewschedule">Customise the scan schedule according to your preferences.</p>
  
  <p class="sub-text" *ngIf=" data?.data.radioData==0 && !isMyscansNewschedule">You are scheduling this assets which will remove from all the schedules. Select an option to schedule scan to continue. </p>
  <p class="sub-text" *ngIf="data?.data.radioData==1  && !isMyscansNewschedule">You are scheduling this assets which will remove from all of my schedules. Select an option to schedule scan to continue.. </p>
  <p class="sub-text" *ngIf=" data?.data.radioData==2 && !isMyscansNewschedule">You are scheduling this assets which will add without removing from other schedules. Select an option to schedule scan to continue. </p>
  
  <p class="sub-text" *ngIf=" !isMyscansNewschedule && !data?.data.radioData && !data?.data.radioFlag   ">Select an option to schedule scan.</p>
   <div class="radio-button" *ngIf="!isMyscansNewschedule">
  <mat-radio-group formControlName="custom_port" aria-label="Select an option" [(ngModel)]="selectedValue" (change)="onSelectionChange($event)">
      <mat-radio-button [value]="true" [checked]="selectedValue">New Schedule</mat-radio-button>
      
      <mat-radio-button [value]="false" [checked]="!selectedValue">Add to an existing scan</mat-radio-button> 
  </mat-radio-group>
  </div> 
    <div *ngIf="selectedValue" class="new-scheduleform">
      <div *ngIf="!isFrequencyLoaded; else loadedContent">
        <ngx-skeleton-loader count="7"></ngx-skeleton-loader>
      </div>
      <ng-template #loadedContent>
        <form [formGroup]="scheduleFormGrp" (ngSubmit)="scheduleConnect()" autocomplete="off">
          <div class="input-box-container">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Scan Name</mat-label>
              <input formControlName="scan_name" matInput>
            </mat-form-field>
          </div>
          <div class="input-box-container">
            <mat-form-field class="example-full-width" appearance="outline"  >
              <mat-label>Frequency</mat-label>
              
              <mat-select formControlName="frequency_id" (selectionChange)="onFrequencyChange($event.value)">
                <mat-option *ngFor="let frequency of frequencyOptions.frequency" [value]="frequency.id">{{ frequency.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            
          </div>
          <div class="input-box-container-ondate">
            <mat-form-field *ngIf="showDateField"  appearance="outline" class="border-field no-bg">
              <mat-label>On Date</mat-label>
              <input matInput formControlName="date"  (dateChange)="onDateChange($event)" [matDatepicker]="datepicker"  placeholder="MM/DD/YYYY" #dateInput>
              <mat-datepicker-toggle matSuffix [for]="datepicker" class="custom-toggle">
                  <span>&#9662;</span>
              </mat-datepicker-toggle>
              <mat-datepicker #datepicker (closed)="onDatepickerClosed()"></mat-datepicker>
          </mat-form-field>
            
          </div>
          <div class="input-box-container">
            <mat-form-field *ngIf="showWeeklyDaysField" class="example-full-width" appearance="outline">
              <mat-label>On Day</mat-label>
              <mat-select formControlName="day">
                <div class="scroll select-credentials">
                  <mat-option *ngFor="let day of frequencyOptions.Days" [value]="day.id">{{ day.name }}</mat-option>
                </div>
                  
              </mat-select>
          </mat-form-field>
          </div>
          <div class="input-box-container">
            <mat-form-field appearance="outline">
              <mat-label>At Time</mat-label>
              <mat-select formControlName="time">
                <div class="scroll select-credentials">
                  <mat-option *ngFor="let timeOption of frequencyOptions.timeOptions" [value]="timeOption">{{ timeOption }}</mat-option>
                </div>
                
              </mat-select>
            </mat-form-field>
            <!-- <mat-form-field appearance="outline">
              <input type="text" placeholder="Select Time" matInput formControlName="time_to_schedule" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let timeOption of scheduleOptions.timeOptions" [value]="timeOption">
                  {{ timeOption }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            
            
            <!-- <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>At time</mat-label>
              <input formControlName="time" matInput>
            </mat-form-field> -->
          </div>
          <div class="upload-submit">
            <button class="btn btn-lightblack-outline" *ngIf="isBack" (click)="onBack()" mat-dialog-close>Back</button>
            <button class="btn btn-lightblack-outline" [mat-dialog-close]="true">Cancel</button>
            <button class="btn btn-blue" [disabled]="!scheduleFormGrp.dirty ||scheduleFormGrp.invalid" >Schedule</button>
        </div>
        </form>
      </ng-template>
      
    </div>
</div> 


<div class="edit-scedule-modalbox" *ngIf="!selectedValue && !isMyscansNewschedule">
  <!-- <h3>Edit Schedule Scan</h3>
  <p class="sub-text">Search for the exisiting scheduled scan </p> -->
  <div class="serch-field">
    <span class="search-icon"> <mat-icon>search</mat-icon></span>
    <form [formGroup]="searchForm">
      <input type="text" autocomplete="off" class="search-input" formControlName="searchTerm" placeholder="Search...">
    </form>
  </div>
  <div class="search-clear">
    <span class="search-text" [ngClass]="{'active':isSearchActive}">Search</span>
    <button class="clear-btn" [disabled]="this.searchForm.get('searchTerm')?.value.trim() == ''" (click)="clearSearch()"><mat-icon>clear</mat-icon></button>
  </div>
  <div class="search-data-table">
    <h3 class="header-text" *ngIf="this.searchForm.get('searchTerm')?.value.trim() !== ''">{{dataSource.length}} Search
      found </h3>

    <div class="schedule-data-inner  scroll">
      <div  *ngIf="dataSource.length === 0 && !isApiCall">
        <ngx-skeleton-loader count="2" [theme]="{ height: '30px', width:'120px', margin: '10px 0 0 10px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="2" [theme]="{ height: '30px', width:'120px', margin: '10px 0 0 10px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="2" [theme]="{ height: '30px', width:'120px', margin: '10px 0 0 10px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="2" [theme]="{ height: '30px', width:'120px', margin: '10px 0 0 10px' }"></ngx-skeleton-loader>
  
      </div>
      <div class="no-data"  *ngIf="dataSource.length === 0 && isApiCall">
        No data found.
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 schedule-data">

        <ng-container matColumnDef="position">
          <td mat-cell *matCellDef="let element"> {{element.schedule_name}} </td>
        </ng-container>
  
        <ng-container matColumnDef="name">
  
          <td mat-cell *matCellDef="let element"> {{element.frequecy_name}} </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected': selectedRow===row}"
          (click)="clickRow(row)"></tr>
      </table>
    </div>

  </div>
  <div class="upload-submit">
      <button class="btn btn-lightblack-outline" *ngIf="isBack" (click)="onBack()" mat-dialog-close>Back</button>
    <button class="btn btn-lightblack-outline" [mat-dialog-close]="true">Cancel</button>
    <button class="btn btn-blue" [disabled]="!this.selectedRow" (click)="scheduleConnect()">Schedule</button>
  </div>





  
<!-- 
  <div class="create-role-modalbox text_center ">
    <h3> Are you sure you want to change the asset to another schedule?</h3>
    <p class="sub-text">This asset is scheduled with another “scan name”, If you perform this action a new schedule will be created for this asset.</p>

      <div class="upload-submit">
        <button class="btn btn-white" >Cancel</button>
        <button class="btn btn-blue" >Schedule</button>
    </div> -->