import { ElementRef, Renderer2 } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

export class Utils {
    constructor() { }
    static notAllowedMail(mailId: string) {
        var re = /.+@(gmail|yahoo|outlook|aol|Zoho|iCloud|mail2world|tutanota)\.com$/;
        return re.test(mailId);
    }
    static notAllowdMailList(control: AbstractControl): ValidationErrors | null {
        if (control.value && Utils.notAllowedMail(control.value)) {
            return { notAllowMail: true };
        }
        else {
            return null;
        }
    }
    
    static noSpaceAllowed(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf(' ') != -1) {
            return { noSpaceAllowed: true };
        }
        else {
            return null;
        }
    }
    static makeFirstWordCapital(str: string | undefined) {
        if (str) {
            var x = str.split(' ');
            x[0] = x[0].toUpperCase();
            return x.join(' ');
        } else {
            return ''
        }

    }
    static makeFirstLetterCapital(str: string | undefined) {
        if (str) {
            return str.charAt(0).toUpperCase() + str.substring(1);
        } else {
            return '';
        }
    }
    static makeFirstLetterCapitalOtherLower(str: string | undefined) {
        if (str) {
            return str.charAt(0).toUpperCase() + str.substring(1).toLocaleLowerCase();
        } else {
            return '';
        }
    }
    
    static capitalizedEachWord(str: string | undefined) {
        if (str) {
            var words = str.split(' ');
            for (var i = 0; i < words.length; i++) {
                var firstLetter = words[i].charAt(0).toUpperCase();
                words[i] = firstLetter + words[i].slice(1);
            }
            return words.join(' ');
        } else {
            return '';
        }
    }

    static getRatingBadge(value: number): string {
        if (value || value === 0) {
            if (value >= 80) {
                return 'bg-low';
            } else if (value >= 50 && value < 80) {
                return 'bg-high';
            } else if (value >= 25 && value < 50) {
                return 'bg-critical';
            }else if(value >= 0 && value < 25){
                return 'bg-critical'
            }
             else {
                return 'bg-decommission';
            }
        } else {
            return '';
        }
    }
    static getRatingcolor(value: number): string {
        if (value || value === 0) {
            if (value >= 80) {
                return 'cl-low';
            } else if (value >= 50 && value < 80) {
                return 'cl-high';
            } else if (value >= 0 && value < 50) {
                return 'cl-critical';
            }
             else {
                return 'cl-decommission';
            }
        } else {
            return '';
        }
    }
    static getNameCombination(firstName: any, lastName: any) {
        return (firstName ? firstName.charAt(0).toUpperCase() : '') + (lastName ? lastName.charAt(0).toUpperCase() : '')
    }

    static getSelectedItems(data: any): string[] {
        const dt = data?.filter((item: any) => item.activated);
        let response: any = [];
        dt?.forEach((item: any) => response.push(item.id))
        return response;
    }

    static fiterEligibleDevices(dt: any): any {
        return dt.filter((item: any) => item.status === 3 && item.total > 1)
    }

    static getObjectToKeys(data: any): any {
        return Object.keys(data)
    }

    static validateObjectData(data: any): boolean {
        if(data){
            return (Utils.getObjectToKeys(data)).length > 0;
        }
        return false;
    }

    static getFirstLetter(word: string | undefined, flag: boolean = false){
        if (word && word.length > 0) {
            return flag ? word[0].toUpperCase() : word[0];
        }
    }

    static restrictFormAccess(flag: boolean, renderer:Renderer2, form: FormGroup, btn: ElementRef ): void{
        if (flag) {
            renderer.setStyle(btn.nativeElement, 'pointer-events', 'none');
            renderer.setStyle(btn.nativeElement, 'opacity', '1');  
            form.disable();
        } else {
            form.enable();
            renderer.removeStyle(btn.nativeElement, 'pointer-events');
            if(!form.valid){
                renderer.setStyle(btn.nativeElement, 'opacity', '0.5');
            }
            
        }
      }
    static getMonthDate(date: Date, months: number){
        date.setMonth(date.getMonth() + months);
        return date;
    }
    static sortByDesiredOrder = (array: any[]): any[] => {
        const desiredOrder = [
            'last_6_months',
            'last_3_months',
            'last_month',
            'last_30_days',
            'last_week',
            'last_7_days'
          ];

        const orderMap = new Map(desiredOrder.map((id, index) => [id, index]));
        return array.sort((a, b) => {
          return (orderMap.get(a.id) || 0) - (orderMap.get(b.id) || 0);
        });
      };
      
      static sortDevicesByScore = (devices: any[], order: 'asc' | 'desc' = 'asc') => {
        return devices.sort((a, b) => {
          if (order === 'asc') {
            return a.score - b.score; 
          } else {
            return b.score - a.score; 
          }
        });
      };  
}