<div class="identified-undentified">
  <div class="file-upload-header">
    <div class="left-side">
      <h2>{{labelDetails.FILE_UPLOAD_HEAD}}</h2>
    </div>
    <div class="right-side">
      <!-- <button mat-stroked-button class="btn save-btn">
        {{labelDetails.SAVE_AND_LEAVE_LABEL}}
      </button> -->
      <span class="count-loader" *ngIf="totalAndUploadedCount.inProgressCount!==totalAndUploadedCount.totalCount">
        ({{totalAndUploadedCount.inProgressCount}}) Uploading files in progress..
        <mat-icon aria-hidden="false" aria-label="autorenew icon" fontIcon="autorenew"></mat-icon>
      </span>
      <span class="count-loader" *ngIf="totalAndUploadedCount.inProgressCount===totalAndUploadedCount.totalCount">
        ({{totalAndUploadedCount.totalCount}}) files has been uploaded. 
      </span>
      <button *ngFor="let btn of dialogData.buttons" mat-flat-button color="primary" [disabled]="enableSubmitBtn"
       class="btn submit" (click)="submit(btn)">
        {{btn.label}}
      </button>
      <mat-icon aria-hidden="false" aria-label="close icon" fontIcon="close" (click)="closeDialog()"></mat-icon>
    </div>

  </div>

  <div class="file-upload-content">
    <div class="idntftd-unidntftd">
      <div class="idntftd-unidntftd-header" *ngIf="selectedIdAndUnidentifiedRow.length">
        <div class="select-dropdown">
          <span class="dropdown-label">Vendor</span>
          <span class="dropdown-selected" [matMenuTriggerFor]="vendor">
            {{selectedDropdpwn.vendor.length ? selectedDropdpwn.vendor : 'Select'}}
          </span>
          <mat-menu #vendor="matMenu">
            <button (click)="selectedValue(vendor.value, 'vendor')" mat-menu-item
              *ngFor="let vendor of vendorList">{{vendor.value}}</button>
          </mat-menu>
        </div>
        <div class="select-dropdown">
          <span class="dropdown-label">Asset</span>
          <span class="dropdown-selected" [matMenuTriggerFor]="device">
            {{selectedDropdpwn?.device?.length ? selectedDropdpwn.device: 'Select'}}
          </span>
          <mat-menu #device="matMenu">
            <button (click)="selectedValue(device.value, 'device')" mat-menu-item
              *ngFor="let device of deviceList">{{device.value}}</button>
          </mat-menu>
        </div>
        <div class="select-dropdown">
          <span class="dropdown-label">Region</span>
          <span class="dropdown-selected region-selected" #toggleButton (click)="toggleRegionDropDown()"
            [title]="selectedRegion">
            {{selectedRegion}}
          </span>
          <div class="region-list" *ngIf="isRegionShow" #rMenu>
            <mat-tree [dataSource]="regionDataSource" [treeControl]="treeControl" class="upload-tree">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li class="mat-tree-node">
                  <mat-checkbox class="checklist-leaf-node"
                    (change)="itemToggle($event.checked, node);updateRegionSelection(node);" [checked]="node.selected">
                    {{node.name}}</mat-checkbox>
                </li>
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li>
                  <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror"
                        [ngClass]="treeControl.isExpanded(node) ? 'tringle' : 'invert-tringle'">
                      </mat-icon>
                    </button>
                    <mat-checkbox class="checklist-leaf-node" [checked]="node.selected"
                      [indeterminate]="node.indeterminate && !node.selected"
                      (change)="itemToggle($event.checked,node);updateRegionSelection(node);">{{node.name}}
                    </mat-checkbox>
                  </div>
                  <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>

          </div>

        </div>
        <button (click)="deletePopup()" mat-button [disabled]="!selectedIdAndUnidentifiedRow.length" class="delete-btn">
          <mat-icon aria-hidden="false" aria-label="delete icon" fontIcon="delete"></mat-icon>
        </button>
        <button mat-flat-button [disabled]="isApplyEnable()" color="primary" class="btn submit"
          (click)="applyDropdownSelection()">{{labelDetails.APPLY_LABEL}}</button>
      </div>
      <span *ngIf="selectedIdAndUnidentifiedRow.length===0" class="add-icon">+
        <input type="file" (change)="uploadFiles($event)" class="file-upload" multiple/>
      </span>
    </div>
    <mat-tab-group (selectedTabChange)="selectTab($event)">
      <mat-tab label="{{labelDetails.TAB_LABEL.UNIDENTIFIED}} ({{unidentifiedData.data.length}})">
        <div class="table-section">
          <net-sec-table *ngIf="unidentifiedData.data.length" 
            [dataSource]="unidentifiedData"
            [columnLabel]="labelDetails.TABLE_COLUMN_LABEL_UN"
            [columns]="UnidentifiedColumns"
            [isCheckBox]="true" 
            [isIcon]="true" 
            [iconName]="'delete'"
            [isSticky]="true"
            (updateSelection)="selectedUnidentifiedTableData($event)"
            (matClickEvent)="deleteRow($event)">
          </net-sec-table>
          <div class="no-data" *ngIf="!unidentifiedData.data.length">
            <img src="../../../../../assets/images/no-data-file.svg" alt="no data" />
            <h2>{{labelDetails.NO_DATA_FOR_UN_MSG.MSG_LINE_1}}</h2>
            <h3>{{labelDetails.NO_DATA_FOR_UN_MSG.MSG_LINE_2}}</h3>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{labelDetails.TAB_LABEL.IDENTIFIED}} ({{identifiedData.data.length}})">
        <div class="table-section">
          <net-sec-table  *ngIf="identifiedData.data.length"
            [dataSource]="identifiedData"
            [columnLabel]="labelDetails.TABLE_COLUMN_LABEL_ID"
            [columns]="IdentifiedColumns" 
            [isCheckBox]="true" 
            [isIcon]="true" 
            [iconName]="'delete'"
            [isSticky]="true"
            (updateSelection)="selectedUnidentifiedTableData($event)"
            (matClickEvent)="deleteRow($event)">
          </net-sec-table>
          <div class="no-data" *ngIf="!identifiedData.data.length">
            <img src="../../../../../assets/images/no-data-file.svg" alt="no data" />
            <h2>{{labelDetails.NO_DATA_FOR_ID_MSG.MSG_LINE_1}}</h2>
            <h3>{{labelDetails.NO_DATA_FOR_ID_MSG.MSG_LINE_2}}</h3>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>