import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CONSTANT } from '../../constants/constant-data';
import { Utils } from '../../utils';
import { AuthService } from '../../services/auth.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { MessageServiceService } from '../../services/message-service.service';
import { throwError } from 'rxjs';
import { ProfileService } from '../../services/profile.service';
import { IUserDetail } from '../../model/profile/profile';
import { CustomDailog } from '../../model/Dialog.model';
import { DialogService } from '../../services/dialog.service';


@Component({
  selector: 'app-password-confirmation-popup',
  templateUrl: './password-confirmation-popup.component.html',
  styleUrls: ['./password-confirmation-popup.component.scss']
})
export class PasswordConfirmationPopupComponent {

  password!: string;
  username!: string;
  buttonLabel!: string;
  title!: string;
  text!: string;
  confirmPasswordForm!: FormGroup;
  userProfile: IUserDetail = {};
  hide = true;
  passwordErrors: string[] = [];
  isButtonDisabled!: boolean;
  userData: any;
  public profileData: any = {};

  validation_messages: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog, 
    public fb: FormBuilder,
   public dialogRef: MatDialogRef<any>,
   public dialogService: DialogService,
   public renderer: Renderer2, 
   public authService: AuthService, 
   public messageService: MessageServiceService,
   public profileService: ProfileService
   ) {
    this.loadProfile();
    this.password = data?.data?.userData.password;
    this.title = data?.data?.userData.title;
    this.text = data?.data?.userData.text;
    this.buttonLabel = data?.data?.userData.buttonLabel;
    this.userProfile = this.profileService.getUserDetails();
    this.validation_messages = {
      password:[
        { type: this.userProfile.sso_enabled ? '' : 'required', message: CONSTANT.AUTHENTICATION.LOG_IN_PASSWORD_REQUIRED },
        // { type: 'minlength', min: 12, message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MIN_LENGTH },
        // { type: 'maxlength', max: 20, message: CONSTANT.AUTHENTICATION.AUTH_PASSWORD_MAX_LENGTH},
        // { type: 'pattern', pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{12,20}$/,
        //  message:CONSTANT.AUTHENTICATION.AUTH_PASSWORD_FORMAT },
          ],
      reason: [
        { type: 'required', message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_REQUIRED },
        { type: 'minlength', min: 8, message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_MIN_LENGTH },
        { type: 'maxlength', max: 120, message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_MAX_LENGTH },
        { type: 'pattern', pattern:/^[^\s][\s\S]*[^\s]$/, message: CONSTANT.WHITELIST_REASON.WHITELIST_PATTERN }]
    };
  }
  ngOnInit(): void {
    this.createForm();
  }
  public loadProfile(): void {
    this.profileService.fetchUserDetails(this.userProfileCallback);
  }
  public userProfileCallback = (results: IUserDetail) => {
    this.userData = results;
    this.profileData['firstname'] = this.userData.firstname;
    this.profileData['lastname'] = this.userData.lastname;
    const currentDate = new Date();
    const expiryDate = new Date(this.userData.expiry_date);
    const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
    const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    this.profileData['expiresIn'] = expiresInDays;
  }

  @ViewChild('myButton') myButton!: ElementRef;
  isSpinner: boolean = false;

  createForm(): void {
    this.confirmPasswordForm = this.fb.group({
      password: new FormControl("", Validators.compose([
        this.userProfile.sso_enabled ? null : Validators.required, 
        // Validators.minLength(12),
        // Validators.maxLength(20),
        // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{12,20}$/),
        // this.passwordMatchValidator()
      ])),
      reason: new FormControl("")
    })
    // this.confirmPasswordForm.get('password')!.valueChanges.subscribe(() => {
    //   this.updatePasswordErrors();
    // });  
    if(this.buttonLabel === 'Decommission' || this.buttonLabel === 'Commission'){
      this.confirmPasswordForm.get('reason')?.setValidators(Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(120),
        Validators.pattern(/^[^\s][\s\S]*[^\s]$/)
      ]));
      this.confirmPasswordForm?.get('reason')?.updateValueAndValidity(); 
    }  
  }
  updatePasswordErrors(): void {
    this.passwordErrors = [];
    const passwordControl = this.confirmPasswordForm.get('password');

    if (passwordControl!.hasError('required')) {
      this.passwordErrors.push('Password is required');
    }
    if (passwordControl!.hasError('maxlength')) {
      this.passwordErrors.push('Password cannot be more than 120 characters');
    }
    if (passwordControl!.hasError('minlength')) {
      this.passwordErrors.push('Password should be at least 6 characters');
    }
    if (passwordControl!.hasError('pattern')) {
      this.passwordErrors.push('Please enter a password in the proper format');
    }
    if (passwordControl!.hasError('noSpaceAllowed')) {
      this.passwordErrors.push('Password should not contain spaces');
    }
    if (passwordControl!.value && !/[0-9]/.test(passwordControl!.value)) {
      this.passwordErrors.push('Password should contain at least one digit');
    }
    if (passwordControl!.value && !/[a-z]/.test(passwordControl!.value)) {
      this.passwordErrors.push('Password should contain at least one lowercase letter');
    }
    if (passwordControl!.value && !/[A-Z]/.test(passwordControl!.value)) {
      this.passwordErrors.push('Password should contain at least one uppercase letter');
    }
    if (passwordControl!.value && !/[@$!%*?&]/.test(passwordControl!.value)) {
      this.passwordErrors.push('Password should contain at least one special symbol');
    }
  }

  async confirm() {
    this.isSpinner =true;
    this.restrictFormAccess(true)
    

    if(this.userProfile.sso_enabled && this.title==CONSTANT.DELETE_DATA_TITLE) {
      this.data?.data?.setCallbackFunction(true,this.userData);
      this.dialogService.closeDialog();

    } else if(this.userProfile.sso_enabled){
      // this.dialogRef.close('closed');
      this.data?.data?.setCallbackFunction();
      this.dialogService.closeDialog();
      return;
    } else {
      this.authService.passwordValidation(this.confirmPasswordForm.value.password)?.subscribe({
        next: (response) => {
          this.isSpinner =false;
          this.restrictFormAccess(false)
          if (response.status === 200) {
            // this.dialogRef.close('closed');
            this.data?.data?.setCallbackFunction(true,this.userData);
            this.dialogService.closeDialog();
          }
        },
        error: (err) => {
          this.isSpinner =false;
          this.restrictFormAccess(false)
          const snakMsg: IToastrOptions = {
            message: err.error.message
          }
          this.messageService.showError(snakMsg);
          return throwError(() => err);
        }
      })

    }

        
  
  }

 onClose(){
  this.data?.data?.setCallbackFunction(false,this.userData);
 }
  checkTextField(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length >= 6 && inputValue.length <= 120 && /^[A-Za-z0-9]*$/.test(inputValue)) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
}
restrictFormAccess(flag: boolean): void{
  Utils.restrictFormAccess(flag, this.renderer, this.confirmPasswordForm, this.myButton);
}

}
