import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { CveAssetsService } from '../../services/cve-assets.service';
import { END_POINTS } from '../../constants/endpoints';
import { MatSidenav } from '@angular/material/sidenav';
import { SpinnerService } from '../../services/spinner.service';
import { SecurityInformationService } from '../../services/security-information.service';
import { ISelectedListDetailsResponse, ISelectedListInfoDetails } from '../list/list.model';
import * as _ from 'lodash';
import { Utils } from '../../utils';
import { AssetSummaryDownloadComponent } from 'src/app/modules/admin/components/asset-summary-download/asset-summary-download.component';
import { DeviceService } from '../../services/device.service';
import { MatDialog } from '@angular/material/dialog';
import { CveService } from '../../services/cve.service';

@Component({
  selector: 'app-cve-assets-details',
  templateUrl: './cve-assets-details.component.html',
  styleUrls: ['./cve-assets-details.component.scss']
})
export class CveAssetsDetailsComponent {
  public dataForCveDetails!: any;
  public isCVEdetails: boolean = false;
  public cveDetails: any;
  public assetsDataLoader:boolean = false;
  public affectedDeviceResponse: any = [];
  public isAssetDetails:boolean = false;
  public assetsData:any;
  deviceId: any;
  deviceVendor: any;
  deviceType: any;
  regionName: any;
  public securityGraphTitle = 'Security Score';
  public securityApiName: any;
  public vulnerabilityGraphTitle = 'Vulnerabilities';
  public vulnerabilityApiName: any;
  public vulnerabilityCveApiName:any
  public cyberResilienceGraphTitle = 'Cyber Resilience Trend';
  public cyberResilienceGraphApiName: any;
  public cyberResilienceFilter = true; 
  public apiPayload: any;
  public commonVulnerabilityTitle = 'Vulnerabilities List';
  public commonVulnerabilityApiName = END_POINTS.DASHBOARD_COMMONVUL;
  public commonVulnerabilityFilter = false;
  public vulRowData: boolean = true
  public vulHeading: any;
  public selectedRowInfoDetails: ISelectedListInfoDetails | undefined = {};
  public selectedRowApiCall = false;
  showDescription: boolean = false;
  showRectification: boolean = false;
  showRationale: boolean = false;
  showIssues: boolean = false;
  public selectedIndex = 0;
  constructor(public filterService: FilterService,
    private cveAssetsService: CveAssetsService,
    private spinnerService: SpinnerService,
    private cveService: CveService,
    private securityInformationService: SecurityInformationService,
    private deviceService: DeviceService,
    private dialog: MatDialog,

  ) {
    this.filterService.sidenavCloseEvent.subscribe(()=>{
     this.isAssetDetails = false;
     this.isCVEdetails = false;
    })
    this.cveAssetsService.dataForCveDetails.subscribe((data)=>{
      this.dataForCveDetails = data;
      this.isCVEdetails = data.isCveDetails;
      this.loadCVEDetails();
      this.loadAffectedAssetsList();
    })
    this.cveAssetsService.dataForAssetsDetails.subscribe((data) => {
      this.assetsData = data
      this.deviceId =  this.assetsData?.selectedRow?.device_name || '';
      this.deviceVendor = this.assetsData?.selectedRow?.vendor || '';
      this.deviceType = this.assetsData?.selectedRow?.device_type || '';
      this.regionName =  this.assetsData?.selectedRow?.region_name || '';
      if(data){
        this.isAssetDetails = data.isAssetDetails;
      }
      this.selectedIndex = data.selectedIndex;
    });
   
  }



  public validateObjectData(data: any): boolean{
    return Utils.validateObjectData(data);
  } 

 
  public downloadAssetsSummary() {
    const dialogRef = this.dialog.open(AssetSummaryDownloadComponent, {
      width: '338px',
      panelClass: 'dowload-report-box',
      disableClose: true,
      data: {
        title: 'Download Asset Summary Report?',
        content: `This report offers a  summary of essential information for ${ this.assetsData.selectedRow.length} Asset's.`,
        assetsIds:  this.assetsData.selectedRow,
        buttons: [{
          label: 'Download',
          class: 'btn-primary',
          callBack: (selectedFormat: string) => {
            const selectedRow = [this.assetsData.selectedRow]
            this.deviceService.assetSummaryData( selectedRow, selectedFormat)
          }
        }, {
          label: 'Cancel',
          class: 'btn-grayborder',
          callBack: () => {
          }
        }],
        disableClose: true
      },
    });
    return dialogRef.afterClosed();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['isCVEdetails'] &&
      changes['selectedCVE']?.currentValue != changes['selectedCVE']?.previousValue) {
        this.isCVEdetails = changes['isCVEdetails'].currentValue;
    }
  }
  public loadCVEDetails() {
    this.cveDetails = null;
    this.cveService.fetchCVEDetails(this.dataForCveDetails?.selectedRow?.cve_id, this.loadCVEDetailsCallback)
  }
  public loadCVEDetailsCallback = (response: any) => {
    if (response.status === 200 && response.data && response.data.cve_summary_details) {
      this.cveDetails = response.data.cve_summary_details;
    }
  }
  public loadAffectedAssetsList() {
    this.assetsDataLoader = false;
    this.affectedDeviceResponse = [];
    this.cveService.fetchAffectedAssetsList(this.dataForCveDetails?.selectedRow?.cve_id, this.loadAffectedAssetsListCallback);
  }
  public loadAffectedAssetsListCallback = (response: any) => {
    this.assetsDataLoader = true;
    if (response.status === 200 && response.data) {
      this.affectedDeviceResponse = response.data.parameters || [];
    }
  }
  public closeSidenav() {
    this.isAssetDetails = false;
    this.filterService.closeSidenav();
  }
  // toggleDescription() {
  //   this.showDescription = !this.showDescription;
  // }
  // toggleRectification() {
  //   this.showRectification = !this.showRectification;
  // }
  // toggleRationale() {
  //   this.showRationale = !this.showRationale;
  // }
  // toggleIssues() {
  //   this.showIssues = !this.showIssues;
  // }
}
