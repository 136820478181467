import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent {
  constructor(private authService: AuthService,
    public router: Router) {
    if(!this.authService.isLoggedIn()) {
      this.logout();
      this.router.navigate(["login"]);
    }
    
  }
  public logout(){
    this.authService.logOut(false);
 }
}
