import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    let numericValue: number;
    if (typeof value === 'string') {
      numericValue = parseFloat(value.replace(/,/g, ''));
    } else {
      numericValue = value;
    }
    if (numericValue <= 9999) {
      
      return Math.floor(numericValue).toString();
    } else if (numericValue >= 10000 && numericValue < 1000000) {
      return this.formatValue((numericValue / 1000).toFixed(1)) + 'k';
    } else if (numericValue >= 1000000 && numericValue < 1000000000) {
      return this.formatValue((numericValue / 1000000).toFixed(1)) + 'Mi';
    } else if (numericValue >= 1000000000) {
      return this.formatValue((numericValue / 1000000000).toFixed(1)) + 'Bi';
    } else {
      return numericValue.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 });
    }
  }

  public formatValue(value: string): string {
    const [wholePart, decimalPart] = value.split('.');
    const formattedDecimalPart = (decimalPart ? decimalPart.slice(0, 1) : '0').padEnd(1, '0');
    const returnData = (wholePart.length <= 2 && formattedDecimalPart != "0")? wholePart + '.' + formattedDecimalPart : wholePart.slice(0, 3);
    return returnData;
  }
}
