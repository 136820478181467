import { NestedTreeControl, TreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import * as _ from 'lodash';
import { ITreeView } from '../filter.model';
import { FilterService } from '../../../services/filter.service';
import { Subscription } from 'rxjs';
import { CONSTANT, ToolTipConfig } from '../../../constants/constant-data';


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input()
  public filterData!: ITreeView[];
  @Output()
  filterValues: EventEmitter<ITreeView[]> = new EventEmitter<ITreeView[]>();
  @Output()
  updatePartialInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: string = 'checkbox';
  @Input() isGroupLevel: boolean = false;
  public allSelected = false;
  public selectedData: ITreeView | any = [];
  public addFilterCheckForAll = true;
  public treeControl =
    new NestedTreeControl<ITreeView>(node => node.data);
  public filterDataSource: MatTreeNestedDataSource<ITreeView>;
  public filterDataSourceBackUp: ITreeView[] = [];
  public filterSubscription: Subscription;
  public hasData: boolean = true;
  public toolTipOption = _.cloneDeep(ToolTipConfig);

  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  public hasChild = (_: number, node: ITreeView) =>
    !!node.data && node.data.length > 0;

  constructor(private filterService: FilterService) {
    this.toolTipOption.display = true;
    this.filterDataSource = new MatTreeNestedDataSource<ITreeView>();
    this.filterSubscription = this.filterService.filter.subscribe((text) => {
      if (!this.filterData.length) {
        this.filterData = _.cloneDeep(this.filterDataSourceBackUp);
      }
      let filteredTreeData: any;

      if (text.length < 3 && text.length !== 0) {
        return;
      }


      filteredTreeData = this.filterData.filter(
        (d: any) =>

          this.recursiveFilter(d, text)
      );

      if (text === 'clear') {
        text = '';
        this.clearData(this.filterDataSourceBackUp);
      }
      if (text) {
        const allText = 'all';
        this.addFilterCheckForAll = false;
        if (allText.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1) {
          this.addFilterCheckForAll = true;
        }
        Object.assign([], filteredTreeData).forEach((ftd: any) => {
          let str = ftd.label;
          while (str.lastIndexOf('.') > -1) {
            const index = str.lastIndexOf('.');
            str = str.substring(0, index);
            if (filteredTreeData.findIndex((t: any) => t.label === str) === -1) {
              const obj = this.filterData.find(d => d.label === str);
              if (obj) {
                filteredTreeData.push(obj);
              }
            }
          }
        });
        if (filteredTreeData.length === 0) {
          this.hasData = false;
        } else {
          this.hasData = true;
        }
      } else {
        filteredTreeData = this.filterDataSourceBackUp;
        this.addFilterCheckForAll = true;
        this.hasData = true;
      }
      this.filterData = filteredTreeData;
      this.filterTreeDataUpdate();
    })

  }

  public recursiveFilter(node: ITreeView, searchText: string): boolean {
    const lowerCaseLabel = node.label?.toLowerCase();
    const labelMatches = lowerCaseLabel?.includes(searchText.toLowerCase());
    if (labelMatches) {
      if (!searchText) {
        this.treeControl.collapseAll();
        return true;
      }
      this.treeControl.expand(node);
      return true;
    }
    if (node.data) {
      for (const child of node.data) {
        if (this.recursiveFilter(child, searchText)) {
          this.treeControl.expand(child);
          this.expandNode(child)
          return true;
        }
      }
    }

    // Check if any child node matches the search text
    //const childrenMatch = node.data && node.data.some(child => this.recursiveFilter(child, searchText));
    if (!searchText) {
      this.treeControl.collapseAll()
      return false;
    }
    return false;
  }

  private expandNode(node: ITreeView): void {
    if (node.parent) {
      this.expandNode(node.parent)
    }
    this.treeControl.expand(node)
  }



  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.filterSubscription?.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.filterTreeDataUpdate();
    this.selectedData = this.finalFilterData(_.cloneDeep(this.filterDataSource.data));
    this.isAllSelected(this.filterDataSource.data);
    this.filterValues.emit(this.finalFilterData(this.selectedData));
    this.updatePartialInfo.emit(this.allSelected);
    this.filterDataSourceBackUp = _.cloneDeep(this.filterData);

  }
  private clearData(data: ITreeView[]): any {
    data?.forEach((obj, i) => {
      obj.selected = false;
      obj.indeterminate = false;
      this.clearData(obj.data || []);
    });
  }
  public filterTreeDataUpdate(): void {
    this.filterDataSource.data = this.filterData;
    this.filterDataSource?.data.forEach((key: any) => {
      this.setParent(key, null, 0);
    });
  }
  // select / unselect value
  public itemToggle(checked: boolean, node: ITreeView): void {
    this.filterService.isFilterTouch = true;
    node.selected = checked;
    if (node.data) {
      node.data.forEach(child => {
        this.itemToggle(checked, child);
      });
    }
    this.checkAllParents(node);
  }
  public dataReturn(node: any, nullItem: any): any {
    if (node.parent) {
      nullItem = _.cloneDeep(node.parent);
      return this.dataReturn(node.parent, nullItem);
    }
    return nullItem;
  }
  // update selected saperate array
  public setChangedSelectedItemHierarch(node: ITreeView | any): void {
    if (node.parent) {
      this.filterSelectedData(this.dataReturn(node, null));
    } else {
      this.filterSelectedData(node);
    }
    this.isAllSelected(this.filterDataSource.data);
    this.updatePartialInfo.emit(this.allSelected);
  }
  selectAll() {
    this.filterService.isFilterTouch = true;
    if (this.allSelected) {
      this.selectedData = this.selectAllCheckBox(true, this.filterDataSource.data);
    } else {
      this.selectAllCheckBox(false, this.filterDataSource.data);
      this.selectedData = []
    }
    this.filterValues.emit(this.selectedData);
    this.updatePartialInfo.emit(this.allSelected);
  }
  isAllSelected(data: any): void {
    let newStatus = true;
    if (!Array.isArray(data)) {
      return data
    }
    data.map(item => {
      if ('data' in item) {
        if (!item.selected) {
          newStatus = false;
        }
        this.isAllSelected(item.data || []);
      } else {
        if (!item.selected) {
          newStatus = false;
        }
      }
    })
    this.allSelected = newStatus;
  }
  selectAllCheckBox(flag: boolean, data: ITreeView | any) {
    if (!Array.isArray(data)) {
      return data
    }
    return data.filter(item => {
      if ('data' in item) {
        item.selected = flag;
        item.indeterminate = flag;
        this.selectAllCheckBox(flag, item.data || []);
      } else {
        item.selected = flag;
      }
      return (item.selected || item.indeterminate);
    })
  }
  private filterSelectedData(node: ITreeView | any): void {
    const findIndex = this.selectedData?.findIndex((obj: ITreeView) => {
      return this.isGroupLevel ? ((node.id || node.id === 0) && obj.id === node.id) || (node.type && obj.type === node.type) :
        ((node.id || node.id === 0) && obj.id === node.id);
    })
    if (this.selectedData.length === 0 && (node.selected || node.indeterminate)) {
      this.selectedData.push(node)
    } else if (this.selectedData.length > 0 && ((node.selected || node.indeterminate))) {
      if (findIndex === -1) {
        if (node.lavel === 0 && this.isGroupLevel) {
          const nodeIndex = this.selectedData?.findIndex((obj: ITreeView) => {
            return node.type === obj.type && ((node.id || node.id === 0) && obj.id === node.id);
          })
          if (nodeIndex > -1) {
            this.selectedData.splice(nodeIndex, 1);
          }
        }
        this.selectedData.push(node);
      } else {
        this.selectedData[findIndex] = node;
      }
    } else if ((this.selectedData.length > 0 && !node.selected && !node.indeterminate)) {
      this.selectedData.splice(findIndex, 1);
    }
    this.filterValues.emit(this.finalFilterData(this.selectedData));
  }
  private finalFilterData(data: ITreeView[]): any {
    if (!Array.isArray(data)) {
      return data
    }
    return data.filter(item => {
      if ('data' in item) {
        item.data = this.finalFilterData(item.data || []);
      } else {
        item.indeterminate = false;
      }
      return (item.selected || item.indeterminate)
    })
  }
  public toggleRadio(checked: boolean, node: ITreeView) {
    this.resetData(this.filterData);
    this.selectedData = [];
    node.selected = checked;
    this.checkAllParents(node);
    this.setChangedSelectedItemHierarch(node);
  }
  public resetData(data: ITreeView[]): any {
    data?.forEach((obj, i) => {
      obj.selected = false;
      obj.indeterminate = false;
      this.resetData(obj.data || []);
    });
    return data;
  }
  // update parent detail in current obj
  private setParent(node: ITreeView, parent: ITreeView | null, level: number): void {
    node.parent = parent;
    node.lavel = level;
    if (node.data) {
      node.data.forEach(childNode => {
        this.setParent(childNode, node, level + 1);
      });
    }
  }
  // check parent level
  public checkAllParents(node: ITreeView): void {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected =
        descendants.every(child => child.selected);
      node.parent.indeterminate =
        descendants.some(child => child.selected);
      this.checkAllParents(node.parent);
    }
  }
  public getLevel(node: ITreeView): number {
    return node.lavel || 0;
  }
  public getSelectedLastChildren(node: ITreeView | any, result: any = []) {
    node?.forEach((item: any) => {
      if (item.data && item.data.length > 0) {
        this.getSelectedLastChildren(item.data, result);
      } else {
        if(item.selected) {
          result.push(item);
        }
      }
    });
    return result?.length || 0;
  }
}
