<div class="delete-confirm netsec-expiredbox cancel-planbox cancel-request delete-progressbox delete-success">
    <!-- <button class="close-btn" mat-button [mat-dialog-close]="true"> <mat-icon>close</mat-icon></button>
    <div class="expiredbox-image">
        <img src="../../assets/images/Union.svg" />
    </div> 
    <h3 mat-dialog-title class="title">Are you sure you want to delete your data permanently?</h3> 
    <p class="diamond-link"><button  class="btn btn-outline diamond-outline" mat-dialog-close>DIAMOND SUBSCRIPTION</button> </p>
    <p>You cannot undo this action. We suggest you to download your data before deleting it</p>   
    <div class="border-top footer-text">
        <p>Your data is securely stored with us for XX days after the end of your plan. Post that, it will be permanently deleted from our database. If you want to download the data go to Account>Subscription”</p>
    </div>
    <div class="expiredbox-btns "> 
        <button  class="btn btn-outline secondary-outline" mat-dialog-close>cancel</button> 
        <button  class="btn btn-red" mat-dialog-close>Delete Permanently</button>
    </div> 
    <p>(or)</p>
    <div class="expiredbox-btns text-center multi-btns">
        <button class="btn btn-plain blue-text">Download Data</button>  <button class="btn btn-plain red-text">Delete data permanently</button> 
    </div> -->
    <div class="expiredbox-image">
        <img src="../../assets/images/spark_byVerastel.svg"/>
    </div>  
    <div *ngIf="isSuccessDelete">
        
        <div class="delete-successicon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                <path d="M26.9654 45.6769L49.3519 23.2904L46.875 20.8135L26.9654 40.723L16.9904 30.748L14.5135 33.225L26.9654 45.6769ZM32.0117 63.5C27.6558 63.5 23.5605 62.6734 19.7259 61.0203C15.8912 59.3672 12.5555 57.1236 9.71891 54.2897C6.88234 51.4557 4.63671 48.1232 2.98202 44.2922C1.32734 40.4611 0.5 36.3676 0.5 32.0117C0.5 27.6558 1.32655 23.5605 2.97966 19.7259C4.63283 15.8912 6.87639 12.5555 9.71034 9.71891C12.5443 6.88234 15.8768 4.63671 19.7078 2.98203C23.5389 1.32734 27.6324 0.5 31.9883 0.5C36.3442 0.5 40.4395 1.32655 44.2742 2.97966C48.1088 4.63283 51.4445 6.87639 54.2811 9.71034C57.1177 12.5443 59.3633 15.8768 61.018 19.7078C62.6727 23.5389 63.5 27.6324 63.5 31.9883C63.5 36.3442 62.6734 40.4395 61.0203 44.2742C59.3672 48.1088 57.1236 51.4445 54.2897 54.2811C51.4557 57.1177 48.1232 59.3633 44.2922 61.018C40.4611 62.6727 36.3676 63.5 32.0117 63.5ZM32 60C39.8167 60 46.4375 57.2875 51.8625 51.8625C57.2875 46.4375 60 39.8167 60 32C60 24.1833 57.2875 17.5625 51.8625 12.1375C46.4375 6.7125 39.8167 4 32 4C24.1833 4 17.5625 6.7125 12.1375 12.1375C6.7125 17.5625 4 24.1833 4 32C4 39.8167 6.7125 46.4375 12.1375 51.8625C17.5625 57.2875 24.1833 60 32 60Z" fill="#00A61B"/>
            </svg>
        </div>
        <h3 mat-dialog-title class="title">Your data has deleted successfully!</h3> 
    
        <!-- <div class="data-deleteflow">
            <img src="../../assets/images/vpn_lock.png" />
            <img src="../../assets/images/linearrrow.png" />
            <img src="../../assets/images/delete.png" />
        </div> -->
        <p>We don’t have any data in your database now. Kindly upgrade to our paid subscriptions to start freshly </p>
        <div class="expiredbox-btns ">  
            <button  class="btn btn-blue" mat-dialog-close>Upgrade!</button>
            <button  class="btn btn-outline red-outline" mat-dialog-close>Sign Out</button> 
        </div>
      
    </div>
    <div *ngIf="!isSuccessDelete">
        <h3 mat-dialog-title class="title weight">Deleting data permanently!</h3> 
        <div class="data-deleteflow">
            <img src="../../assets/images/vpn_lock.png" />
            <img src="../../assets/images/linearrrow.png" />
            <img src="../../assets/images/delete.png" />
        </div>
        <p class="moment">Give us a moment we are deleting your data from our database</p>
        <div class="progress-bar">
            <mat-progress-bar 
            mode="determinate" 
            [value]="percentage" 
            color="#2b73db"
            
            ></mat-progress-bar>
        </div>
        <p class="text-center mt-3">{{percentage}}%</p>

    </div>
    
    <!-- <div class="delete-progressbar"></div>
    <p class="text_center">50%</p> -->
</div>
