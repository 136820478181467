import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'net-sec-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() columns: string[] = [];
  @Input() columnLabel: string[] = [];
  @Input() dataSource = new MatTableDataSource<any>([]);
  @Input() isCheckBox: boolean = false;
  @Input() isIcon: boolean = false;
  @Input() iconName: string = "";
  @Input() isSticky: boolean = false;
  @Output() updateSelection = new EventEmitter();
  @Output() matClickEvent = new EventEmitter();

  selection = new SelectionModel<any>(true, []);
  constructor() {

  }

  ngOnInit(): void {
    if (this.columns.length && this.columnLabel.length) {
      if (this.isCheckBox) {
        this.columnLabel.unshift('');
        this.columns.unshift('select');
      }
      if (this.isIcon) {
        this.columnLabel.push('');
        this.columns.push(this.iconName);
      }
    }
  }
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  public toggleRow(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.currentSelection();
  }
  public currentSelection(): void {
    this.updateSelection.emit(this.selection);
  }
  public iconEvent(currentRow: any) {
    if(this.matClickEvent) {
      this.matClickEvent.emit(currentRow);
    }
  }
}
