import { Component, Input, HostListener } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})

export class SideBarComponent {
  isSidebarOpen = false;
  startX = 0; // Initial X coordinate
  startY = 0; // Initial Y coordinate
  offsetX = 0; // Offset position from the left
  offsetY = 0; // Offset position from the top
  isDragging = false; // Flag to check if the button is being dragged
  @Input() columnDefs: (ColDef & { visible?: boolean })[] = []; // Extend ColDef to include 'visible'

  @Input() gridApi!: GridApi; // Input to access grid API

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen; // Toggle the sidebar visibility
  }

  // Start dragging
  @HostListener('mousedown', ['$event'])
  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.clientX - this.offsetX;
    this.startY = event.clientY - this.offsetY;
    event.preventDefault(); // Prevent text selection during drag
  }

  // Dragging the button
  @HostListener('document:mousemove', ['$event'])
  onDragging(event: MouseEvent) {
    if (!this.isDragging) return;
    this.offsetX = event.clientX - this.startX;
    this.offsetY = event.clientY - this.startY;
    const draggableElement = document.getElementById('draggable');
    if (draggableElement) {
      draggableElement.style.transform = `translate(${this.offsetX}px, ${this.offsetY}px)`;
    }
  }

  // End dragging
  @HostListener('document:mouseup')
  onDragEnd() {
    this.isDragging = false;
  }

  onToggleColumn(columnField: string, event: any) {
    const visible = event.target.checked;
    this.gridApi.setColumnVisible(columnField, visible);

    // Update visibility state in columnDefs for checkbox sync
    const column = this.columnDefs.find(col => col.field === columnField);
    if (column) {
      column.visible = visible;
    }
  }
}
